import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle, FaHome } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
    //fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function ApplicationPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const { user } = useSelector((state) => state.auth);


  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])

  


  return (
    <>
      <Helmet>
        <title> Application | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       <div  style={{position:"absolute",top:"0",backgroundColor:"#40255F",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",paddingTop:"1rem",paddingBottom:"1rem"}}>
       <FaHome onClick={()=>{navigate('/home')}}  style={{color:"white",fontSize:"2.2rem",position:"absolute",right:"1rem",cursor:"pointer"}}/>
       
        <img style={{height:"100px"}} src={icon} alt={"one recruiter logo"}/>
        </div>

        <Container style={{marginTop:"6rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         {/*FIRST SECTION  */} 
          <Typography variant="h2" gutterBottom style={{width:"44rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
              Getting Started
            </Typography>
   
          <Divider style={{width:"40%",border:"2px solid #40255F"}}/>

            <Typography variant="body2" sx={{ mt: 6, display:"flex",alignItems:"center",textAlign:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
            Welcome to OneRecruiter Business Account! You're one step closer
            to a smoother, quicker, and effortless hiring process.
               
              
            </Typography>



            
              <Typography variant="body2"  sx={{ mt: 5,mb: 12, display:"flex",textAlign:"center",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
                 OneRecruiter is an all-in one staffing solution. Post your job opportunity like you would
                 on LinkedIn or Indeed. However, once it's live, our technology will provide the services of a staffing agency
                 with a sprinkle of in-house recruitment.
              </Typography>
            

       {/*FIRST SECTION END */}     



     <div style={{backgroundColor:"#40255F",width:"100vw",paddingBottom:"5rem"}}>
       <StyledWidth>
         
         <div style={{width:"80%",backgroundColor:"#FFFFFF",borderRadius:"0.5rem",padding:"2rem",display:"flex",alignItems:"center",justifyContent:"center",flexDirection:"column"}}>
           
         <Typography variant="h2" gutterBottom style={{width:"44rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
              How Does It Work ?
            </Typography>
   
          

            <Typography variant="body2" sx={{ mt: 1, color:"#40255F",display:"flex",textAlign:"center",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
            OneRecruiter is a marketplace web portal connecting growing businesses with the country's most talented recruiters while automating critical areas of the interview process.
               
              
            </Typography>

         </div>


         <div style={{position:"relative",width:"100%",display:"flex",flexDirection:"row",justifyContent:"space-between",alignItems:"flex-start",marginTop:"5rem"}}>
             
            {/*LEFT COLUMN */}
            <div style={{width:"35%",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"5rem"}}>
              
              {/*1 */}
              <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
                 <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Free for Businesses</h3>
                 <p style={{fontSize:"1.5rem"}}>
                   When a company signs up for a business account, there will be a speedy verification process to confirm their
                   identity. No payment is required until the company hires a candidate. There will be consultation with a OneRecruiter team member to ensure we're the best solution for your needs
                   and that you get the most out of our technology and services.
                 
                  
                 </p>
             </div>

             {/*2 */}
              <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
                
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Upload Job Postings</h3>
                 <p style={{fontSize:"1.5rem"}}>
                  Companies can immediately post job opportunities and tailor their interview process.
                   OneRecruiter's flat rate system provides businesses with complete cost control, enabling them to allocate
                   additional savings toward business growth or innovation.
                  
                 </p>


             </div>

              {/*3 */}
              <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Team Interviews</h3>
                 <p style={{fontSize:"1.5rem"}}>
                 Whether the interview is onsite or virtual, OneRecruiter will facilitate and coordinate the interview and all
                 communications. Once the recruiter or candidata selects the time and date for the interview, the platform will send out
                 a confirmation email and calendar invite to all parties.
                 Reminders as well!
                  
                 </p>
             </div>



             {/*4 */}
             <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Offer Letter</h3>
                 <p style={{fontSize:"1.5rem"}}>
                 After selecting the candidate you want to hire, an Offer Letter will appear with the necessary fields such as salary, job title, start date, and others. This information will be automatically filled in by the system. Once the company signs the Offer Letter, the candidate will receive a notification.
                 </p>
             </div>


             </div>

            {/* MIDDLE LINE */}
             <StyledMiddleLine className="middleLine" style={{color:"#40255F",height:"160rem",width:"30px",borderRight:"3px solid #FFFFFF",position:"relative",top:"3rem",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
             
                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"1rem"}}>
         
                       <div style={{height:"2px",width:"13rem", backgroundColor:"white",position:"relative",left:"-10.5rem",top:"2.5rem"}}></div>

                 </div>

               
                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"19rem"}}>
                 <div style={{height:"2px",width:"12rem", backgroundColor:"white",position:"relative",left:"2.3rem",top:"2.5rem"}}></div>
                 </div>



                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"50rem"}}>

                 <div style={{height:"2px",width:"13rem", backgroundColor:"white",position:"relative",left:"-10.5rem",top:"2.5rem"}}></div>
                 </div>


                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"57rem"}}>
             
                   <div style={{height:"2px",width:"12rem", backgroundColor:"white",position:"relative",left:"2.3rem",top:"2.5rem"}}></div>

                 </div>

                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"84rem"}}>
                   <div style={{height:"2px",width:"13rem", backgroundColor:"white",position:"relative",left:"-10.5rem",top:"2.5rem"}}></div>
                 </div>

                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"99rem"}}>
                   <div style={{height:"2px",width:"12rem", backgroundColor:"white",position:"relative",left:"2.3rem",top:"2.5rem"}}></div>
                 </div>

                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"113rem"}}>
                   <div style={{height:"2px",width:"13rem", backgroundColor:"white",position:"relative",left:"-10.5rem",top:"2.5rem"}}></div>
                 </div>

                 <div style={{height:'5rem',borderRadius:"50%",border:"1px solid white",width:"5rem",position:"absolute",left:"-0.65rem",top:"135rem"}}>
                   <div style={{height:"2px",width:"12rem", backgroundColor:"white",position:"relative",left:"2.3rem",top:"2.5rem"}}></div>
                 </div>


             </StyledMiddleLine>

         
             {/*RIGHT COLUMN */}
             <div style={{width:"35%",position:"relative",top:"18rem", display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center",gap:"5rem"}}>
             

             {/*1 */}
             <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
              
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Business Account Approved</h3>
              <p style={{fontSize:"1.5rem"}}>
              Once approved, companies can enhance their profile by adding more information. Our Talent Experts will then use this information to find candidates that align well with the company's culture, expectations, and other important aspects.
               
              </p>
 
            </div>
            
            {/*2 */}
             <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
              
             
              
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>New Applicants</h3>
              <p style={{fontSize:"1.5rem"}}>
              OneRecruiter will provide 5-8 qualified profiles, all at once, for each job posting. Once the company reviews the resumes and feedback, they can select the candidates they want to interview. There will be a line of communication available with the Talent Expert assigned to the job posting if the company has any updates to the job posting or questions about any of the candidates.
               
              </p>



            </div>
            
             {/*3 */}
             <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
               
             
              
              <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>Feedback</h3>
              <p style={{fontSize:"1.5rem"}}>
              OneRecruiter is committed to providing complete transparency and real-time updates to all involved in the hiring process. Recruiters who have candidates in the pipeline are required to promptly submit their profiles along with detailed feedback. Similarly, companies and panel members are expected to provide feedback no later than three business days after the candidate's interview. 
               
              </p>
             </div>






              {/*4 */}
              <div style={{border:"5px solid #FFFFFF",padding:"1rem",color:"white",textAlign:"center"}}>
               
             
              
               <h3 style={{paddingBottom:"1rem",borderBottom:"3px solid white",fontSize:"1.8rem"}}>For More Information</h3>
               <p style={{fontSize:"1.5rem"}}>
               Create a Business Account today and one of our team members will be in touch!
                
               </p>
              </div>





             </div>

            </div>

       </StyledWidth>

     </div>





      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"5rem",width:"48rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
            Business Account Application
            </Typography>
   
          <Divider style={{width:"55%",border:"2px solid #40255F",marginBottom:"6rem",marginTop:"1rem"}}/>

           

            <ApplicationForm />
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
       {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
