import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';


import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaBell, FaCircle } from "react-icons/fa";


import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';




import samplecv from 'src/assets/images/samplecv.png'
import { useDispatch, useSelector } from 'react-redux';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { AddJobToPendingAndAddCandidate, uploadCandidateResume } from 'src/redux/actions/group.action';
import { last } from 'lodash';
import { notifyErrorFxn } from 'src/utils/toast-fxn';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
 // maxWidth: 1200,
 width:"100%",
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function AddCandidateBusinessPageAction() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { user } = useSelector((state) => state.auth);

  console.log("location state is--->",location.state)

  const [candidateLocation, setCandidateLocation] = useState('');
 
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');

  const [yearsOfExp, setYearsOfExp] = useState('');
  const [currentTitle, setCurrentTitle] = useState('');
  const [currentEmployer, setCurrentEmployer] = useState('');
  const [noticePeriod, setNoticePeriod] = useState('');
  const [workAuthorization, setWorkAuthorization] = useState('');
  const [salaryRange, setSalaryRange] = useState('');
  const [additionalSalaryNote, setAdditionalSalaryNote] = useState('');
  const [candidateStrengths, setCandidateStrengths] = useState('');
  const [areasOfConcern, setAreasOfConcern] = useState('');
  const [additionalComments, setAdditionalComments] = useState('');

  const candidateData = {
    firstName,
    lastName,
    yearsOfExperience:yearsOfExp,
    candidateLocation,
    currentTitle,
    currentEmployer,
    noticePeriod,
    workAuthorization,
    salaryRange,
    additionalSalaryNote,
    candidateStrengths,
    areasOfConcern,
    additionalComments,
    businessId:location.state.row  && location.state.row.businessId
  }


 
  

  const rows= [
    {interviewRound:"Helena H.", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Oscar D.", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"Carlo E.", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},


    {interviewRound:"Daniel P.", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Mark R.", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"George L.", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},
    
  ];


  const [selectedFile, setSelectedFile] = useState({selectedFile: [], selectedFileName: []});
  const [file, setFile] = useState();
  const handleselectedFile = event => {
    setSelectedFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
    setFile(URL.createObjectURL(event.target.files[0]));
};



useEffect(() => {

   if(!location.state.row){
    navigate('/dashboard/active-job-postings-recruiter')
   }

})




//  useEffect(() => {
//
//    if(user && user.userType=== 'recruiter'){
//      dispatch(setRecruiter(true));
//      dispatch(setDemo(false));
//    }
//    else{
//  
//     dispatch( setRecruiter(false));
//     dispatch(setDemo(true));
//    }
//  },[])

  


  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '15rem',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
    
  
  };


  const inputContainerBig = {
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    width:'100%',
   
  };



  return (
    <>
      <Helmet>
        <title>Add Candidate Business Action | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%",position:"relative"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         
 {/* <p onClick={()=>{navigate('/dashboard/candidates-pending-review-candidates')}}
   style={{position:"absolute",left:"0%",top:"0%",display:"flex",alignItems:"center",gap:"0.5rem"}}> 
  <FaRegArrowAltCircleLeft/> 
  Back to Candidates List
          </p>*/}


      
       {/*THIRD SECTION  */}
          

           {/* <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center",marginBottom:"3rem" }}>

           <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
           <h1>Helena H.</h1>
           <i>Posted: 3/20/2024</i>
           <i>Status:Needs Attention</i>
           <i>Talent Expert: <span style={{color:"blue",textDecoration:"underline"}}>Jessica Campbell</span></i>
           </div>


           <div >
           <p  style={{fontSize:"22px",fontWeight:"900"}}>Job Posting ID: IT-543</p> 
           <p  style={{fontSize:"22px",fontWeight:"900"}}>Sr Full Stack Developer</p> 
            <p style={{border:"1px solid black",display:"flex",alignItems:"center",gap:"1rem",padding:"0.6rem"}}>
              Contact Talent Expert
              <FaBell/>
            </p>
            
            </div>

        </div> */}
   
        

        
            <Grid container xs={12} spacing={2} direction="row">
           
           
            <Grid item container xs={5} direction="row" style={{display:"flex",justifyContent:"flex-start",gap:"0rem"}}>
              
            
              <Grid item  xs={12} >
                <img src={samplecv} alt="sample resume"/>
                </Grid>


                <Grid item  xs={12} style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"2rem"}} >

                <div style={{marginBottom:"1rem",position:"relative",top:"-18rem"}}>{selectedFile.selectedFileName}</div>
                <Button component="label"  style={{position:"relative",top:"-20rem",color:"blue",padding:"1rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}}>
                
                  
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleselectedFile}
                   />
                  { "Upload Resume"}
                </Button>



                  
                 <Button onClick={()=>{
                 if(!location.state.row){
                  notifyErrorFxn("A Job was not selected , please go to the active job postings page and select 'add candidate'" )
                 }
                 else if(!selectedFile.selectedFile||selectedFile.selectedFile.length < 2 ){
                  notifyErrorFxn("Please upload a resume!")
                 }
                 else if(
                  !candidateLocation||
 
                  !firstName||
                 !lastName||
                
                   !yearsOfExp||
                   !currentTitle||
                   !currentEmployer||
                   !noticePeriod||
                   !workAuthorization||
                   !salaryRange||
                   !additionalSalaryNote||
                   !candidateStrengths||
                  !areasOfConcern||
                   !additionalComments
               ){
                notifyErrorFxn("Please make sure to fill in all fields!")
                 }
                 else{


                 dispatch(uploadCandidateResume(selectedFile.selectedFile,location.state.row.id,candidateData,location.state.row.jobRole))
                  
                 setTimeout(()=>{

               
                  setCandidateLocation('');
 
                  setFirstName('');
                 setLastName('');
                
                   setYearsOfExp('');
                   setCurrentTitle('');
                   setCurrentEmployer('');
                   setNoticePeriod('');
                   setWorkAuthorization('');
                   setSalaryRange('');
                   setAdditionalSalaryNote('');
                   setCandidateStrengths('');
                  setAreasOfConcern('');
                   setAdditionalComments('');





                 },800)
                 } 
                
                }}
                 style={{color:"green",padding:"1rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}}>Add Candidate</Button>


                 {/*<Button style={{color:"red",padding:"1rem",backgroundColor:"#e0dcdc", boxShadow:" 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)"}}>Reject, Not Moving Forward</Button>*/}
                 

                </Grid>



            </Grid>

            <Grid item container xs={7} direction="column">

            <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem" }}>First Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="first name"
                      required
                      placeholder='Tom'
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Last Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="last name"
                      required
                      placeholder='Smith'
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      /*style={inputStyle}*/
                      style={{width:"20rem"}}
                    />
                  </div>
                </Grid>
              </Grid>
            
             
            <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem" }}>Location*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="location"
                      required
                      placeholder='Baltimore MD'
                      value={candidateLocation}
                      onChange={(e) => setCandidateLocation(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Years of Experience*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="yearsOfExperience"
                      required
                      placeholder='10 years'
                      value={yearsOfExp}
                      onChange={(e) => setYearsOfExp(e.target.value)}
                      /*style={inputStyle}*/
                      style={{width:"20rem"}}
                    />
                  </div>
                </Grid>
              </Grid>



              <Grid container  spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Current Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="currentTitle"
                      required
                      placeholder='Sr. Full Stack Developer'
                      value={currentTitle}
                      onChange={(e) => setCurrentTitle(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem" }}>Current Employer*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Microsoft'
                      value={currentEmployer}
                      onChange={(e) => setCurrentEmployer(e.target.value)}
                      /*style={inputStyle}*/
                      style={{width:"20rem"}}
                    />
                  </div>
                </Grid>
              </Grid>




              <Grid container spacing={6} direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative",top:"0.8rem" }}>Notice Required*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="noticePerios"
                      required
                      placeholder='2 weeks'
                      value={noticePeriod}
                      onChange={(e) => setNoticePeriod(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Work Authorization Status*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="work authorization status"
                      required
                      placeholder='US Citizen'
                      value={workAuthorization}
                      onChange={(e) => setWorkAuthorization(e.target.value)}
                      /*style={inputStyle}*/
                      style={{width:"20rem"}}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid container spacing={6} direction="row">
                 

              <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Salary Range*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='$150k - $160k'
                      value={salaryRange}
                      onChange={(e) => setSalaryRange(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
                
                
                
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.8rem"  }}>Additional Salary Note space</b>
                  <div style={inputContainer}>
                     <textarea rows={5} 
                    name="jobResponsibilities"
                    placeholder='Add additional salary notes here'
                    value={additionalSalaryNote}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setAdditionalSalaryNote(e.target.value)}
                    
                    >
                    </textarea>
                  </div>
                </Grid>


              <Grid container direction="row" sx={{mt:2}}>
                <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",paddingLeft:"4rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-0.3rem"  }}>Candidate's Strength.*</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={5} 
                    name="Candidate's Strengths"
                    placeholder='Amazing experience in both the commercial and federal sector. Top performer 2 years in a row. Recently promoted. Well-versed in many typescript frameworks, has about 5yrs of backend with Node.JS'
                    value={candidateStrengths}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setCandidateStrengths(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>


              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",paddingLeft:"4rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-0.3rem"  }}>Areas of concern.</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={5} 
                    name="Areas of Concern"
                    placeholder='Not much. She was promoted quickly from her previous job, so i would be concerned about her expectations for promotion and ensure it aligns with the company'
                    value={areasOfConcern}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setAreasOfConcern(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>



              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",paddingLeft:"4rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-0.3rem"  }}>Additional comments</b>
                  <div style={inputContainerBig}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={5} 
                    name="Additional Comments"
                    placeholder='None'
                    value={additionalComments}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1.5rem",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setAdditionalComments(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>

               
              </Grid>
           
            </Grid>



            </Grid>


           </Grid>

   
               
           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
