import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle, FaHome } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  //fontFamily:"Libre Baskerville, sans serif",
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 900,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function SuccessApplicationPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title> Successful Application | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       <div  style={{position:"absolute",top:"0",backgroundColor:"#40255F",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",paddingTop:"1rem",paddingBottom:"1rem"}}>
       <FaHome onClick={()=>{navigate('/home')}}  style={{color:"white",fontSize:"2.2rem",position:"absolute",right:"1rem"}}/>
       
        <img style={{height:"100px"}} src={icon} alt={"one recruiter logo"}/>
        </div>

        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         {/*FIRST SECTION  */} 
          <Typography variant="h2" gutterBottom style={{width:"44rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
              Nice Work !
            </Typography>
   
          <Divider style={{width:"100%"}}/>

            <Typography variant="body2" sx={{ mt: 6, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
            Thank you for signing up for OneRecruiter Business Account. You're one step closer to a smoother, quicker and effortless hiring process.
               
              
            </Typography>



            
              <Typography variant="body2"  sx={{ mt: 2,mb: 2, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
                Please use the link below to schedule your consultation. The link has been sent to your email as well.
                Consultation Meeting:

              </Typography>

              <Typography variant="body2"  sx={{ mt: 2,mb: 2, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",maxWidth:"100%" }}>
              <a style={{color:"blue",textDecoration:"underline"}} href="https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ05HyLjKF3_CfLtQLszUCzeeswWEz2H5e5k6R9qZT6oguw6g2VCoqSTxHvtDR7p2_2BlBSdPmsU">
              https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ05HyLjKF3
              </a>

              </Typography>
            

       {/*FIRST SECTION END */}     



    



          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
