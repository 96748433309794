// component
import SvgColor from '../../../components/svg-color';
import {GiCooler} from 'react-icons/gi'


const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const demoConfig = [
 
 {
  title: 'Discover',
    path: '#',
    //icon: icon('ic_analytics'),
    children:[
      {
        title: 'Home',
        path: '/dashboard/home',
        icon: 'Home',
        
      },
    //  {
    //    title: 'Post a Job',
    //    path: '/dashboard/post-a-job',
    //    icon: 'Search',
    //   
    //  },
   //   {
   //    title: 'Add a Candidate',
   //    path: '/dashboard/add-candidate',
   //    icon: 'Search',
   //   
   //  },
      {
        title: 'Notifications',
        path: '/dashboard/chat',
         icon: 'Bell',
        iconLabel: 'msg',
      },

      {
        title: 'Active Job Postings',
        path: '/dashboard/active-job-postings-recruiter',
        icon: 'Speaker',
        
      },


      {
        title: 'My Jobs',
        path: '/dashboard/my-jobs-recruiter',
        icon: 'People',
        
      },
   //   {
   //     title: 'Action Items',
   //     path: '/dashboard/action-items',
   //     icon:'List',
   //     iconLabel: 'settings',
   //   },
    ]
 }
,
 
 
  
];

export default demoConfig;
