// component
import SvgColor from '../../../components/svg-color';
import {GiCooler} from 'react-icons/gi'


const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
 
 {
  title: 'Discover',
    path: '#',
    icon: icon('ic_analytics'),
    children:[
      {
        title: 'Home',
        path: '/dashboard/home',
        icon: 'Home',
        
      },
      {
        title: 'Add Job',
        path: '/dashboard/post-a-job',
        icon: 'Search',
       
      },
      {
        title: 'Add Candidate',
        path: '/dashboard/add-candidate-business',
         icon: 'Bell',
        iconLabel: 'msg',
      },
      {
        title: 'Action Items',
        path: '/dashboard/action-items',
        icon:'List',
        iconLabel: 'settings',
      },
    ]
 }
,


 {
  title: 'Explore',
    path: '#',
    icon: icon('ic_analytics'),
    children:[
     
      {
        title: 'Job Postings',
        path: '/dashboard/active-job-postings',
        icon: 'Speaker',
        
      },
      //{
      //  title: 'JOBS',
      //  path: '/dashboard/my-cooler',
      //  icon: /*icon('ic_disabled')*/<GiCooler style={{color:"white "}}/>,
      // 
      //},
      {
        title: 'Candidates',
        path: '/dashboard/candidates-pending-review',
         icon: 'People',
        iconLabel: 'msg',
      },
      {
        title: 'Interviews',
        path: '/dashboard/candidates-pending-selection',
        iconLabel: 'settings',
        icon: 'Star',
      },
      {
        title: 'Hired Candidates',
        path: '/dashboard/hired-candidates',
        iconLabel: 'settings',
        icon:'Done'
      },
     /* {
        title: 'Drafted Jobs',
        path: '/dashboard/drafted-jobs',
        iconLabel: 'settings',
        icon:'Drafted'
      },*/

      {
        title: 'Closed Jobs',
        path: '/dashboard/closed-jobs',
        iconLabel: 'settings',
        icon:'Closed'
      },
    ]
 }


 
 
  
];

export default navConfig;
