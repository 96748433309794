import React from 'react'
import './choice.css'
import { BsFillPatchCheckFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import agileDev from  'src/assets/images/motivationicon.png'
import businessThinking from  'src/assets/images/motivationicon.png'
import interDisc from  'src/assets/images/motivationicon.png'
import userCentric from  'src/assets/images/motivationicon.png'
import userFriendly from  'src/assets/images/motivationicon.png'
import scalable from  'src/assets/images/motivationicon.png'

const Choice = () => {
  return (
    <section id='choice'>
   
   <p style={{color:"#40255F",fontWeight:"700",fontSize:"3.5rem",paddingBottom:"1rem",borderBottom:"1px solid #40255F",width:"max-content",margin:"0 auto"}}> Our Motivation{/*<span className="text-accent">  get the Job! </span>*/}</p>
   <div className="container-hp choice__container">



      <div className="choice__backend">

     <div  className="choice-small-image" >
      <img src={userCentric} alt="" />
     </div>

       <div className="large-desc "  >
         <h3 style={{color:"#40255F",fontWeight:"800"}}>Our Story</h3>
         <p style={{color:"white"}}>
         Skilled independent recruiters often struggle with various aspects of the business, such as business development, marketing, negotiations, and legal documents. If they choose to work with an agency, they may receive 10 - 45% of their commission, and only if they meet a monthly billing minimum. It's time to level out the playing field and enable companies to hire from recruiters with integrity.
         </p>
       </div>
       
      </div>

       <div className="choice__backend">
        
       <div className="choice-small-image">
      <img src={interDisc} alt="" />
     </div>
      
      
     <div className="large-desc ">
       <h3 style={{color:"#40255F",fontWeight:"800"}}>Our Mission</h3>
       <p style={{color:"white"}}>
       To create cutting-edge staffing technology that automates custom interview processes, saving businesses time and money, while empowering independent recruiters with ownership, fair pay, and community.
       </p>
      </div>

       </div>

       <div className="choice__backend">
      
       <div className="choice-small-image">
      <img src={agileDev} alt="" />
     </div>
      
     <div className="large-desc ">
       <h3 style={{color:"#40255F",fontWeight:"800"}}>What We Do</h3>
       <p style={{color:"white"}}>
     OneRecruiter is a marketplace platform that connects the most talented independent recruiters in the country to job postings exlusively shared by our business partners. Creating a frictionless world by automating and managing critical phases of the interviewing process. Allowing businesses to hire quality candidates at the best rates in the market while empowering independent recruiters.
       </p>
      </div>

       </div>

     

       

   </div>

     
    

    </section>
  )
}

export default Choice