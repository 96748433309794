import { createSlice } from '@reduxjs/toolkit';

const initialState = {
       myGroups: [], 
       allGroups: [], 
       candidatesPendingReview:[],
       candidatesPendingInterview:[],
       jobsPendingInterview:[],
       jobsPendingReview:[],
       jobsPendingOffer:[],
       candidatesPendingOffer:[],
       candidatesAcceptedOffer:[],
       allJobs: [], 
       jobInFocus:{},
       candidateInFocus:{},
       allJobsRecruiter: [],
       allClosedJobs: [], 
       jobCandidates: [], 
       publicGroups: [], 
       feedbackFormDetails:{

       },
       privateGroups: [],
       groupMembers: [], 
       employeer: {}, 
       message: '',
      isLoading: false,
};

const groupSlice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    saveMyGroup: (state, action) => {
        state.myGroups = action.payload;
    },
    saveAllGroup: (state, action) => {
        state.allGroups = action.payload;
    },

    saveAllJobs: (state, action) => {
      state.allJobs = action.payload;
  },
  saveJobInFocus: (state, action) => {
    state.jobInFocus = action.payload;
},
saveCandidateInFocus: (state, action) => {
  state.candidateInFocus = action.payload;
},
  saveAllJobsRecruiter: (state, action) => {
    state.allJobsRecruiter = action.payload;
},
  saveAllClosedJobs: (state, action) => {
    state.allClosedJobs = action.payload;
},
  saveJobCandidates: (state, action) => {
    state.jobCandidates = action.payload;
},

saveCandidatesPendingReview: (state, action) => {
  state.candidatesPendingReview = action.payload;
},
saveJobsPendingReview: (state, action) => {
  state.jobsPendingReview = action.payload;
},
saveCandidatesPendingInterview: (state, action) => {
  state.candidatesPendingInterview = action.payload;
},
saveJobsPendingInterview: (state, action) => {
  state.jobsPendingInterview = action.payload;
},



clearCandidatesPendingReview: (state, action) => {
  state.candidatesPendingReview = [];
},
clearJobsPendingReview: (state, action) => {
  state.jobsPendingReview = [];
},





clearCandidatesPendingInterview: (state, action) => {
  state.candidatesPendingInterview = [];
},
clearJobsPendingInterview: (state, action) => {
  state.jobsPendingInterview = [];
},



saveCandidatesPendingOffer: (state, action) => {
  state.candidatesPendingOffer = action.payload;
},
saveJobsPendingOffer: (state, action) => {
  state.jobsPendingOffer = action.payload;
},


clearCandidatesPendingOffer: (state, action) => {
  state.candidatesPendingOffer = [];
},
clearJobsPendingOffer: (state, action) => {
  state.jobsPendingOffer = [];
},




saveCandidatesAcceptedOffer: (state, action) => {
  state.candidatesAcceptedOffer = action.payload;
},
saveJobsAcceptedOffer: (state, action) => {
  state.jobsAcceptedOffer = action.payload;
},


clearCandidatesAcceptedOffer: (state, action) => {
  state.candidatesAcceptedOffer = [];
},
clearJobsAcceptedOffer: (state, action) => {
  state.jobsAcceptedOffer = [];
},

saveFeedbackFormDetails: (state, action) => {
  state.feedbackFormDetails = action.payload;
},

    savePublicGroup: (state, action) => {
        state.publicGroups = action.payload;
    },
    savePrivateGroup: (state, action) => {
        state.privateGroups = action.payload;
    },
    saveGroupMembers: (state, action) => {
      state.groupMembers = action.payload;
  },
    saveEmployeer: (state, action) => {
      state.employeer = action.payload;
  },
    isItLoading: (state, action) => {
      state.isLoading = action.payload;
  },
    clearGroup: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

const { actions, reducer } = groupSlice;

export const {
 saveMyGroup,
 saveAllGroup,
 saveAllJobs,
 saveJobInFocus,
 saveCandidateInFocus,
 saveAllJobsRecruiter,
 saveAllClosedJobs,
 saveJobCandidates,
 saveCandidatesPendingReview,
 saveJobsPendingReview,

 clearCandidatesPendingReview,
 clearJobsPendingReview,


 saveCandidatesPendingInterview,
 saveJobsPendingInterview,

 clearCandidatesPendingInterview,
 clearJobsPendingInterview,

 saveCandidatesPendingOffer,
 saveJobsPendingOffer,

 clearCandidatesPendingOffer,
 clearJobsPendingOffer,


 saveCandidatesAcceptedOffer,
 saveJobsAcceptedOffer,

 clearCandidatesAcceptedOffer,
 clearJobsAcceptedOffer,

 saveFeedbackFormDetails,

 savePublicGroup,
 savePrivateGroup,
 saveGroupMembers,
 saveEmployeer,
 isItLoading,
 clearGroup
} = actions;

export default reducer;


