import React, { useState, useMemo, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
// import Button from '@material-ui/core/Button';
import { TextField, FormControl, FormControlLabel, RadioGroup, Radio, createMuiTheme, ThemeProvider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';
import { Divider, Chip, Grid, Paper, Typography, Box, Avatar, Button, ButtonBase, Stack, 
    ToggleButton, ToggleButtonGroup, Hidden  } from '@mui/material';
import { borderRadius } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RectangleIMG from '../../assets/images/cooler-img.png';
import { useNavigate } from 'react-router-dom';


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  });

const useStyles = makeStyles((theme) => ({
    textField: {
    padding: '8px',
     border: '1px solid grey',
    },
    paper: {
      display: "flex",
      width: "auto",
    },
    grid: {
      width: "auto",
    },
    arrow: {
      padding: theme.spacing(3),
    },
    box: {
    //   padding: theme.spacing(3),
      paddingLeft: theme.spacing(8),
    },
  }));


  const RespGrid = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      //gridTemplateColumns: "repeat(1, 1fr , 1fr)",
      flexDirection:"column",
      display:"none"
    },
  
    [theme.breakpoints.up('md')]: {
     // gridTemplateColumns: "repeat(2, 1fr, 1fr, 1fr, 1fr)",
     flexDirection:"row",
    },
  }));



  const useStyles2 = makeStyles((theme) => ({
    selected: {
      "&&": {
        // backgroundColor: theme.palette.primary.main,
        backgroundColor: 'black',
        color: theme.palette.secondary.main
      }
    }
  }));

  


function HomeCandidatesCard ({ /*groupId,*/ name,/* fee, count, status, img, startDate, members, isMember*/}) {
    const [isOpen, setIsOpen] = useState(false);
    const classes = useStyles();
    const classes2 = useStyles2();
    const [time, setTime] = useState('time');
    const [date, setDate] = useState(null);
    const [uid, setUid] = useState(null)
    let today = new Date().toISOString().slice(0, 10);
    const [nTime, setnTime] = useState(null);
    const navigate = useNavigate();
    // const { allUsers, connects, isLoading } = useSelector((state) => state.user);

    const notifyError = () => toast.error('Please select a date later than today!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });

    const handleChange = (event, newtime) => {

    };

    const setUser = (uid_btn, newtime) => {
     setnTime(newtime);
        if(date == null){
          notifyError();
         }else if(uid != uid_btn){
          alert('Select time from the dev date selected');
         } else{
          setIsOpen(true);
         console.log('UID: ', uid);
         console.log('Date Val: ', date);
         console.log('Selected Time is:', newtime);
         }
    };
    return (
        <>
          <Paper
          sx={{
            p: 1,
            pt: 2,
            pb: 2,
            margin: 'auto',
            // maxWidth: 500,
            border: '0px solid black',
            borderRadius: '7px',
            boxShadow: '0px 4px 16px rgba(60, 60, 60, 0.06)',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={3} lg={3.5}  style={{ width:"10rem",display: 'flex',justifyContent:"flex-start",alignItems:"center",paddingLeft:"3rem"}}>
            {/* <Avatar alt="Profile Pic" src={EmptyAvatar} style={{ width: '120px', height: '120px'}} /> */}
            
             {/* <ButtonBase sx={{ width: 108, height: 108 }}>
              <Img alt="complex" src={img ? img : RectangleIMG} />
              </ButtonBase>*/}

                <div style={{flexDirection:"column",display: 'flex',justifyContent:"flex-start",alignItems:"flex-start", border: '0px solid red'}}>
                  {/* <h2 style={{ fontSize: '18px'}}><b>NAME: </b></h2> */}
                  <h2 style={{ fontSize: '24px',marginBottom:"-15px"/*,color:"#130C66"*/}}><b>{name} </b></h2>
                    
                   <p style={{ fontSize: '16px'}}>{/*name.toUpperCase()*/'Cloud Architect'}</p> 
                  </div>

            </Grid>
            <Grid style={{backgroundColor:"transparent"}} item xs={10} sm={10} md={10} lg={8.5} container spacing={0}>
            

               {/*1 */} 
               <Grid item xs={2.5} direction="column" spacing={2}>
                
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" className={classes.box}>
               
                <Grid item xs={6} sm container spacing={1} justifyContent="center" alignItems="center">
                <Grid item display="flex" direction="column" justifyContent="center" alignItems="center" gap="0.5rem" sx={{mt: 3.5}}>
                <b style={{fontSize:"1.1rem"}}>Resume</b>
                <Button variant="contained" style={{minHeight: '45px', width: '125px', backgroundColor: '#7152CA', }}
                onClick={() => {
                 // const groupData = {groupId, name, fee, count, img, startDate, members}
                 // navigate('/dashboard/members', { state: { groupData } })
                }}>
                  VIEW
              </Button>
                </Grid>
                </Grid>
  
                </Box>
                  <br/>
                    <Grid container justify="center">
                    </Grid>
  
                </Grid>



                {/*2 */} 
              <Grid item xs={2.5} direction="column" spacing={2}>
                
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" className={classes.box}>
               
                <Grid item xs={6} sm container spacing={1} justifyContent="center" alignItems="center">
                <Grid item display="flex" direction="column" justifyContent="center" alignItems="center" gap="0.5rem" sx={{mt: 3.5}}>
                <b style={{fontSize:"1.1rem"}}>Cover Letter</b>
                <Button variant="contained" style={{minHeight: '45px', width: '125px', backgroundColor: '#7152CA', }}
                onClick={() => {
               //   const groupData = {groupId, name, fee, count, img, startDate, members}
               //   navigate('/dashboard/members', { state: { groupData } })
                }}>
                  VIEW
              </Button>
                </Grid>
                </Grid>
  
                </Box>
                  <br/>
                    <Grid container justify="center">
                    </Grid>
  
                </Grid>
    


          {/*3 */} 
          <Grid item xs={2.5} direction="column" spacing={2}>
                
                <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" className={classes.box}>
               
                <Grid item xs={6} sm container spacing={1} justifyContent="center" alignItems="center">
                <Grid item display="flex" direction="column" justifyContent="center" alignItems="center" gap="0.5rem" sx={{mt: 3.5}}>
                <b style={{fontSize:"1.1rem"}}>Survey</b>
                <Button variant="contained" style={{minHeight: '45px', width: '125px', backgroundColor: '#7152CA', }}
                onClick={() => {
                //  const groupData = {groupId, name, fee, count, img, startDate, members}
                //  navigate('/dashboard/members', { state: { groupData } })
                }}>
                  VIEW
              </Button>
                </Grid>
                </Grid>
  
                </Box>
                  <br/>
                    <Grid container justify="center">
                    </Grid>
  
                </Grid>




             {/*4 */} 
              <Grid item xs={2.5} direction="column" spacing={2} >
                
              <Box display="flex" alignItems="center" flexDirection="column" justifyContent="center" className={classes.box}>
             
              <Grid item xs={6} sm container spacing={1} justifyContent="center" alignItems="center">
              <Grid item display="flex" direction="column" justifyContent="center" alignItems="flex-start" style={{position:"relative",bottom:"15%"}}  gap="0.5rem" sx={{mt: 5}}>
             
             
              <Button variant="contained" style={{minHeight: '45px', width: '125px', backgroundColor: 'green', }}
              onClick={() => {
             //   const groupData = {groupId, name, fee, count, img, startDate, members}
             //   navigate('/dashboard/members', { state: { groupData } })
              }}>
                INTERVIEW
            </Button>


              <Button variant="contained" style={{minHeight: '45px', width: '125px', backgroundColor: '#C32914', }}
              onClick={() => {
               // const groupData = {groupId, name, fee, count, img, startDate, members}
               // navigate('/dashboard/members', { state: { groupData } })
              }}>
                DECLINE
            </Button>
             
              </Grid>
              </Grid>

              </Box>
                <br/>
                  <Grid container justify="center">
                  </Grid>

              </Grid>


            </Grid>
          </Grid>
        </Paper>
        <br/>

        </>

      );
}

export default HomeCandidatesCard