import React from 'react'
import './about3.css'
import about3graphic from 'src/assets/images/about3graphic.png'

import {AiOutlineArrowRight} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const About3 = () => {


const navigate= useNavigate()


  return (

    <>

  



    <section id='about3'>


    <p className="howAreWeDiff" style={{color:"#40255F",fontWeight:"700",fontSize:"3rem",paddingBottom:"1rem",borderBottom:"1px solid #40255F",width:"max-content",margin:"0 auto",}}> How Are We Different</p>

    <div className="container-hp about3__container">
       
      <div className="about3Explainer">
     
     

       <div>
        <p>Low Service Fees </p>
        <p>Manage the Entire Hiring Process</p>
        <p>Process Transactions </p>
        <p>Web Application</p>
        <p>Business Account Mobile App </p>
       
        <p>Candidate Experience Focus</p>
      </div>

     


       {/*<div className = "about-btn-placement" >
        <button   onClick={()=>{navigate('/login')}} className="btn ">
           Get Started 
         </button>

         <p  onClick={()=>{navigate('/dashboard/home')}} className="btn-invisible" >See How It Works</p>
     </div>*/}


      </div>
     
     {/*<div className='aboutCTA'>
    
       <img  className='CTAbox' src={countPennies} alt="counting coins"/>
     

     <div className='clip-path-bottom-right'></div>
     <div className='clip-path-bottom-left'></div>
     <div className='clip-path-top-left'></div>
</div>*/}  


{<div className='about3CTA'>
    
 
    <img  className='CTA3boximg' src={about3graphic} alt="search image"/>
    
       
        
        </div>}
      

      
   
   
</div>

    </section>


</>
  )
}

export default About3