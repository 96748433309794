import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import Hidden from '@mui/material/Hidden';
import Icon from '@mui/material/Icon';
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from '@mui/material/IconButton';
import { FaMessage } from "react-icons/fa6";


import Paper from '@mui/material/Paper';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';




import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
//import withReducer from 'app/store/withReducer';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Chat from './Inbox';
import ChatsSidebar from './InboxSidebar';
import ContactSidebar from './ContactSidebar';
import StatusIcon from './StatusIcon';

//import reducer from './store';
//import { getUserData } from './store/userSlice';
import { Link, useNavigate, /*Redirect*/ } from 'react-router-dom';
//import { selectContactById, getContacts } from './store/contactsSlice';
//import {
//  closeContactSidebar,
//  openContactSidebar,
//  openMobileChatsSidebar,
//  closeUserSidebar,
//  closeMobileChatsSidebar,
//} from './store/sidebarsSlice';

import UserSidebar from './UserSidebar';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
//import { fetchRealTimeConnections, fetchRealTimeConnections2, fetchRealTimeUsers } from 'redux/actions/user.action';

const drawerWidth = 400;
const headerHeight = 200;

const useStyles = makeStyles((theme) => ({
  '@global': {
    '#fuse-main': {
      height: '100vh',
      
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '100%',
    position: 'relative',
    flex: '1 1 auto',
    height: 'auto',
    backgroundColor:theme.palette.background.default,
   
  },
  topBg: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    height: headerHeight,
    //backgroundImage: 'url("../../assets/images/backgrounds/header-bg.png")',
    backgroundColor: theme.palette.primary.dark,
    backgroundSize: 'cover',
    pointerEvents: 'none',

    
  },
  contentCardWrapper: {
    position: 'relative',
    padding: 24,
    maxWidth: 1400,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    width: '100%',
    minWidth: '0',
    maxHeight: '100%',
    margin: '0 auto',
    [theme.breakpoints.down('sm')]: {
      padding: 16,
    },
    [theme.breakpoints.down('xs')]: {
      padding: 12,
    },
  },
  contentCard: {
    display: 'flex',
    position: 'relative',
    flex: '1 1 100%',
    flexDirection: 'row',
   // backgroundImage: 'url("/assets/images/patterns/rain-grey.png")',
    backgroundColor:theme.palette.background.paper,
    minHeight: 0,
    overflow: 'hidden',
  },
  drawerPaper: {
    width: drawerWidth,
    maxWidth: '100%',
    overflow: 'hidden',
    height: '100%',
    [theme.breakpoints.up('md')]: {
      position: 'relative',
    },
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 100%',
    zIndex: 10,
    background: `linear-gradient(to bottom, ${alpha(theme.palette.background.paper, 0.8)} 0,${alpha(
      theme.palette.background.paper,
      0.6
    )} 20%,${alpha(theme.palette.background.paper, 0.8)})`,
  },
  content: {
    display: 'flex',
    flex: '1 1 100%',
    minHeight: 0,
  },
}));

function NotificationsPage() {
  const dispatch = useDispatch();
  //const chat = useSelector(({ chatApp }) => chatApp.chat);
 // const mobileChatsSidebarOpen = useSelector(
 //   ({ chatApp }) => chatApp.sidebars.mobileChatsSidebarOpen
 // );

  //const userSidebarOpen = useSelector(({ chatApp }) => chatApp.sidebars.userSidebarOpen);
  //const contactSidebarOpen = useSelector(({ chatApp }) => chatApp.sidebars.contactSidebarOpen);
  
  //const selectedContact = useSelector((state) =>
  //  selectContactById(state, state.chatApp.contacts.selectedContactId)
  //);


  //const { isAuth, user } = useSelector((state) => state.login);

  const { user } = useSelector((state) => state.auth);
  const { selectedChatUser } = useSelector((state) => state.chat);
  //const { allUsers, isLoading } = useSelector((state) => state.user);
  let unsubscribe;
  const classes = useStyles();


  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  

 // useEffect(() => {
 //   dispatch(getUserData());
 //   dispatch(getContacts());
 // }, [dispatch]);


  //useEffect(() => {
  //  unsubscribe = dispatch(fetchRealTimeUsers(user.uid))
  //  unsubscribe = dispatch(fetchRealTimeConnections(user.uid))
  //  unsubscribe = dispatch(fetchRealTimeConnections2(user.uid))
  //  .then(unsubscribe => {
  //    return unsubscribe;
  //  })
  //  .catch(error => {
  //    console.log(error);
  //  })
  //
  //}, []);
  //
  
    //componentWillUnmount

  //  useEffect(() => {
  //    return () => {
  //      //cleanup
  //      console.log("I AM DOING A CHECK HERE!-->")
  //      unsubscribe.then(f => f()).catch(error => console.log(error));
  //    }
  //  }, []);

 /* if (!isAuth) return <Redirect to={'/login'}/>*/
  return (
    <div className={clsx(classes.root)}>
      {/*<div className={classes.topBg} />*/}

      <div className={clsx(classes.contentCardWrapper, 'container')} >
        <div className={clsx(classes.contentCard, ' rounded-20')}  style={{borderRadius:"0.3rem",border:"1px solid lightgrey"}}>
          <Hidden mdUp>
            <SwipeableDrawer
              className="h-full absolute z-20"
              variant="temporary"
              anchor="left"
            open={false/*mobileChatsSidebarOpen*/}
              onOpen={(ev) => {}}
              onClose={() => {/*dispatch(closeMobileChatsSidebar())*/}}
              disableSwipeToOpen
              classes={{
                paper: clsx(classes.drawerPaper, 'absolute ltr:left-0 rtl:right-0'),
              }}
              style={{ position: 'absolute' }}
              ModalProps={{
                keepMounted: true,
                disablePortal: true,
                BackdropProps: {
                  classes: {
                    root: 'absolute',
                  },
                },
              }}
            >
              <ChatsSidebar />
            </SwipeableDrawer>
          </Hidden>
          <Hidden smDown>
            <Drawer
              className="h-full z-20"
              variant="permanent"
              open
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              {/* Showing list of chats */}
              <ChatsSidebar /> 
            </Drawer>
          </Hidden>
          <SwipeableDrawer
            className="h-full absolute z-30"
            variant="temporary"
            anchor="left"
            open={/*userSidebarOpen*/false}
            onOpen={(ev) => {}}
            onClose={() => {/*dispatch(closeUserSidebar())*/}}
            classes={{
              paper: clsx(classes.drawerPaper, 'absolute left-0'),
            }}
            style={{ position: 'absolute' }}
            ModalProps={{
              keepMounted: false,
              disablePortal: true,
              BackdropProps: {
                classes: {
                  root: 'absolute',
                },
              },
            }}
          >
            <UserSidebar />
          </SwipeableDrawer>

          <main className={clsx(classes.contentWrapper, 'z-10')} >
            {!selectedChatUser ? (
              <div className="flex flex-col flex-1 items-center justify-center p-24" style={{backgroundColor:"transparent",backgroundImage:!selectedChatUser && 'url("/assets/images/patterns/rain-grey.png")'}}>
                <div className="rounded-full p-46 md:p-60 shadow-xl">
                  {/*<Icon className="block text-48 md:text-64" color="secondary">*/}
                   <ChatIcon style={{color:"#40255F"}}   className="block text-48 md:text-64" color="secondary"/>
                  {/*</Icon>*/}
                </div>
               
                <div style={{fontSize:"2rem"}} className="mt-28 mb-12 text-50 font-900">
                  Chat with candidates
                </div>

                <div
                  className="hidden md:flex px-16 pb-24 text-16 text-center"
                  color="textSecondary"
                >
                  Select a user to start a conversation! 
                </div>
                <button
                  variant="outlined"
                  color="primary"
                  className="flex md:hidden"
                  onClick={() => {/*dispatch(openMobileChatsSidebar())*/}}
                >
                  Select a user to start a conversation!..
                </button>
              </div>
            ) : (
              <>
                <AppBar className="w-full" elevation={0} position="static" style={{display:"flex",justifyContent:"space-between",flexDirection:"row",paddingRight:"3rem",backgroundColor:"white",color:"black",borderBottom:"1px solid #E0E0E0"}}>
                  <Toolbar className="px-16">
                   {/* <IconButton
                      color="inherit"
                      aria-label="Open drawer"
                     // onClick={() => {dispatch(openMobileChatsSidebar())}}
                      className="flex md:hidden"
                    >
                      <Icon><ChatIcon style={{position:"relative",top:"-0.5rem"}}/></Icon>
                       
                    </IconButton> */}
                    <div
                      className="flex items-center cursor-pointer"
                      //onClick={() => {dispatch(openContactSidebar())}}
                     // onKeyDown={() => {dispatch(openContactSidebar())}}
                      role="button"
                      tabIndex={0}
                    >
                      <div className="relative mx-8" >
                        <div className="absolute right-0 bottom-0 -m-4 z-10">
                          {/*<StatusIcon status={'online'} />*/}
                        </div>
                        <Avatar src={selectedChatUser && selectedChatUser.photoUrl} alt={selectedChatUser &&  selectedChatUser.name}>
                          {!selectedChatUser.photoUrl ||selectedChatUser &&  selectedChatUser.photoUrl === ''
                            ? selectedChatUser && selectedChatUser.name && selectedChatUser.name[0]
                            : ''}
                        </Avatar>



                      </div>
                      <Typography color="inherit" className="text-18 font-semibold px-4" style={{display:"flex",flexDirection:"column"}}>
                      <p> {selectedChatUser &&  selectedChatUser.name}</p>
                        <p style={{color:"gray"}}>{"Talent Expert"}</p>
                      </Typography>
                    </div>
                  </Toolbar>


                        <div style={{display:"flex",flexDirection:"column",gap:"0.1rem",justifyContent:"center",alignItems:"center"}}>
                         <div>JP ID:<u>IT-22</u></div>
                        <div style={{color:"gray"}}>Sr. Full Stack Developer</div>
                        </div>

                </AppBar>
                 
                <div className={classes.content}>
                  <Chat className="flex flex-1 z-10" />
                </div>
              </>
            )}
          </main>

          <SwipeableDrawer
            className="h-full absolute z-30"
            variant="temporary"
            anchor="right"
          open={/*contactSidebarOpen*/false}
            onOpen={(ev) => {}}
            onClose={() => {/*dispatch(closeContactSidebar())*/}}
            classes={{
              paper: clsx(classes.drawerPaper, 'absolute ltr:right-0 rtl:left-0'),
            }}
            style={{ position: 'absolute' }}
            ModalProps={{
              keepMounted: true,
              disablePortal: true,
              BackdropProps: {
                classes: {
                  root: 'absolute',
                },
              },
            }}
          >
            <ContactSidebar />
          </SwipeableDrawer>
          
        </div>
      </div>
    </div>
  );
}

//export default withReducer('chatApp', reducer)(InboxApp);

export default NotificationsPage;
