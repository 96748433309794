import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signin, signinBusiness } from 'src/redux/actions/auth.action';

// ----------------------------------------------------------------------

export default function LoginFormBusiness() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch(); 

  const userSignin = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { email, password };
    dispatch(signinBusiness(user, navigate, setLoading));
  }

  return (
    <div style={{width:"80%",fontFamily:"Public Sans, sans-serif ",}}>
     <form onSubmit={userSignin}>
      <Stack style={{fontFamily:"Public Sans, sans-serif ",}} spacing={3}>
        <TextField required name="email" type="email" label="Email address" 
         InputProps={{ 
          style:{fontFamily:"Public Sans, sans-serif ",
          
         }}
        }
        
        onChange={(e) => setEmail(e.target.value)}/>

        <TextField
          name="password"
          label="Password"
          required
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            style:{fontFamily:"Public Sans, sans-serif ",
           
            },
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack  direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <Checkbox name="remember" label="Remember me" />
        <Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link> */}
      </Stack>

      <LoadingButton  sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif ",}} fullWidth size="large" type="submit" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "Login"}
      </LoadingButton>
      </form>


      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2,color:"gray" ,fontFamily:"Public Sans, sans-serif ",}}>
        <div style={{fontFamily:"Public Sans, sans-serif ",}}>
          or continue with
        </div>
      </Stack>


   
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
         <LoadingButton  onClick ={()=>{navigate('/application')}} sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif ",}} fullWidth size="large"  variant="contained" disabled={false}>
        {"Create an Account"}
      </LoadingButton>
      </Stack>


      <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 2,color:"gray" }}>
        <div style={{fontFamily:"Public Sans, sans-serif ",}}>
        By clicking continue, you agree to our terms of service and privacy policy
        </div>
      </Stack>




    </div>
  );
}
