import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllClosedJobsForMyBusiness } from 'src/redux/actions/group.action';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function ClosedJobsPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('');


  const { user } = useSelector((state) => state.auth);

  const { myGroups,allClosedJobs, isLoading } = useSelector((state) => state.group);


  useEffect(() => {
    dispatch(fetchAllClosedJobsForMyBusiness(user && user.uid));
  }, [user])


  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  

  const rows= [
    {jobId:"IT-22", title:"Lead Product Designer",   status:"Live",hiringManager:"Michael Davis", talentExpert:"Tina Fay"},
    {jobId:"IT-522", title:"Data Architect",   status:"Live",hiringManager:"Tamera Johnson", talentExpert:"Karla Green"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Assigned",hiringManager:"Christopher Morris", talentExpert:"Fredrick Jordan"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Needs Attention",hiringManager:"Tammy Morris", talentExpert:"Dorian Smith"},
    {jobId:"OPS-22", title:"Director of People Operations",   status:"Assigned",hiringManager:"Maria Ortiz", talentExpert:"Devin Dottson"},
  ];

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Closed Jobs | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem",/*color:"#40255F",*/display:"flex",justifyContent:"center",marginBottom:"3rem"}}>
            Closed Jobs
            </Typography>
   
          {/*<Divider style={{width:"100%",marginBottom:"3rem"}}/>*/}

          <Typography variant="body2" sx={{ mt: 1,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" }}>
           The list below is a list of closed jobs. These are jobs that have been previusly dealt with, signed off on by Talent Experts and then closed.
          
               
              
            </Typography>



        
           <div style={{padding:"1rem",borderRadius:"0.5rem",border:"2px solid lightgray",width:"66rem"}}>
            <TableContainer sx={{py:"2rem", width: "100%",}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF"}}>

                          <TableCell sx={{color:"black",fontSize:"1rem",fontFamily:"Public Sans, sans-serif",}}  align="center">JP ID</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1rem",fontFamily:"Public Sans, sans-serif",}} align="center">Title</TableCell>
                          {/*<TableCell  sx={{color:"black",fontSize:"1rem"}} align="center">Status</TableCell>*/}
                          <TableCell  sx={{color:"black",fontSize:"1rem",fontFamily:"Public Sans, sans-serif",}} align="center">Hiring Manager</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1rem",fontFamily:"Public Sans, sans-serif",}} align="center">Talent Expert</TableCell>
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {allClosedJobs && allClosedJobs.length? allClosedJobs.map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{my:2, '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{ backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          <TextField  
                            name="interview round"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              },
                              }}
                            placeholder='Round -1'
                            value={rows[0].jobId}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />
                            
                      
                            </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              },
                              }}
                            placeholder='Virtual'
                            value={row.jobRole}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  

                            </TableCell>


                          {  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="duration"
                           inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif ",
                            },
                            }}
                           placeholder='60 mins'
                           value={rows[1].status}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> }


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif ",}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif ",
                            },
                            }}
                          placeholder=''
                          value={row.hiringManagerName?row.hiringManagerName:rows[0].hiringManager}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif ",}}
                          />  
                          
                          </TableCell>

                          <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif ",}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif ",
                            },
                            }}
                          placeholder=''
                          value={row.recruiterName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif ",}}
                          />  
                          
                          </TableCell>

                          </TableRow>
                        )):
                        <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
                        <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"3rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Jobs You have closed will be displayed here... </center></TableCell>
                        </TableRow>
                        }
                      </TableBody>
                    </Table>
                </TableContainer>
            </div>






         



       

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
