import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Container, Chip, Typography, Divider } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { signup, uploadImage } from 'src/redux/actions/auth.action';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link, Stack, CssBaseline, IconButton, InputAdornment, TextField, Grid, Button } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import { LoadingButton } from '@mui/lab';
import { createGroup, fetchMyGroups } from 'src/redux/actions/group.action';
import EmptyRowCard from 'src/components/home/empty-row-card';
import HomeCandidatesCard from 'src/components/home/home-candidates-card';
import chase from 'src/assets/images/Group_Logo.png'
import microsoft from 'src/assets/images/microsoft.jpeg'
import { fCurrency } from '../utils/formatNumber';
import CandidatesInterviewCard from 'src/components/home/candidates-interview-card';
import JobIntroCard from 'src/components/my-cooler/job-intro-card';
import AvailabilityCard from 'src/components/my-cooler/availability-card';

const StyledRoot = styled('div')(({ theme }) => ({
  //fontFamily:"Libre Baskerville, sans serif",
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

export default function ViewFullJobPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [employeer, setEmployeer] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [selectedFile, setSelectedFile] = useState({ selectedFile: [], selectedFileName: [] });
  const [file, setFile] = useState();

  const [state, setState] = useState({
    jobRole:"",
    businessName: "",
    recruiterName: "",
   jobResponsibilities:"",
    startDate: "",
    salary: "",
    amount: "",
    yearsOfExperience:""
  })

  const handleChange = (e) => {
    const value = e.target.value;
    setState({
      ...state,
      [e.target.name]: value
    });
  }

  const handleselectedFile = (event) => {
    setSelectedFile({
      selectedFile: event.target.files[0],
      selectedFileName: event.target.files[0].name,
    });
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const userSignup = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { fname, lname, email, employeer, password };
    console.log('USER: ', user);
    dispatch(signup(user, navigate, setLoading));
  };


  const createThisGroup = (e) => {
    e.preventDefault();
   console.log("GROUP OR JOB, IS CREATING!")
    setLoading(true);
   /* const id = user.id;
    const name = user.firstName + " " + user.lastName;
    const email = user.email;
    const profileImg = user.imageUrl;*/
    //const userData = {id, name, email, profileImg};
    let userData;
    dispatch(createGroup(state/*,selectedFile.selectedFile,*/, userData, navigate, setLoading));
  }

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
  };


  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);

  useEffect(() => {
    dispatch(fetchMyGroups(user.coolers));
  }, [user])


  const myCoolerGroups = myGroups?.length ? (
    myGroups.map((group,index) => {
      return (
        <HomeCandidatesCard 
        groupId={group.groupId}
      name={index === 0 ? 'Franklin Edwards':'Joel Nathaniel'} 
      fee={fCurrency(group.amount)}
      count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
      img={chase}
      members={group.members}
      isMember={group.members.includes(user?./*u*/id)}
      startDate={group.startDate}
        />
      )
    })
  ) : 
  <>
  <EmptyRowCard msg={"Coolers you have joined will appear here."}/>
  </>




console.log("MY GROUPS: ", myGroups);
const myInterviewGroups = myGroups?.length ? (
  myGroups.slice(0,1).map((group,index) => {
    return (
      <CandidatesInterviewCard 
      groupId={group.groupId}
    name={index === 0 ? 'Alicia Smith':'Joel Nathaniel'} 
    fee={fCurrency(group.amount)}
    count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
    img={chase}
    members={group.members}
    isMember={group.members.includes(user?./*u*/id)}
    startDate={group.startDate}
      />
    )
  })
) : 
<>
<EmptyRowCard msg={"Coolers you have joined will appear here."}/>
</>





  return (
    <>
    <Container style={{ display: 'flex', justifyContent: 'center',alignItems:"center" ,flexDirection:"column",width:"90%"}}>
      <div
        component="main"
        maxWidth="lg"
        style={{ background: /*'#6077F00F'*/'white', margin: '20px',width:"99%",borderRadius:"1rem"}}
      >
        <div style={{paddingBottom:"2rem",}}>
          <CssBaseline />
          <br />
          <br />
          <div style={{ marginLeft: '8%' }}>
            <p style={{ fontSize: '20px', color: '#130C66' }}>
              <b>Edit Job</b>
            </p>
          </div>
          <hr />
          <form onSubmit={createThisGroup}>
          <Grid container direction="column" spacing={6} style={{ paddingLeft: '100px', paddingRight: '100px' }}>
          <Grid item xs={12} >
              <Grid container direction="row">
                <Grid item xs={4}>
                  <p style={{ fontSize: '17px', width: '60%' }}>Job Role</p>
                  <div style={inputContainer}>
                    <TextField
                      name="jobRole"
                      required
                      value={state.jobRole}
                      onChange={handleChange}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <p style={{ fontSize: '17px', width: '60%' }}>Salary($)</p>
                  <div style={inputContainer}>
                    <TextField
                      name="salary"
                      required
                      value={state.salary}
                      onChange={handleChange}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <p style={{ fontSize: '17px', width: '60%' }}>Start Date</p>
                  <div style={inputContainer}>
                    <TextField
                      name="startDate"
                      required
                      type="string"
                      onChange={handleChange}
                      style={inputStyle}
                      value={state.startDate}
                    />
                  </div>
                </Grid>

              </Grid>

             


              <Grid container direction="row">
                <Grid item xs={12}>
                  <p style={{ fontSize: '17px', width: '60%' }}>Job Responsibilities</p>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={20} 
                    name="jobResponsibilities"
                    value={state.jobResponsibilities}
                    style={{borderRadius:"1rem",width:"100%",fontFamily:"Arial",fontSize:"1.3rem",padding:"1rem"}}
                    onChange={handleChange}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>



              <Grid container direction="row">
               {/* <Grid item xs={4}>
                  <p style={{ fontSize: '17px', width: '40%' }}>Password</p>
                  <div style={inputContainer}>
                    <TextField
                      name="password"
                      required
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                              <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}></Grid>*/}

                <Grid container direction="row" style={{marginTop: '2%'}}>
              <Grid item xs={2}>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" disabled={loading} style={{background: '#7152CA'}}>
                  {loading ? 'Loading...' : 'Submit'}
                </LoadingButton>
              </Grid>
              &nbsp; &nbsp; &nbsp;
              <Grid item xs={2}>
                <LoadingButton onClick={() => {
                  navigate(-1);
                }} fullWidth size="large" variant="contained" style={{background: '#7152CA'}}>
                  { 'Cancel'}
                </LoadingButton>
              </Grid>
            </Grid>
              </Grid>
            </Grid>
          </Grid>
          </form>
        </div>
      </div>

   
      <div style={{background:'white',  padding: '10px',marginTop:"3rem",width:"100%",borderRadius:"1rem"}}>
                <h3 style={{color:"#130C66"}} >Candidates</h3>
                <Divider  style={{marginTop:"-1rem"}}/>
                <br/>
                {myCoolerGroups}
       </div>



       <div style={{background: 'white',  padding: '10px',marginTop:"3rem",width:"100%",borderRadius:"1rem"}}>
                <h3 style={{color:"#130C66"}} >Interviews</h3>
                <Divider  style={{marginTop:"-1rem"}}/>
                <br/>
                {myInterviewGroups}
        </div>



        <div style={{background: 'white',  padding: '10px',marginTop:"3rem",width:"100%",borderRadius:"1rem"}}>
                <h3 style={{color:"#130C66"}} >Availability</h3>
                <Divider  style={{marginTop:"-1rem"}}/>
                <br/>
        <AvailabilityCard 
        groupId={1}
        name={"Frontend Developer"} 
        fee={fCurrency(50000)}
        feeInNum={250}
        count={`${1} OF ${3} SAVERS`}
        img={microsoft}
        members={25}
        isMember={true}
        startDate={'2012-04-24'}
        />
        </div>

  </Container>
    </>
  );
}
