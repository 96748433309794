import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, 
      InputAdornment, TextField, Checkbox,
       Typography, Grid, FormControlLabel, RadioGroup, 
       FormControl, FormLabel, Radio } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signinBusiness } from 'src/redux/actions/auth.action';
import REC from 'src/assets/images/rec.png'

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

// ----------------------------------------------------------------------

export default function ProfileForm() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [bio, setBio] = useState('');

  const [acctHolderName, setAcctHolderName] = useState('');
  const [bestContactNo, setBestContactNo] = useState('');

  const [positionTitle, setPositionTitle] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [employeeNum, setEmployeeNum] = useState('');
  const [yearFounded, setYearFounded] = useState('');
  const [companySocMedia, setCompanySocMedia] = useState('');

  const [companyBio, setCompanyBio] = useState('');

  const [additionalBenefits, setAdditionalBenefits] = useState('');

  const [companyCulture, setCompanyCulture] = useState('');
  const [companyOnboarding, setCompanyOnboarding] = useState('');

  const [howDidYouHear, setHowDidYouHear] = useState('');


  const dispatch = useDispatch(); 

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };


  const rows = [
    {source:"Total Active Job Postings",stats:"25"},
    {source:"Total Hires",stats:"64"},
    {source:"Total Job Postings Terminated",stats:"3"},
    {source:"Total Interviews Cancelled w/o Rescheduling",stats:"0"},
    {source:"Average Response Time",stats:"Same Day"},
    {source:"Last Active",stats:"3 days ago"},
  ];


  const rows2 = [
    {event:"Team Interview -Virtual",date:"3/4/2024"},
    {event:"Team Interview -Virtual",date:"3/11/2024"},
    {event:"Team Interview -Virtual",date:"3/11/2024"},
    {event:"Team Interview -Virtual",date:"3/11/2024"},
    {event:"Team Interview - Onsite",date:"3/11/2024"},
    {event:"HR Interview - Onsite",date:"3/11/2024"},
  ];


  const userSignin = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { email, password };
    dispatch(signinBusiness(user, navigate, setLoading));
  }

  return (
    <div style={{width:"80%",zIndex:"0"}}>
     <form onSubmit={userSignin}>

     <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
              Profile Information
            </Typography>

      <Grid container spacing={0}  direction="row">
         

      <Grid item container xs={3} direction="row">
       <img src={REC} style={{height:"10rem",width:"10rem"}} alt={"profile img"}/>
      </Grid>


      <Grid item container xs={9}  direction="row">
            <Grid item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Company name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='first and last name'
                      value={acctHolderName}
                      onChange={(e) => setAcctHolderName(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Company Website*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='www.company.com'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
              </Grid>


         

              



     
      
              <Grid  item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>LinkedIn Page*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder=''
                      value={bestContactNo}
                      onChange={(e) => setBestContactNo(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Other Links</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder=''
                      value={positionTitle}
                      onChange={(e) => setPositionTitle(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
              </Grid>
         </Grid>
          </Grid>




          <Grid item container xs={12} sx={{my:5}}  direction="row">
                <Grid item xs={2.4} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Location(HQ)*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='first and last name'
                      value={acctHolderName}
                      onChange={(e) => setAcctHolderName(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={2.4} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Total # Employees*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Enter #'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>


                <Grid item xs={2.4} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Year Founded*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='www.company.com'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>


                <Grid item xs={2.4} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Company Type*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='www.company.com'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>


                <Grid item xs={2.4} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.7rem"  }}>Industry*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='www.company.com'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
              </Grid>




              <Typography variant="h3" gutterBottom style={{marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
              Employee Information
            </Typography>
              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12} style={{display:"flex",flexDirection:"column",gap:"1.5rem"}}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"0.7rem"  }}>Company Bio: What Do You Do ?.*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={8} 
                    name="jobResponsibilities"
                    placeholder='A company bio is a brief description of a company'
                    value={companyBio}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setCompanyBio(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>






          
              


              <Grid container spacing={6}  sx={{mt:3}} direction="row">
                
              <Grid container item xs={6}>  
               
              <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-0.7rem"  }}>Employee Benefits*</b>
                <Grid item xs={6} style={{marginTop:"-3rem"}}>
                
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel value="unemployed" control={<Radio />} label="Medical" />
                     <FormControlLabel value="Self-employed" control={<Radio />} label="Dental" />
                     <FormControlLabel value="full-time W-2 Employee" control={<Radio />} label="Vision" />
                     <FormControlLabel value="full-time c2c" control={<Radio />} label="Stock Options" />
                     <FormControlLabel value="part-time" control={<Radio />} label="401(k)" />
                    
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>


                <Grid item xs={6} style={{marginTop:"-3rem"}}>
                  
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                    
                     <FormControlLabel value="other" control={<Checkbox />} label="Company 401(k) Match" />
                    
                     <FormControlLabel value="othera" control={<Checkbox />} label="Tuition Assistance" />
                     <FormControlLabel value="otherb" control={<Checkbox />} label="Unlimited PTO" />
                     <FormControlLabel value="otherc" control={<Checkbox />} label="Free Lunches" />
                     <FormControlLabel value="otherd" control={<Checkbox />} label="4-Day Work Week" />
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>
              </Grid>

                <Grid item xs={5.5} >
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-0.7rem" }}>Additional employee benefits information and programs*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={11} 
                    name="jobResponsibilities"
                    value={additionalBenefits}
                    placeholder="
                    . Health Savings Account &#32;"
                    


                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setAdditionalBenefits(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>
              </Grid>



    


              <Grid container direction="row" sx={{mt:6}}>
                <Grid item xs={12} style={{display:"flex",flexDirection:"column",gap:"1rem"}}>
                  <b style={{ fontSize: '17px', width: '60%'}}>Awards/Accolades/Press*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={companyCulture}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setCompanyCulture(e.target.value)}
                    placeholder=' Enter company culture'
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>




              <Grid container direction="row" sx={{mt:6}}>
                <Grid item xs={12} >
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-0.7rem" }}>Employee Onboarding Process. Once a candidate signs their new offer letter, what should they expect?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={companyOnboarding}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontFamily:"Arial",fontSize:"1rem"}}
                    onChange={(e) => setCompanyOnboarding(e.target.value)}
                    placeholder=' Enter onboarding process '
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>





              <Typography variant="h3" gutterBottom style={{marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             Company Activity
              </Typography>
             
             
              <Grid container style={{marginTop:"2rem"}} spacing={4} direction="row">
              
              <div style={{padding:"1rem",border:"3px solid lightgray",borderRadius:"0.5rem"}}>
                    <b style={{position:"relative",left:"1rem"}}>Latest Activity on OneRecruiter</b>
                      <table style={{ width: "57rem"}} aria-label="simple table">
                        

                          <tr sx={{backgroundColor:"#FFFFFFF"}}>

                            <TableCell style={{color:"grey",fontSize:"1rem",fontWeight:"800"}} >Source</TableCell>
                            <TableCell style={{color:"grey",fontSize:"1rem",fontWeight:"800"}}  align="center">Stats</TableCell>
                           
                          </tr>
                        
                        <TableBody>
                          {rows.map((row) => (
                            <TableRow
                              key={row.source}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell  component="th" scope="row">
                                {row.source}
                              </TableCell>
                              <TableCell align="center">{row.stats}</TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </table>
                  </div>

              </Grid>


              <Typography variant="h3" gutterBottom style={{marginTop:"5rem",marginBottom:"2rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             Online Reviews
              </Typography>
           
              <Grid container style={{width:"100%"}} spacing={1} direction="row">
                <Grid item xs={2.4}>
                  <u style={{ fontSize: '15px', width: '100%',color:"blue" }}>Glassdoor</u>
                 
                </Grid>

                <Grid item xs={2.4}>
                  <u style={{ fontSize: '15px', width: '100%',color:"blue"  }}>LinkedIn</u>
                  
                </Grid>


                <Grid item xs={2.4}>
                  <u style={{ fontSize: '15px', width: '100%',color:"blue"  }}>Reddit</u>
                 
                </Grid>



                <Grid item xs={2.4}>
                  <u style={{ fontSize: '15px', width: '100%',color:"blue"  }}>CareerBuilder</u>
                  
                </Grid>


                <Grid item xs={2.4}>
                <u style={{ fontSize: '15px', width: '100%',color:"blue"  }}>Indeed</u>
                </Grid>

              </Grid>


              <Typography variant="h3" gutterBottom style={{position:"relative",left:"-2rem",marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
            Upcoming Interviews & Events
              </Typography>



           
           
              <Grid container style={{marginTop:"2rem"}} spacing={4} direction="row">
                 
                  <div style={{padding:"1rem",border:"3px solid lightgray",borderRadius:"0.5rem"}}>
                  
                      <table style={{width:"35rem"}} aria-label="simple table">
                        

                          <tr sx={{backgroundColor:"#FFFFFFF"}}>

                            <TableCell style={{color:"black",fontSize:"1rem",fontWeight:"800"}}>Event</TableCell>
                            <TableCell style={{color:"black",fontSize:"1rem",fontWeight:"800"}} align="center">Date</TableCell>
                           
                          </tr>
                       
                        <TableBody>
                          {rows2.map((row) => (
                            <TableRow
                              key={row.source}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell  component="th" scope="row">
                                {row.event}
                              </TableCell>
                              <TableCell align="center">{row.date}</TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </table>
                 
                 </div>

              </Grid>


           



              



     
      </form>


      

   
     




    </div>
  );
}
