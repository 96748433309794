
import Header from '../componentsWelcome/header/Header'
import Nav from '../componentsWelcome/nav/Nav'

import Experience from '../componentsWelcome/experience/Experience'
import Footer from '../componentsWelcome/footer/Footer'

import About from '../componentsWelcome/about/About'
import About2 from '../componentsWelcome/about2/About2'
import About3 from '../componentsWelcome/about3/About3'
/*import RangeTable from '../componentsWelcome/rangeTable/RangeTable'*/
import Choice from '../componentsWelcome/choice/Choice'
import { Helmet } from 'react-helmet-async'
//import Solutions from '../componentsWelcome/solutions/Solutions'
//import Testimonials from '../componentsWelcome/testimonials/Testimonials'

/*import Features from '../componentsWelcome/features/Features'*/
//import Projects from '../componentsWelcome/projects/Projects'
/*import Faq from '../componentsWelcome/Faq/Faq'*/


const WelcomePage = () => {
 
    return (
      <>
      <Helmet>
      <title> One Recruiter </title>
    </Helmet>


        <div className="welcomePage">
        {/*<Nav/>*/}
        {<Header />}
        <About />
        <About2/>
        <About3/>
        <Choice/>
        {/*<Experience />*/}
        
        {/*<RangeTable/>*/}
        {/*<Features/>*/}
       
        {/*<Solutions/>*/}
        {/*<Testimonials/>*/}
        {/*<Projects/>*/}
        <Footer/>
        </div>

    </>
      )
}

export default WelcomePage