import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Paper, Button, Stack, Skeleton, Divider, TextField, } from '@mui/material';
import { useEffect,useRef } from 'react';
import { findDOMNode } from 'react-dom'
import { fCurrency, fNumber } from '../utils/formatNumber';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EmptyRowCard from 'src/components/home/empty-row-card';
import { fetchGroups, fetchMyGroups } from 'src/redux/actions/group.action';
import MyCoolersRowCard from 'src/components/my-cooler/my-coolers-card';
import PieChartCard from 'src/components/home/pie-chart-card';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WalletBox from 'src/components/home/wallet-box';
import { isItLoading } from 'src/redux/reducers/group.slice';
import { fetchUserDataBusiness } from 'src/redux/actions/auth.action';

import { useMemo, useState} from 'react';

import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from 'src/components/chart2';
import RecentTransaction from 'src/components/home/recent-transaction';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import HomeCoolersCard from 'src/components/home/home-coolers-card';
//placeholder image ofr coolers
import chase from 'src/assets/images/Group_Logo.png'
import ProfileBox from 'src/components/home/profile-box';
import HomeCandidatesCard from 'src/components/home/home-candidates-card';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import Input from '@material-ui/core/Input';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';


const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;



const CHART_DATA = [50,50];

export default function ComingSoonPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { transactions } = useSelector((state) => state.transaction);

  const [searchText, setSearchText] = useState('');


  function handleSearchText(event) {
    setSearchText(event.target.value);
  }



  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  


  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      /*theme.palette.primary.lighter,
      theme.palette.primary.light,*/
      theme.palette.primary.main,
      theme.palette.primary.dark,
    ],
    labels: ["ACCRUED","ACCRUED"],
    interactions: [],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center', show:false },
    tooltip: {
      enabled: false
  },


 
    plotOptions: {
      pie: {
        expandOnClick:false,
        click:false,
        donut: {
          size: '70%',
          labels: {
            // value: {
            //   formatter: (val) => fNumber(0),
            // },
            value: {
              formatter: (val) =>  {if(user &&user.accruedBalance)
                           {return fCurrency(user.accruedBalance)}
                           else {return '$0'}
   
                          }
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                console.log("user?.accruedBalance: ", user?.accruedBalance);
                return user?.accruedBalance === 0 ? "$0" : fCurrency(user?.accruedBalance);
                // return fNumber(0);
              },
            },
            hover: {
              enabled: false,
             
          },
          toggle: {
            enabled: false,
           
        },
          },
        },
      },
    },
   
  });


 



  useEffect(() => {
    if(user?./*u*/id == undefined){
     return navigate("/login");
    }
   }, [])

   const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '40%',
  };

  useEffect(() => {
    dispatch(fetchMyGroups(user?.recruiterJobs));
    //dispatch(fetchMyTransactions(user?./*u*/id));
    console.log("Transac Changed.");
  }, [user])

  useEffect(() => {
   // dispatch(fetchUserDataBusiness(user?./*u*/id));
  }, [])



const myCoolerGroups = myGroups?.length ? (
  myGroups
  .slice(0, 3)
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  .map((group,index) => {
    return (
        <HomeCandidatesCard 
      groupId={group.id}
      name={index === 0 ? 'Franklin Edwards':'Joel Nathaniel'} 
      //fee={fCurrency(group.amount)}
      //count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
      img={chase}
     // members={group.members}
     // isMember={group.members.includes(user?./*u*/id)}
      startDate={group.startDate}
      />
    )
  })
) : 
<>
<EmptyRowCard msg={"Candidates you have added will appear here"}/>
</>


  return (
    <>
      <Helmet>
        <title> One Recruiter | Business </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
         Welcome 🖐🏽
        </Typography> */}
        {/* <PdfViewer /> */}

        <Grid  container direction="row" justifyContent="flex-end" alignItems="center">
      
         
           {/*<Button onClick ={()=>{navigate('/dashboard/add-job')}} variant="contained" style={{backgroundColor: "#7152CA", paddingTop: '10px', paddingBottom: '10px',  paddingRight: '30px', paddingLeft: '30px'}}>
           Add Job
      </Button>*/}

       {/*  <TextField
                      name="search"
                      required
                      type="text"
                      
                      style={inputStyle}
                    /> */}


           <div style={{borderRadius:"0.5rem",border:"1px solid lightgray",width:"35%",marginTop:"4rem"}} className="flex p-4 items-center  px-8 py-4">
                <Icon color="action"><SearchIcon style={{position:"relative",top:"-0.5rem"}}/></Icon>

                <Input
                  placeholder="Search..."
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
              </div>
    </Grid>
    <br/>
    
      <br/>


        <Grid container style={{justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"1.5rem",height:"40vh",position:"relative",top:"8rem"}} spacing={2}>
       
             <div item xs={12} style={{justifyContent:"center",alignItems:"center",fontWeight:"900",fontSize:"36px"}}>
             Coming Soon
             </div>
              
             <div item xs={12} style={{justifyContent:"center",alignItems:"center",fontWeight:"900",fontSize:"36px"}}>
             With
             </div>
            
             <div item xs={12} style={{justifyContent:"center",alignItems:"center",fontWeight:"900",fontSize:"36px"}}>
             Subscription
             </div>



            
           
          </Grid>
          <br/>




          {/*<Grid container style={{justifyContent:"flex-start",alignItems:"flex-start",flexDirection:"column",paddingLeft:"2rem"}} spacing={2}>
       
             



             <div item xs={12} style={{justifyContent:"flex-start",alignItems:"center",fontWeight:"900",fontSize:"26px",paddingBottom:"0rem", borderBottom:"2px solid lightgray",width:"37rem",marginBottom:"1rem",color:"#40255F"}}>
              Best Practice Resources
             </div>


             <i item xs={12} style={{justifyContent:"flex-start",alignItems:"center",fontWeight:"900",fontSize:"16px",paddingBottom:"1rem", }}>
             <a style={{color:"blue",textDecoration:"underline"}} href='#'>Interviewology: The New Science of Interviewing</a> &nbsp; <span>Written By: Anna Papalia</span>
             </i>
           
           
                </Grid>*/}
          
          
       
         
      </Container>
    </>
  );
}
