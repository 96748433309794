import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));

// ----------------------------------------------------------------------

export default function DraftedJobsPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const [companyName, setCompanyName] = useState('');

  const rows= [
    {jobId:"Chief Technology Officer",name:"3/4/2024", title:"30 Days"  },
    {jobId:"Data Architect",name:"2/15/2024", title:"19 Days"  },
    {jobId:"Outside Sales Representative",name:"4/2/2024", title:"2 Days"},
   
  ];

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Drafted Jobs | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem",/*color:"#40255F",*/ display:"flex",justifyContent:"center",marginBottom:"3rem"}}>
            Drafted Jobs
            </Typography>
   
          {/*<Divider style={{width:"100%",marginBottom:"3rem"}}/>*/}

          <Typography variant="body2" sx={{ mt: 1,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",textAlign:"center" }}>
          The postings below are incomplete and are missing information.
           Each drafted job posting will expire 30 days from the date you 
           started filling out the job posting. If the job posting expires, 
           the system will delete your progress without saving it. A a result, 
           you will have to start from the beginning. There's a threshold of
            three drafted job postings per account.
              
            </Typography>



        
           <div style={{padding:"1rem",borderRadius:"0.5rem",border:"2px solid lightgray"}}>
            <TableContainer sx={{py:"2rem", width: "44rem",}} component={Paper}>
                    
                    <table style={{width: "100%"}} aria-label="simple table">
                      <tr>

                       
                        <TableCell sx={{color:"black",fontSize:"1.1rem",fontWeight:"800",textAlign:"left"}}  align="left">Job Title</TableCell>
                          <TableCell sx={{color:"black",fontSize:"1.1rem",fontWeight:"800",textAlign:"left"}}  align="left">Date Created</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.1rem",fontWeight:"800",textAlign:"left"}} align="left">Time Remaining</TableCell>
                        
                         
                       
                      </tr>
                      <TableBody>
                        {rows.map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{my:2, '&:last-child td, &:last-child th': { border: 0},backgroundColor:"white",padding:"1rem"}}
                             
                          >

                              <TableCell  sx={{ backgroundColor:"transparent"}}  scope="row">  
                            
                                 {row.jobId}
                             
                        
                              </TableCell>
  



                            <TableCell sx={{ backgroundColor:"transparent"}}  scope="row">  
                            
                          {row.name}
                           
                      
                            </TableCell>


                            <TableCell align="left"  sx={{my:2, backgroundColor:"transparent"}}>

                            {row.title}

                            </TableCell>


                        

                       

                          </TableRow>
                        ))}
                      </TableBody>
                    </table>
                </TableContainer>
            </div>






         



       

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
