import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid,Box } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaBell, FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatesPendingInterview } from 'src/redux/actions/group.action';
import { saveCandidateInFocus } from 'src/redux/reducers/group.slice';

import Modal from '@mui/material/Modal';
import sampleVideo    from 'src/assets/videos/sample-job-interview.mp4'




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
   // fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


// ----------------------------------------------------------------------

export default function CandidatesPendingSelectionPageCandidates() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const [companyName, setCompanyName] = useState('');


  const { user } = useSelector((state) => state.auth);
  const { candidatesPendingInterview, isLoading } = useSelector((state) => state.group);

  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])

  useEffect(()=>{

    console.log("current Job Id",location.state.currentJob.id)

    if(!location.state.currentJob.id){
      navigate('/dashboard/candidates-pending-selection')
    }
  
    //the line below isn't really compulsory, but we just keep it, for future
    dispatch(fetchCandidatesPendingInterview());
    
   
  
  },[])
  

  


  const rows= [
    {interviewRound:"Helena H.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Oscar D.", status:"In progress", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"Carlo E.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},


    {interviewRound:"Daniel P.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/7"},
    {interviewRound:"Mark R.", status:"In progress", position:"Lead Application Developer", typeOfInterview:"No",feedback:"Pending", duration:"0/8"},
    {interviewRound:"George L.", status:"In progress", position:"Sr Full Stack Developer", typeOfInterview:"No",feedback:"Pending", duration:"3/7"},
    
  ];

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Candidates Pending Selection list | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"2rem",width:"100%",position:"relative"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         
  <p onClick={()=>{navigate('/dashboard/action-items')}}
   style={{position:"absolute",left:"-3%",top:"-3%",display:"flex",alignItems:"center",gap:"0.5rem"}}> 
  <FaRegArrowAltCircleLeft/> 
  Back to Folder List
  </p>


      
       {/*THIRD SECTION  */}
          

            <div style={{width:"100%",display:"flex",justifyContent:"space-between",alignItems:"center", }}>

           <div style={{display:"flex",flexDirection:"column",alignItems:"flex-start"}}>
           <h1 style={{color:"black",fontSize:"30px",fontWeight:"700"}}>{location.state.currentJob.jobRole}</h1>
           <i>Posted: {location.state.currentJob.jobCreatedDate}</i>
           <i>Status:Needs Attention</i>
           <i>Talent Expert: <span style={{color:"blue",textDecoration:"underline"}}>Jessica Campbell</span></i>
           </div>


           <div>
           <p  style={{fontSize:"22px",fontWeight:"900"}}>Job Posting ID: IT-543</p> 
            <p style={{border:"1px solid black",display:"flex",alignItems:"center",gap:"1rem",padding:"0.6rem"}}>
              Contact Talent Expert
              <FaBell/>
            </p>
            
            </div>

            </div>
   
          <Divider style={{width:"90%",marginTop:"2rem",marginBottom:"3rem",border:"2px solid lightgrey"}}/>

        


          
            
          
            <TableContainer sx={{py:"2rem", width: "95%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black"}}>

                          <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Candidates</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Interview Status</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Reviewed</TableCell>
                          <TableCell style={{color:"#40255F",fontSize:"24px",fontWeight:"bold",fontFamily:"Public Sans, sans-serif",}} align="center">Decision</TableCell>
                          {/*<TableCell align="center">Candidate Reviewed</TableCell>*/}
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {candidatesPendingInterview && candidatesPendingInterview.length ? candidatesPendingInterview.filter((item)=>(item.jobId === location.state.currentJob.id)).map((row,index) => (
                          <TableRow onClick={()=>{ dispatch(saveCandidateInFocus(row));navigate('/dashboard/candidates-pending-selection-candidate-expanded',{state:{candidateObj:row}})}}
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } 
                             ,backgroundColor:"white",padding:"1rem",borderBottom:"none",
                            
                             [`& .${tableCellClasses.root}`]: {
                              borderBottom: "none"
                            },
                            
                            }}
                          >
                            <TableCell align="center" sx={{my:3, backgroundColor:"transparent",fontFamily:"Public Sans, sans-serif",}}  >  
                            
                          
                            <p style={{color:"#40255F",fontSize:"22px",fontWeight:"600"}}>{(row.firstName && row.firstName) + " " + (row.lastName && row.lastName).substring(0,1)+"."}</p>
                           
                           <p style={{color:"gray",fontSize:"14px"}}>{row.jobRole}</p>
                      
                            </TableCell>



                           
                            <TableCell align="center"  sx={{my:2, backgroundColor:"transparent",color:"gray",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {row.selectionReviewed ?"Completed":"In Progress"  }
                            

                            </TableCell>





                            <TableCell align="center"  sx={{my:2, backgroundColor:"transparent",color:"gray",fontFamily:"Public Sans, sans-serif",}}>

                            
                            {row.selectionReviewed ?"Yes":"No" }
                            

                            </TableCell>


                          {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="duration"
                           
                           placeholder='60 mins'
                           value={row.duration}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"1rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"transparent",color:"gray",fontFamily:"Public Sans, sans-serif",}}>

                          
                          {rows[0].feedback}
                          
                          
                          </TableCell>

                          </TableRow>
                        )):
                        
                        
                        <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
                        <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"0.5rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> Candidates for interview will be displayed here.. </center></TableCell>
                        </TableRow>}
                      </TableBody>
                    </Table>
                </TableContainer>
             
   
                <Divider style={{marginTop:"4rem",width:"90%",marginBottom:"3rem",border:"2px solid lightgrey"}}/>
           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
