import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle, FaHome } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationFormRecruiter from 'src/components/login/applicationFormRecruiter';
import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function ApplicationPageRecruiter() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()


  const { user } = useSelector((state) => state.auth);
  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  

  return (
    <>
      <Helmet>
        <title> Application | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       <div  style={{zIndex:"1000",position:"fixed",top:"0",backgroundColor:"#40255F",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",paddingTop:"1rem",paddingBottom:"1rem"}}>
       <FaHome onClick={()=>{navigate('/home')}}  style={{color:"white",fontSize:"2.5rem",position:"absolute",right:"1rem",cursor:"pointer"}}/>
       
        <img style={{height:"100px"}} src={icon} alt={"one recruiter logo"}/>
        </div>

        <Container style={{marginTop:"6rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>
            <Typography variant="h2" gutterBottom style={{width:"44rem",color:"#40255F",display:"flex",justifyContent:"center",fontFamily:"Libre Baskerville,sans-serif"}}>
              Talent Expert Application
            </Typography>
   
          
          <Divider style={{width:"55%",border:"0.5px solid #40255F",marginTop:"1rem"}}/>

            <Typography variant="body2" sx={{ mt: 6, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem" ,fontFamily:"Libre Baskerville,sans-serif"}}>
            OneRecruiter is creating a new and exciting ecosystem for Independent Recruiters (IRs) that has never been seen before.
             Here, recruiters will have equal opportunities and minimal competition to maintain a balanced workload.
              This will provide a work-life balance that will enable financial rewards with less time spent on business development,
               marketing, negotiating contracts, drafting contracts, and facilitating transactions. 
               
              
            </Typography>



            
              <Typography variant="body2"  sx={{ mt: 5,mb: 12, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",fontFamily:"Libre Baskerville,sans-serif" }}>
                 OneRecruiter aims to revolutionize the entire staffing algorithm, challenging the traditional ways of recruitment.
                  Join our Fleet and be part of the change in how we hire talent!
              </Typography>
            

            <ApplicationFormRecruiter />
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
