//import FuseScrollbars from 'src/@fuse/core/FuseScrollbars';
//import FuseUtils from 'src/@fuse/utils';
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

import SearchIcon from '@mui/icons-material/Search';

import Input from '@material-ui/core/Input';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { motion } from 'framer-motion';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChats,fetchDummyChats } from 'src/redux/actions/chat.action';
//import { fetchConnectedUsers, fetchConnectedUsers2, fetchRealTimeUsers } from 'redux/actions/user.action';
import ContactListItem from './ContactListItem';
//import ContactListItem2 from './ContactListItem2';
import StatusIcon from './StatusIcon';
import { Stack } from '@mui/material';
//import { getChat } from './store/chatSlice';
//import { selectContacts } from './store/contactsSlice';
//import { closeMobileChatsSidebar, openUserSidebar } from './store/sidebarsSlice';
//import { updateUserData } from './store/userSlice';

const statusArr = [
  {
    title: 'Online',
    value: 'online',
  },
  {
    title: 'Away',
    value: 'away',
  },
  {
    title: 'Do not disturb',
    value: 'do-not-disturb',
  },
  {
    title: 'Offline',
    value: 'offline',
  },
];

function InboxSidebar(props) {
  const dispatch = useDispatch();
  const contacts =[] /*useSelector(selectContacts)*/;
  // const user = useSelector(({ chatApp }) => chatApp.user);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [searchText, setSearchText] = useState('');

  const [jobPostings, setJobPostings] = useState(true);


  const [statusMenuEl, setStatusMenuEl] = useState(null);
  const [moreMenuEl, setMoreMenuEl] = useState(null);

  //New Hooks
  const [chatStarted, setChatStarted] = useState(false);
  const [chatUser, setChatUser] = useState('');
  const [message, setMessage] = useState('');
  const [userUid, setUserUid] = useState(null);
  const { user } = {}/*useSelector((state) => state.login);*/
  const { allUsers, connectedUsers, connects, connects2, isLoading } = useSelector((state) => state.user);
  let unsubscribe;
  
  useEffect(() => {
    console.log('All Users', allUsers);
  }, [])
 
  useEffect(() => {

 //   unsubscribe = dispatch(fetchConnectedUsers(user.uid))
 //   unsubscribe = dispatch(fetchConnectedUsers2(user.uid))
 //   .then(unsubscribe => {
 //     return unsubscribe;
 //   })
 //   .catch(error => {
 //     console.log(error);
 //   })
  }, []);
  
    //componentWillUnmount

    
   // useEffect(() => {
   //   return () => {
   //     //cleanup
   //     unsubscribe.then(f => f()).catch(error => console.log(error));
   //   }
   // }, []);
  


  function handleMoreMenuClick(event) {
    setMoreMenuEl(event.currentTarget);
  }

  function handleMoreMenuClose(event) {
    setMoreMenuEl(null);
  }

  function handleStatusMenuClick(event) {
    event.preventDefault();
    event.stopPropagation();
    setStatusMenuEl(event.currentTarget);
  }

  function handleStatusSelect(event, status) {
    event.preventDefault();
    event.stopPropagation();
  //  dispatch(
  //    updateUserData({
  //      ...user,
  //      status,
  //    })
  //  );
    setStatusMenuEl(null);
  }

  function handleStatusClose(event) {
    event.preventDefault();
    event.stopPropagation();
    setStatusMenuEl(null);
  }

  function handleSearchText(event) {
    setSearchText(event.target.value);
  }


  const initChat = (user2, isMobile) => {
   const user1 = user && user.uid;
    setChatStarted(true)
    setChatUser(user2.name)
    setUserUid(user2.uid);

     
     dispatch(fetchDummyChats(user1, user2))
     console.log('IsMobile: ', isMobile);
     if (isMobile) {
        //dispatch(closeMobileChatsSidebar());
      }
  }

  const testConnections = () => {
    
  const connectsById = Object.fromEntries(
    connects2.map(({ user1, type, status, invited_amt, skipped_amt }) => [user1, { type, status, invited_amt, skipped_amt }])
      );
      
    const connectedUsersOutput = connectedUsers.map(({ uid, name, email, city, intro, skillset, skills_needed, 
      lookingFor, lastActive, isTechnical, photoUrl, password}) => ({
        uid, name, email, city, intro, skillset, skills_needed, 
        lookingFor, lastActive, isTechnical, photoUrl, password,
      ...(connectsById[uid] || { type: '', status: '', invited_amt: '', skipped_amt: ''})
    }));

    console.log('Connected Users Mapped: ', connectedUsersOutput);
  }





  const connectsById = Object.fromEntries(
    connects2.map(({ user1, type, status, invited_amt, skipped_amt }) => [user1, { type, status, invited_amt, skipped_amt }])
      );
      
    const connectedUsersOutput = connectedUsers.map(({ uid, name, email, city, intro, skillset, skills_needed, 
      lookingFor, lastActive, isTechnical, photoUrl, password}) => ({
        uid, name, email, city, intro, skillset, skills_needed, 
        lookingFor, lastActive, isTechnical, photoUrl, password,
      ...(connectsById[uid] || { type: '', status: '', invited_amt: '', skipped_amt: ''})
    }));


  return (
    <div className="flex flex-col flex-auto h-full">
      <AppBar position="static" color="default" elevation={0} style={{backgroundColor:"white",marginTop:"0.5rem"}}>
        {/*START OF STACK */}

        {<Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{backgroundColor:'#F2F2F2',padding:"0.2rem",borderRadius:"0.5rem",width:"90%",margin:"0 auto",marginBottom:"1rem"}}
          spacing={{
            xs: 1,
            sm: 2,
          }}
        >
         
          <div onClick={()=>{setJobPostings(true)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:jobPostings?"#FFFFFF":"transparent",padding:"0.5rem",width:"45%",textAlign:"center"}}>Job Postings</div>
          <div onClick={()=>{setJobPostings(false)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:!jobPostings?"#FFFFFF":"transparent",padding:"0.5rem",width:"45%",textAlign:"center"}}>Candidates</div>
         
        
        
        
        </Stack>}

    
         {/*END OF STACK */}
      </AppBar>

      {/* Chats List */}
      {/*<FuseScrollbars className="overflow-y-auto flex-1">*/}
        <List className="w-full">
          {useMemo(() => {
            function getFilteredArray(arr, _searchText) {
              if (_searchText.length === 0) {
                return arr;
              }
              //return FuseUtils.filterArrayByString(arr, _searchText);
              return []
            }

            const chatListContacts =
              contacts.length > 0 && user && user.chatList
                ? user.chatList.map((_chat) => ({
                    ..._chat,
                    ...contacts.find((_contact) => _contact.id === _chat.contactId),
                  }))
                : [];
            const filteredContacts = getFilteredArray([...contacts], searchText);
            const filteredChatList = getFilteredArray([...chatListContacts], searchText);

            const container = {
              show: {
                transition: {
                  staggerChildren: 0.1,
                },
              },
            };

            const item = {
              hidden: { opacity: 0, y: 20 },
              show: { opacity: 1, y: 0 },
            };

            return (
              <motion.div
                className="flex flex-col flex-shrink-0"
                variants={container}
                initial="hidden"
                animate="show"
              >
             {/* <button onClick={() => testConnections()}>Please Click Me😌</button> */}


          


         <Toolbar className="px-16" style={{position:"relative",top:"-1rem"}}>
              <div style={{borderRadius:"0.5rem",border:"1px solid lightgray"}} className="flex p-4 items-center w-full px-8 py-4">
                <Icon color="action"><SearchIcon style={{position:"relative",top:"-0.5rem"}}/></Icon>

                <Input
                  placeholder="Search Chats"
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
              </div>
            </Toolbar>




             {/*connectedUsersOutput.length > 0 && (
                  <motion.div variants={item}>
                    <div style={{backgroundColor:"pink"}} className="font-medium text-20 px-16 py-4" color="secondary">
                      Chats
                    </div>
                  </motion.div>
                )*/}

                

              
                {
                  connectedUsersOutput.length ? (
                    connectedUsersOutput.map((user) => {
                      return(
                        <motion.div variants={item} key={user.uid}>
                        <ContactListItem
                          user={user}
                        //   onContactClick={(contactId) => dispatch(getChat({ contactId, isMobile }))}
                      onContactClick={() => {initChat(user, isMobile)}}   /*{/*DAGOGO YOU STOPPED HERE --> YOU ARE TRYING TO MAKE CHATS VISIBLE! }*/
                        />
                      </motion.div>
                      );
                      })
                    ) : (
                      <div className="container">
                          <center><p className="center">No available chat yet</p></center>
                      </div>
                    )
                }
                {/* {filteredContacts.length > 0 && (
                  <motion.div variants={item}>
                    <Typography className="font-medium text-20 px-16 py-24" color="secondary">
                      Pending Invites
                    </Typography>
                  </motion.div>
                )}

                {filteredContacts.map((contact) => (
                  <motion.div variants={item} key={contact.id}>
                    <ContactListItem2
                      contact={contact}
                      onContactClick={(contactId) => dispatch(getChat({ contactId, isMobile }))}
                    />
                  </motion.div>
                ))} */}
              </motion.div>
            );
          }, [contacts, user, searchText, dispatch, isMobile])}
        </List>
      {/*</FuseScrollbars>*/}
    </div>
  );
}

export default InboxSidebar;
