import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Paper, Button, Stack, Skeleton, Divider } from '@mui/material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import HomeBox from '../components/home/home-box';
import PublicCoolerRowCard from 'src/components/public-cooler/public-cooler-card';
import { fetchGroups, fetchMyGroups,fetchJobCandidates, fetchPublicGroup } from 'src/redux/actions/group.action';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fCurrency } from '../utils/formatNumber';
import EmptyRowCard from 'src/components/home/empty-row-card';
import { fetchUserData } from 'src/redux/actions/auth.action';
import MyCoolersRowCard from 'src/components/my-cooler/my-coolers-card';
// import AllCoolerRowCard from 'src/components/my-cooler/all-cooler-card';

//placeholder image ofr coolers
import chase from 'src/assets/images/Group_Logo.png'
import microsoft from 'src/assets/images/microsoft.jpeg'
import JobIntroCard from 'src/components/my-cooler/job-intro-card';
import CandidateCard from 'src/components/my-cooler/candidate-card';

import { useLocation, useNavigate } from 'react-router-dom';
import HomeCandidatesCard from 'src/components/home/home-candidates-card';


export default function ViewCandidatesPage() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()
  console.log("LOCATION STATE: ", location.state);

  const { user } = useSelector((state) => state.auth);
  const { jobCandidates,myGroups, isLoading } = useSelector((state) => state.group);

  useEffect(() => {
    console.log("location state is-->",location.state)
     //dispatch(fetchMyGroups(user.coolers));
      dispatch(fetchJobCandidates(location.state && location.state.groupData.id));
  }, [user])

  
/*  useEffect(() => {
    dispatch(fetchUserData(user.id));
  }, [])*/

  console.log("MY GROUPS-->: ", jobCandidates);
  const myCoolerGroups = jobCandidates?.length ? (
    jobCandidates.map((group,index) => {
      return (
       
        <HomeCandidatesCard 
        groupId={group.id}
      name={group.firstName + " " + group.lastName} 
      //fee={fCurrency(group.amount)}
      //count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
      //img={chase}
      //members={group.members}
      //isMember={group.members.includes(user?.id)}
      //startDate={group.startDate}
        />
      )
    })
  ) : 
  <>
  <EmptyRowCard msg={"Candidates who applied will appear here."}/>
  </>


  return (
    <>
      <Helmet>
        <title> One Recruiter | View Candidates </title>
      </Helmet>
      <Container maxWidth="xl" style={{backgroundColor: '#609AF00F'}}>
      {/* <SearchBox style={{ width: '100%' }} /> */}
      <br/>
      <Grid  container direction="row" justifyContent="space-between" alignItems="center">
      <h2 style={{color:"#130C66"}}>Front End Developer</h2>
      
     {/* <Button onClick={()=>{}} variant="contained" style={{backgroundColor: "#7152CA", paddingTop: '10px', paddingBottom: '10px',  paddingRight: '30px', paddingLeft: '30px'}}>
      Filter
  </Button>*/}
    </Grid>
    <Divider/>
      <br/>
      <br/>
      <br/>
      {
        isLoading ?
        <Stack>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
        </Stack>
        :
         <>
        <JobIntroCard 
        groupId={1}
        name={location.state.groupData? location.state.groupData.jobRole:" "} 
        fee={location.state.groupData? location.state.groupData.salary:" "}
        feeInNum={250}
        count={`${1} OF ${3} SAVERS`}
        img={microsoft}
        members={25}
        isMember={true}
        startDate={location.state.groupData ? location.state.groupData.startDate:"11/04/2024"}
        />

        {myCoolerGroups}
        </>

      }
  </Container>
      
     
    </>
  );
}