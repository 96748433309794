import React from 'react'
import './about.css'
import mobileAndWeb from 'src/assets/images/mobile&web.png'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const About = () => {


const navigate= useNavigate()


  return (

    <>


    <section id='about'>

    <p style={{fontWeight:"700",fontSize:"3.5rem",color:"#40255F",paddingBottom:"1rem",paddingLeft:"0.8rem",paddingRight:"0.8rem",borderBottom:"1px solid #40255F",width:"max-content",margin:"0 auto",marginBottom:"2rem",marginTop:"3rem"}}> The Solution{/*<span className="text-accent">  get the Job! </span>*/}</p>
    <div className="container-hp about__container">
       


    {<div className='aboutCTA1'>
    
 
    <img  className='CTAboximg1' src={mobileAndWeb} alt="search image"/>
    
       
        
        </div>}
       

      <div className="aboutExplainer">
      <h1 style={{fontWeight:"700",color:"#40255F",fontSize:"2.3rem"}}> Speed, Quality, Savings & Integrity{/*<span className="text-accent">  get the Job! </span>*/}</h1>

         <p>
         The business model around internal Talent Acquisition teams, organizations,
          and agencies continues to evolve to meet business needs. In-house talent 
          acquisition teams, while effective, require a full-time staff, resulting 
          in overhead costs, and pressure to maintain talent through periods of low demand. Recruitment
           agencies and contract recruiting (based in the USA) are attractive to
            companies for this reason. However, the cost of these services tends to be greater. 
            This has caused a rise in offshore recruitment agencies offering more competitive 
            rates due to lower employee wages and overhead costs. Results from these agencies 
            can be a mixed bag due to cultural differences, misaligned expectations, time zones, and language challenges. Ultimately, one solution for Talent Acquisition is not better than another, until now. OneRecruiter bridges the gap between, high-quality, cost efficiency, and speed. Revolutionizing the staffing industry.
        </p>

       {/*<div className = "about-btn-placement" >
        <button   onClick={()=>{navigate('/login')}} className="btn ">
           Get Started 
         </button>

         <p  onClick={()=>{navigate('/dashboard/home')}} className="btn-invisible" >See How It Works</p>
     </div>*/}


      </div>
     
     {/*<div className='aboutCTA'>
    
       <img  className='CTAbox' src={countPennies} alt="counting coins"/>
     

     <div className='clip-path-bottom-right'></div>
     <div className='clip-path-bottom-left'></div>
     <div className='clip-path-top-left'></div>
</div>*/}  



      

   
   
</div>

    </section>


</>
  )
}

export default About