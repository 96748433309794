import { Helmet } from 'react-helmet-async';
import { Grid, Container, Typography, Paper, Button, Stack, Skeleton, Divider, TextField } from '@mui/material';
import { useState,useEffect,useRef } from 'react';
import { findDOMNode } from 'react-dom'
import { fCurrency, fNumber } from '../utils/formatNumber';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import EmptyRowCard from 'src/components/home/empty-row-card';
import { fetchGroups, fetchMyGroups } from 'src/redux/actions/group.action';
import MyCoolersRowCard from 'src/components/my-cooler/my-coolers-card';
import PieChartCard from 'src/components/home/pie-chart-card';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WalletBox from 'src/components/home/wallet-box';
import StatsBox from 'src/components/home/stats-box';
import { isItLoading } from 'src/redux/reducers/group.slice';
import { fetchUserDataBusiness } from 'src/redux/actions/auth.action';

import merge from 'lodash/merge';
import ReactApexChart from 'react-apexcharts';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { BaseOptionChart } from 'src/components/chart2';
import RecentTransaction from 'src/components/home/recent-transaction';
import { fetchMyTransactions } from 'src/redux/actions/transaction.action';
import HomeCoolersCard from 'src/components/home/home-coolers-card';
//placeholder image ofr coolers
import chase from 'src/assets/images/Group_Logo.png'
import ProfileBox from 'src/components/home/profile-box';
import HomeCandidatesCard from 'src/components/home/home-candidates-card';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import SearchIcon from '@mui/icons-material/Search';

import Input from '@material-ui/core/Input';

import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';



const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;



const CHART_DATA = [50,50];

export default function ExpensesPage() {
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { myGroups, isLoading } = useSelector((state) => state.group);
  const { transactions } = useSelector((state) => state.transaction);

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
   width: '40%',
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
    gap:"1rem"
  };



  const chartOptions = merge(BaseOptionChart(), {
    colors: [
      /*theme.palette.primary.lighter,
      theme.palette.primary.light,*/
      theme.palette.primary.main,
      theme.palette.primary.dark,
    ],
    labels: ["ACCRUED","ACCRUED"],
    interactions: [],
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center', show:false },
    tooltip: {
      enabled: false
  },


 
    plotOptions: {
      pie: {
        expandOnClick:false,
        click:false,
        donut: {
          size: '70%',
          labels: {
            // value: {
            //   formatter: (val) => fNumber(0),
            // },
            value: {
              formatter: (val) =>  {if(user &&user.accruedBalance)
                           {return fCurrency(user && user.accruedBalance)}
                           else {return '$0'}
   
                          }
            },
            total: {
              formatter: (w) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                console.log("user?.accruedBalance: ", user?.accruedBalance);
                return user?.accruedBalance === 0 ? "$0" : fCurrency(user?.accruedBalance);
                // return fNumber(0);
              },
            },
            hover: {
              enabled: false,
             
          },
          toggle: {
            enabled: false,
           
        },
          },
        },
      },
    },
   
  });

  const [searchText, setSearchText] = useState('');


  function handleSearchText(event) {
    setSearchText(event.target.value);
  }

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [bio, setBio] = useState('');

  const [acctHolderName, setAcctHolderName] = useState('');
  const [bestContactNo, setBestContactNo] = useState('');

  const [positionTitle, setPositionTitle] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [ceoName, setCeoName] = useState('');
  const [employeeNum, setEmployeeNum] = useState('');
  const [yearFounded, setYearFounded] = useState('');
  const [companySocMedia, setCompanySocMedia] = useState('');

  const [companyBio, setCompanyBio] = useState('');

  const [additionalBenefits, setAdditionalBenefits] = useState('');

  const [companyCulture, setCompanyCulture] = useState('');
  const [companyOnboarding, setCompanyOnboarding] = useState('');

  const [howDidYouHear, setHowDidYouHear] = useState('');


  const rows2 = [
    {event:"Entry-Level",date:<strike>10%</strike> , pilot: "(Pilot Price: 7.5%)" },
    {event:"Mid-level",date:<strike>15%</strike>,pilot: "(Pilot Price: 11.25%)" },
    {event:"Senior-level",date:<strike>18%</strike>, pilot: "(Pilot Price: 13.5%)" },
    {event:"Lead, manager, SME",date:<strike>20%</strike>,pilot: "(Pilot Price: 15%)" },
    {event:"C-Suite",date:<strike>22%</strike>,pilot: "(Pilot Price: 16.5%)" },
    
  ];

 

 

  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])
  


  useEffect(() => {
    if(user?./*u*/id == undefined){
     return navigate("/login");
    }
   }, [])

  useEffect(() => {
    dispatch(fetchMyGroups(user?.recruiterJobs));
    //dispatch(fetchMyTransactions(user?./*u*/id));
    console.log("Transac Changed.");
  }, [user])

  useEffect(() => {
    //dispatch(fetchUserDataBusiness(user?./*u*/id));
  }, [])



const myCoolerGroups = myGroups?.length ? (
  myGroups
  .slice(0, 3)
  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
  .map((group,index) => {
    return (
        <HomeCandidatesCard 
      groupId={group.id}
      name={index === 0 ? 'Franklin Edwards':'Joel Nathaniel'} 
      //fee={fCurrency(group.amount)}
      //count={`${group.members.length} OF ${group.noOfSavers} SAVERS`}
      img={chase}
     // members={group.members}
     // isMember={group.members.includes(user?./*u*/id)}
      startDate={group.startDate}
      />
    )
  })
) : 
<>
<EmptyRowCard msg={"Candidates you have added will appear here"}/>
</>


  return (
    <>
      <Helmet>
        <title> One Recruiter | Business </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <Typography variant="h4" sx={{ mb: 5 }}>
         Welcome 🖐🏽
        </Typography> */}
        {/* <PdfViewer /> */}

        <Grid  container direction="row" justifyContent="flex-end" alignItems="center">
      
         
     {/* <Button onClick ={()=>{navigate('/dashboard/add-job')}} variant="contained" style={{backgroundColor: "#7152CA", paddingTop: '10px', paddingBottom: '10px',  paddingRight: '30px', paddingLeft: '30px'}}>
      Add Job
      </Button>*/}

<div style={{borderRadius:"0.5rem",border:"1px solid lightgray",width:"35%"}} className="flex p-4 items-center  px-8 py-4">
                <Icon color="action"><SearchIcon style={{position:"relative",top:"-0.5rem"}}/></Icon>

                <Input
                  placeholder="Search..."
                  className="flex flex-1 px-8"
                  disableUnderline
                  fullWidth
                  value={searchText}
                  inputProps={{
                    'aria-label': 'Search',
                  }}
                  onChange={handleSearchText}
                />
              </div>
    
    </Grid>
    <br/>
    
      <br/>


        <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 170,
                  // border: '1px solid black',
                  backgroundColor: /*'#6077F00F'*/'white',
                }}
              >
                <StatsBox firstRow={'Total Spent to Date'} secondRow={'$45,678.90'} thirdRow={'Last Updated 5 minutes ago'}/>
              </Paper>
            </Grid>

             <Grid item xs={12} md={12} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 170,
                  // border: '1px solid black',
                  backgroundColor: /*'#6077F00F'*/ 'white',
                }}
              >
                <StatsBox firstRow={'Remaining Staffing Budget'} secondRow={'$23,500'} thirdRow={'2024 Total Budget:$69,178.90'}/>
              </Paper>
            </Grid>


            <Grid item xs={12} md={12} lg={4}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 170,
                  // border: '1px solid black',
                  backgroundColor: /*'#6077F00F'*/ 'white',
                }}
              >
                <StatsBox firstRow={'Total Hires to Date'} secondRow={'4'} thirdRow={'Last Updated 5 minutes ago'}/>
              </Paper>
            </Grid>
          </Grid>
          <br/>
          {/* <SearchBox style={{ width: '100%' }} /> */}
          
          <Typography variant="h3" gutterBottom style={{position:"relative",left:"0rem",marginTop:"8rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
            Commission Structure
              </Typography>



           
           
              <Grid container style={{marginTop:"2rem",position:"relative",left:"2rem"}} spacing={4} direction="row">
                 
                  <div style={{padding:"1rem",border:"3px solid lightgray",borderRadius:"0.5rem"}}>
                  
                      <table style={{width:"50rem"}} aria-label="simple table">
                        

                          <tr sx={{backgroundColor:"#FFFFFFF"}}>

                            <TableCell style={{color:"black",fontSize:"1rem",fontWeight:"800"}}>Job Posting Skill Level</TableCell>
                            <TableCell style={{color:"black",fontSize:"1rem",fontWeight:"800"}} align="center">Minimum Commission Fee</TableCell>
                           
                          </tr>
                       
                        <TableBody>
                          {rows2.map((row) => (
                            <TableRow
                              key={row.source}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell  component="th" scope="row">
                                <p style={{fontWeigtht:"800"}} >{row.event}</p>
                              </TableCell>
                              <TableCell style={{fontWeigtht:"800"}} align="center">{row.date}{' '}{' '} &nbsp;{row.pilot}</TableCell>
                              
                            </TableRow>
                          ))}
                        </TableBody>
                      </table>
                 
                 </div>

              </Grid>




         <Typography variant="h3" gutterBottom style={{position:"relative",left:"0rem",marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
            Banking Information
              </Typography>  

         <Grid  container xs={12}  direction="row">
            <Grid item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>Full Name on Bank Account*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='first and last name'
                      value={acctHolderName}
                      onChange={(e) => setAcctHolderName(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },

                    }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative", top:"0.8rem"}}>Financial Institution or Bank Name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Chase Bank'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },
                    }}
                    />
                  </div>
                </Grid>
              </Grid>


         

              



     
      
              <Grid  item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>Bank Account Number on File*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='############1234'
                      value={bestContactNo}
                      onChange={(e) => setBestContactNo(e.target.value)}
                      style={inputStyle}

                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },
                    }}
                      
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>Bank Routing Number</b>
                  <div style={inputContainer}>
                    <TextField
                      name="############1234"
                      required
                      placeholder=''
                      value={positionTitle}
                      onChange={(e) => setPositionTitle(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },
                    }}
                    />
                  </div>
                </Grid>
              </Grid>

              <Grid  item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>EIN*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='***_**-1234'
                      value={employeeNum}
                      onChange={(e) => setEmployeeNum(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },
                    }}
                    />
                  </div>
                </Grid>

                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}> 
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>Email Address*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='email@gmail.com'
                      value={yearFounded}
                      onChange={(e) => setYearFounded(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          cursor:"pointer",
                          paddingLeft:'0.3rem',
                        },
                    }}
                    />
                  </div>
                </Grid>
              </Grid>


              <Grid  item container xs={12}  direction="row">
                <Grid item xs={6} style={{display:"flex",flexDirection:"column",gap:"1.5rem",}}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative", top:"0.8rem" }}>Contact Number*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='(123) 456-7891'
                      value={companyBio}
                      onChange={(e) => setCompanyBio(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          height:'2rem',
                          paddingLeft:'0.3rem',
                          cursor:"pointer",
                        },
                    }}
                    />
                  </div>
                </Grid>

              
              </Grid>




            
         </Grid>  


        
             <center style={{position:"relative",left:"-15%",marginTop:"6rem"}}>
             <Button component="label"  sx={{bgcolor:"#40255F"}}  size="large" type="button" variant="contained" >
               
               <input
             type="file"
             style={{ display: 'none' }}
           
              />
              { "Update Banking Info"}
             </Button>
             </center>
            
        
        
         

      </Container>
    </>
  );
}
