import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SavingsIcon from '@mui/icons-material/Savings';
import MessageIcon from '@mui/icons-material/Message';
import SettingsIcon from '@mui/icons-material/Settings';
import { PiSpeakerHigh } from "react-icons/pi";
import { BsPeople } from "react-icons/bs";
// ----------------------------------------------------------------------
import { FaHome,FaSearch,FaList,FaBell } from "react-icons/fa";
import { FaRegStar } from "react-icons/fa";
import DoneIcon from '@mui/icons-material/Done';
import drafted  from  'src/assets/images/drafted.png';
import home from 'src/assets/images/home.png';
import postajob from 'src/assets/images/postajob.png';
import notifications from  'src/assets/images/notifications.png';
import actionitems from'src/assets/images/actionitems.png';
import toptalentexperts from'src/assets/images/toptalentexperts.png';
import activejobpostings from'src/assets/images/activejobpostings.png';
import projects from'src/assets/images/projects.png';
import done from'src/assets/images/done.png';
import closedjobs from'src/assets/images/closedjobs.png';



NavSection.propTypes = {
  data: PropTypes.array,
};
{/* DAGOGO U STOPPED HERE MAY 16TH 2024 --> THIS IS WHERE THE MOVEMENT ISSUE IN YOUR MENU ISSUE LIES */}
export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding style={{ p: "1rem",color:"white",display:"flex",flexDirection:"column",gap:"0.4rem",fontFamily:"Public Sans, sans-serif", }}>
        {data.map((item) => (   
          <>
          <NavItem key={item.title} item={item}/>
           {
             item?.children?.map((c) => (
              <SubNavItem key={c.title} item={c}/>
            ))
           }
          </>
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, iconLabel, info } = item;

  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        color: '#FFFFFF !important',
        fontSize: '18px',
        fontFamily:"Public Sans, sans-serif",
        '&.active': {
          color: '#130C66',
          // bgcolor: '#66000000',
          backgroundColor: path != '#' && 'white',
          fontWeight: 'fontWeightBold',
          borderBottomLeftRadius: '26px',
        },
      }}
    >
      {iconLabel != 'msg' && iconLabel != 'settings' && <StyledNavItemIcon sx={{ fontSize: '20px'}}>{icon && icon}</StyledNavItemIcon>}
      {iconLabel === 'msg' && <StyledNavItemIcon sx={{fontSize: '20px'}}><MessageIcon /></StyledNavItemIcon>}
      {iconLabel === 'settings' && <StyledNavItemIcon sx={{fontSize: '20px'}}><SettingsIcon /></StyledNavItemIcon>}

      <ListItemText disableTypography primary={title} sx={{color: title.toLowerCase() === 'cooler' && 'white'}}/>
      {/* sx={{ color: '#130C66', fontSize: '18px'}}/> */}
      {/* <ListItemText disableTypography primary={title} sx={{ color: '#FFFFFF', fontSize: '18px'}}/> */}

      {info && info}
    </StyledNavItem>
  );
}
function SubNavItem({ item }) {
  const { title, path, icon, info } = item;

  return (
      <StyledNavItem
      component={RouterLink}
      to={path}
      style={{
        //color: '#FFFFFF',
        color: '#130C66',
        backgroundColor: path != '#' && 'white',
        display:"flex",
        justifyContent:"flex-start",
        borderRadius:"0.5rem",
        fontFamily:"Public Sans, sans-serif",
        
        width:"95%",
        '&.active': {
          color: '#130C66',
          backgroundColor: path != '#' && 'white',
          fontWeight: 'fontWeightBold',
         // borderBottomLeftRadius: '26px',
        },
      }}
    >
      {/* <StyledNavItemIcon sx={{color: '#FFFFFF', fontSize: '20px'}}>{icon && icon}</StyledNavItemIcon> */}
      <StyledNavItemIcon sx={{fontSize: '18px', ml: 0}}>
      {icon === 'Home' && <img src={home} alt=''/>}
        {icon === 'Search' && <img src={postajob} alt=''/>}
        {icon === 'List' && <img src={actionitems} alt=''/>}
        {icon === 'Bell' && <img src={notifications} alt=''/>}
        {icon === 'Speaker' && <img src={activejobpostings} alt=''/>}
        {icon === 'People' && <img src={projects} alt=''/>}
        {icon === 'Star' && <img src={toptalentexperts} alt=''/>}
        {icon === 'Done' && <img src={done} alt=''/>}
        {icon === 'Drafted' && <img src={drafted} alt=''/>}
        {icon === 'Closed' && <img src={closedjobs} alt=''/>}


      </StyledNavItemIcon>

      <ListItemText disableTypography primary={title} sx={{fontSize: '18px'}}/>

      {info && info}
    </StyledNavItem>
  );
}
