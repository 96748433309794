import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, TextField, Grid } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useEffect, useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import ApplicationForm from 'src/components/login/applicationForm';
import ProfileForm from 'src/components/login/profileForm';

import { FaRegArrowAltCircleLeft } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import JobPostingForm from 'src/components/login/jobPostingForm';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import folder from 'src/assets/images/folder.png'
import { setDemo, setRecruiter } from 'src/redux/reducers/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCandidatesPendingReview, fetchJobsWithCandidatesPendingReview } from 'src/redux/actions/group.action';
import { AppNewsUpdate } from 'src/sections/@dashboard/app';




// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
    //fontFamily:"Libre Baskerville, sans serif",
  },
}));


const StyledMiddleLine = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    display: 'none',
  
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));


const StyledWidth = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  display:"flex",
  justifyContent:"flex-start",
  alignItems:"center",
  flexDirection:"column",
  padding: theme.spacing(12, 0),
  //backgroundColor:"pink",
  margin:'0 auto'
}));


const StyledTypography = styled('Typography')(({ theme }) => ({
  fontSize:"1.5rem",
  left:"-14rem",


  [theme.breakpoints.down('xl')]: {
   
    left:"-5rem",
  },


  [theme.breakpoints.down('lg')]: {
   
    left:"-2rem",
  },


  [theme.breakpoints.down('md')]: {
   
    left:"3rem",
  }



}));

// ----------------------------------------------------------------------

export default function ActionItemsPage() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [companyName, setCompanyName] = useState('');

  const rows= [
    {interviewRound:"IT-22", typeOfInterview:"Lead Product Designer",feedback:"Candidates Pending Review", duration:"3/4/2024"},
    {interviewRound:"IT-522", typeOfInterview:"Data Architect",feedback:"Candidates Pending Selection", duration:"3/11/2024"},
    {interviewRound:"OPS-22", typeOfInterview:"Director of People Operations",feedback:"Candidates Pending Offer Letter", duration:"3/11/2024"},
    
  ];


  const { user } = useSelector((state) => state.auth);
  //console.log("user details-->",user)
 


  const { candidatesPendingReview,jobsPendingReview, isLoading } = useSelector((state) => state.group);

  console.log("candidates pending review-->", candidatesPendingReview)
  console.log("jobs pending review-->", jobsPendingReview)

  //let notMyGroups = allJobs
 //const [notMyGroups,setNotMyGroups] = useState(allJobs)

 
useEffect(()=>{

  dispatch(fetchJobsWithCandidatesPendingReview(user && user.id?user.id:user && user.uid));
  dispatch(fetchCandidatesPendingReview());

},[])


  useEffect(() => {

    if(user && user.userType=== 'recruiter'){
      dispatch(setRecruiter(true));
      dispatch(setDemo(false));
    }
    else{
  
     dispatch( setRecruiter(false));
     dispatch(setDemo(true));
    }
  },[])

  

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };



  return (
    <>
      <Helmet>
        <title> Action Items | oneRecruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       
        <Container style={{marginTop:"0rem",width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>

         



      
       {/*THIRD SECTION  */}
            <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem"/*,color:"#40255F"*/,display:"flex",justifyContent:"center",fontFamily:"libre Baskerville"}}>
            Action Items
            </Typography>
   
          <Divider style={{width:"100%",marginBottom:"3rem"}}/>

          <Typography variant="body2" sx={{ mt: 1,mb:10, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem",fontSize:"1.2rem",fontFamily:"libre Baskerville" }}>
           The list and folders below are your action items. Be sure to check this page and your pipeline regularly.
           Candidates are eager to work with your company, so lets not keep them waiting. Please ensure you provide valueable feeback for each profile, This will help the candidate understand
           why they were not the best fit.
               
              
            </Typography>



         <StyledTypography variant="h3" gutterBottom style={{marginTop:"3rem",marginBottom:"0.8rem",fontWeight:"600",width:"44rem",color:"black",display:"flex",justifyContent:"flex-start",position:"relative"}}>
            Needs Immediate Action
            </StyledTypography>    
            


            <Divider style={{width:"100%",height:"3px",backgroundColor:"red",marginBottom:"1rem"}}/>




            <TableContainer sx={{py:"2rem", width: "60rem",}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF"}}>

                          <TableCell sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}}  align="center">{/*JP ID*/}</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center">{/*Title*/}</TableCell>
                          <TableCell  sx={{color:"black",fontSize:"1.2rem",fontWeight:"800",fontFamily:"Public Sans, sans-serif !important",}} align="center">{/*Time Lapsed*/}</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        { jobsPendingReview && jobsPendingReview.length && candidatesPendingReview && candidatesPendingReview.length ? jobsPendingReview.filter((item)=>(item.businessId ===(user &&  user.id))).map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{my:2, '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{ backgroundColor:"#e0dcdc",width:"28%"}}  scope="row">  
                            
                          <TextField  
                            name="interview round"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Round -1'
                            value={/*row.candidateLocation*/"IT-22"}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />
                            
                      
                            </TableCell>


                            {<TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Virtual'
                            value={row.jobRole}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  

                            </TableCell>}


                          {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="duration"
                           inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                           placeholder='60 mins'
                           value={row.jobRole}
                           onChange={(e) => setCompanyName(e.target.value)}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                          </TableCell> */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                          placeholder=''
                          value={`${ Math.round(((new Date().getTime()/1000) - (candidatesPendingReview.filter((candidate)=>(candidate.jobId === row.id))[0] && candidatesPendingReview.filter((candidate)=>(candidate.jobId === row.id))[0].pendingReviewPeriodStarted.seconds ))/86400) } Days` /*new Date(row.pendingReviewPeriodStarted.seconds*1000).toDateString()*/ }
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                          />  
                          
                          </TableCell>

                         {/*  <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>

                         <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                          placeholder=''
                          value={row.salaryRange}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif !important",}}
                          />  
                          
                          </TableCell>*/}



                         {/* <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif !important",}}>
                          <LoadingButton onClick={()=>{addToTheRecruiterJobs(user && user.id,row.id,user &&user.firstName,user && user.lastName)}}
                           sx={{bgcolor:"#40255F",fontFamily:"Public Sans, sans-serif",width:"10rem",padding:"1rem"}}   type="submit" variant="contained" disabled={false}>
                          { "Add Job"}
                         </LoadingButton>
                          </TableCell>*/}

                          </TableRow>
                        )):
                        
                        <TableRow  sx={{marginTop:"0.5rem",fontFamily:"Public Sans, sans-serif !important",width:"100%"}}>
                        <TableCell colSpan={5}>  <center style={{display:"flex",justifyContent:"center",padding:"0.5rem",width:"100%",color:"gray",fontSize:"22px",fontWeight:"800"}}> No Work for now... </center></TableCell>
                        </TableRow>
                        }
                      </TableBody>
                    </Table>
                </TableContainer>

           

            {/*<TableContainer sx={{py:"2rem", width: "1000px"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                      
                      </TableHead>
                      <TableBody>
                        {rows.map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif",}}  scope="row">  
                            
                          <TextField  
                            name="interview round"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Round -1'
                            value={row.interviewRound}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif",}}
                          />
                            
                      
                            </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc",fontFamily:"Public Sans, sans-serif",}}>

                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif !important",
                              },
                              }}
                            placeholder='Virtual'
                            value={row.typeOfInterview}
                            onChange={(e) => setCompanyName(e.target.value)}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",fontFamily:"Public Sans, sans-serif",}}
                          />  

                            </TableCell>


                         


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                          <TextField 
                          name="feedback"
                          inputProps={{
                            style: {
                            height:'0.6rem'
                            ,fontFamily:"Public Sans, sans-serif !important",
                            },
                            }}
                          placeholder='basic interview'
                          value={row.feedback}
                          onChange={(e) => setCompanyName(e.target.value)}
                          style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                          />  
                          
                          </TableCell>

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                      </TableContainer> */}

       <Divider style={{width:"100%",height:"3px",backgroundColor:"red",marginTop:"1rem",marginBottom:"3rem"}}/>




                <Typography variant="h2" gutterBottom style={{marginTop:"0rem",width:"44rem",color:"#40255F",display:"flex",justifyContent:"center"}}>
            Candidate Pipeline
            </Typography>


            <Divider style={{width:"25%",marginBottom:"3rem",marginTop:"0.5rem",backgroundColor:"black"}}/>



        <Grid  container  xs={12} spacing={1} style={{display:"flex",flexDirection:"column",gap:"6rem",width:"66rem"}}>

            <Grid  container  xs={12} /*spacing={11}*/ style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:"0rem",paddingLeft:"4rem",paddingRight:"4rem",margin:"0 auto"}}>
                <Grid onClick={()=>{navigate('/dashboard/candidates-pending-review')}}  style= {{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" }} item xs={3}>
                
                  <div style={inputContainer}>
                    <img src={folder} alt="folder img"/>
                  </div>

                  <b style={{ fontSize: '20px', width: '100%',textAlign:"center" }}>Candidates Pending Review</b>
                </Grid>


                <Grid  onClick={()=>{navigate('/dashboard/candidates-pending-selection')}} style= {{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" }}  item xs={3}>
                  
                  <img src={folder} alt="folder img"/>
                  <b style={{ fontSize: '20px', width: '100%',textAlign:"center" }}>Candidates Pending Selection</b>
                </Grid>
            </Grid>




             <Grid  container  xs={12} /*spacing={11}*/ style={{display:"flex",flexDirection:"row",justifyContent:"space-between",gap:"0rem",paddingLeft:"4rem",paddingRight:"4rem",margin:"0 auto"}}>
                <Grid  onClick={()=>{navigate('/dashboard/candidates-pending-offer')}} style= {{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" }}  item xs={3}>
                
                  <div style={inputContainer}>
                    <img src={folder} alt="folder img"/>
                  </div>

                  <b style={{ fontSize: '20px', width: '100%',textAlign:"center" }}>Candidates Pending Offer Letter</b>
                </Grid>


                <Grid onClick={()=>{navigate('/dashboard/closed-jobs')}}  style= {{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column" }} item xs={3}>
                  
                  <img src={folder} alt="folder img"/>
                  <b style={{ fontSize: '20px', width: '100%',textAlign:"center" }}>Recently Closed Job Postings</b>
                </Grid>
            </Grid>
       </Grid>

           
            {/*<JobPostingForm />*/}
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(true)}>
             {/* <Link href='#' variant="subtitle2">Terms and Conditions</Link>*/}
            </Typography>
     {/*THIRD SECTION  END*/}

          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
