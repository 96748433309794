import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  allUsers: [],
  liveUsers: [],
  connectedUsers: [
/*THIS DATA IS TEMPORARY, CUZ I HAVENT SEEDED IT TO ANY DATABASE YET */
  {
    uid:"yZIpS4WcZxZ150nesewtKL5U6w43",
    name:"John smith",
    email:"john@gmail.com",
    city:"boise Idaho",
    intro:"i am a hard worker",
    skillset:"front end dev",
    skills_needed:"backend dev",
    lookingFor:"ui designer",
    lastActive:186770963,
    isTechnical:"No",
    photoUrl:"https://firebasestorage.googleapis.com/v0/b/bridgetech-advance-project.appspot.com/o/profile_images%2F6ee430b6-81af-496f-9690-6250c7c77f23.jpeg?alt=media&token=a834584d-47ad-4f48-bc95-6ba02293af26",
    password:123456,
    type:'',
    status:'',
    invited_amt:'',
    skipped_amt:''

  }, 
  {
    uid:"yZIpS4WcZxZ150nesewtKL5U6w43",
    name:"Tim Marcus",
    email:"tim@gmail.com",
    city:"boise Idaho",
    intro:"i am a hard worker",
    skillset:"front end dev",
    skills_needed:"backend dev",
    lookingFor:"ui designer",
    lastActive:186770963,
    isTechnical:"Yes",
    photoUrl:"https://firebasestorage.googleapis.com/v0/b/bridgetech-advance-project.appspot.com/o/profile_images%2F6ee430b6-81af-496f-9690-6250c7c77f23.jpeg?alt=media&token=a834584d-47ad-4f48-bc95-6ba02293af26",
    password:123456,
    type:'',
    status:'',
    invited_amt:'',
    skipped_amt:''

  }
  ],
  connects: [],
  connects2: [],
  isLoading: false,
  info: '',
  error: '',
  message: '',
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetchUsersPending: (state) => {
        state.isLoading = true;
        state.error = '';
        state.message = '';
      },
      fetchUsersSuccess: (state, action) => {
        state.isLoading = false;
        state.allUsers = action.payload;
        state.error = '';
        state.message = action.payload.msg;
    },
    fetchRealTimeUsersSuccess: (state, action) => {
      state.isLoading = false;
      state.liveUsers = action.payload;
      state.error = '';
      state.message = action.payload.msg;
  },
  fetchConnectedUserSuccess: (state, action) => {
    state.isLoading = false;
    state.connectedUsers = action.payload;
    state.error = '';
    state.message = action.payload.msg;
},
    fetchUsersFailed: (state, { payload }) => {
      (state.isLoading = false);
        (state.error = payload.errorMessage);
    },
    initiatePending: (state) => {
      state.isLoading = true;
      state.error = '';
      state.message = '';
    },
    initiateSuccess: (state, action) => {
      state.isLoading = false;
      state.connects = action.payload;
      state.error = '';
  },
    initiateSuccess2: (state, action) => {
      state.isLoading = false;
      state.connects2 = action.payload;
      state.error = '';
  },
  initiateFailed: (state, { payload }) => {
     state.isLoading = false;
     state.info = payload.errorMessage;
  },
  resetConnects: (state, { payload }) => {
    state.connectedUsers = [];
 },
    clearUser: (state) => {
      return {
        ...initialState,
      };
    },
  },
});

const { actions, reducer } = userSlice;

export const {
 fetchUsersPending,
 fetchUsersSuccess,
 fetchRealTimeUsersSuccess,
 fetchConnectedUserSuccess,
 fetchUsersFailed,
 initiatePending,
 initiateSuccess,
 initiateSuccess2,
 initiateFailed,
 resetConnects,
 clearUser,
} = actions;

export default reducer;


