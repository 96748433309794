import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, 
      InputAdornment, TextField, Checkbox,
       Typography, Grid, FormControlLabel, RadioGroup, 
       FormControl, FormLabel, Radio, 
       Button} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signin, signinRecruiter } from 'src/redux/actions/auth.action';

// ----------------------------------------------------------------------

export default function ApplicationFormRecruiter() {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [bio, setBio] = useState('');

  const [acctHolderName, setAcctHolderName] = useState('');
  const [bestContactNo, setBestContactNo] = useState('');

  const [positionTitle, setPositionTitle] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [companyWebsite, setCompanyWebsite] = useState('');
  const [companyEmail, setCompanyEmail] = useState('');
  const [awards, setAwards] = useState('');
  const [topTenJobs, setTopTenJobs] = useState('');
  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [YearsOfHr, setYearsOfHr] = useState('');
  const [YearsOfIndependence, setYearsOfIndependence] = useState('');
  
  const [LinkedInProfile, setLinkedInProfile] = useState('');
  
  const [MonthlyReqs, setMonthlyReqs] = useState('');

  const [techStack, setTechStack] = useState('');

  const [BestMonth, setBestMonth] = useState('');

  const [meetingAvailability, setmeetingAvailability] = useState('');

  const [mostChallenging, setmostChallenging] = useState('');

  const [selectedFile, setSelectedFile] = useState({selectedFile: [], selectedFileName: []});
  const [file, setFile] = useState();
  const handleselectedFile = event => {
    setSelectedFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
    setFile(URL.createObjectURL(event.target.files[0]));
};



  const dispatch = useDispatch(); 

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };


  const userSignin = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { email, password };
    dispatch(signinRecruiter(user, navigate, setLoading));
  }

  return (
    <div style={{width:"80%",zIndex:0}}>
     <form onSubmit={userSignin}>

     <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start",marginBottom:"5rem",fontFamily:"Libre Baskerville,sans-serif"}}>
              Account Holder Information
            </Typography>


            <Grid container spacing={6} direction="row" style={{marginBottom:"3rem"}}>
                <Grid item xs={6} >
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem"}}>Account holder's name*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='first and last name'
                      value={acctHolderName}
                      onChange={(e) => setAcctHolderName(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Email Address For Account*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='email@company.com'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
              </Grid>



     
      
              <Grid container spacing={6} direction="row">
                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Best Contact Number with Area Code*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='(123) 456-789'
                      value={bestContactNo}
                      onChange={(e) => setBestContactNo(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Mailing Address*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='Mailing Address'
                      value={companyEmail}
                      onChange={(e) => setCompanyEmail(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>
              </Grid>


          
              <Typography variant="h3" gutterBottom style={{marginTop:"5rem",marginBottom:"3rem", color:"#40255F",display:"flex",justifyContent:"flex-start",fontFamily:"Libre Baskerville,sans-serif"}}>
              Profile Information
            </Typography>
              <Grid container direction="row" sx={{mt:2}}>
                <Grid item xs={12}>
                  <b style={{ fontSize: '17px', width: '60%' ,position:"relative",top:"-1rem" }}>Bio: Tell us about yourself and your experience as a Recruiter. If selected, there will be a follow up meeting to dive deeper.*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={15} 
                    name="jobResponsibilities"
                    value={bio}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setBio(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>


              <Grid container spacing={6}  sx={{mt:3}} direction="row">
                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>What is your current work status?*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel value="unemployed" control={<Radio />} label="Unemployed" />
                     <FormControlLabel value="Self-employed" control={<Radio />} label="Self-employed" />
                     <FormControlLabel value="full-time W-2 Employee" control={<Radio />} label="Full-time, W-2 Employee" />
                     <FormControlLabel value="full-time c2c" control={<Radio />} label="Full-time, C2C" />
                     <FormControlLabel value="part-time" control={<Radio />} label="Part-time" />
                     <FormControlLabel value="other" control={<Radio />} label="Other" />
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>

                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%' ,position:"relative",top:"-1rem"}}>Are you working as an indedpendent recruiter at the moment?*</b>
                  <div style={inputContainer}>
                   <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel value="Yes" control={<Radio />} label="Yes, I am currently working as a 1099, IR." />
                     <FormControlLabel value="No" control={<Radio />} label="No, I have in the past." />
                     <FormControlLabel value="Never" control={<Radio />} label="No, I have never worked as an IR before." />
                   
                   </RadioGroup>
                 </FormControl>
                 
                  </div>
                </Grid>
              </Grid>



      
        
              <Grid container direction="row" xs={12}  sx={{mt:5,display:"flex",justifyContent:"space-between"}}>
                <Grid item xs={5.5}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>Awards/ Accolades/ Recent Press</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={awards}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setAwards(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>




                <Grid item xs={5.5}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>What Are your top 10 most hired Job titles?</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={topTenJobs}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setTopTenJobs(e.target.value)}
                    
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>




              <Grid container spacing={6}  sx={{mt:3}} direction="row">
                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Industry of Expertise*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel value="unemployed" control={<Radio />} label="Information Technology" />
                     <FormControlLabel value="Self-employed" control={<Radio />} label="Salesforce (Technology)" />
                     <FormControlLabel value="full-time W-2 Employee" control={<Radio />} label="Marketing & Advertising" />
                     <FormControlLabel value="full-time c2c" control={<Radio />} label="Sales (IT or Salesforce)" />
                     <FormControlLabel value="part-time" control={<Radio />} label="Healthcare (Admin)" />
                     <FormControlLabel value="part-time" control={<Radio />} label="Healthcare (Technical)" />
                     <FormControlLabel value="other" control={<Radio />} label="Other" />
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>

                <Grid item xs={6} sx={{display:"flex",flexDirection:"column", gap:"2rem"}}>
                  
                  <div>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Years of Experience with Recruitment*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='# of Years'
                      value={yearsOfExperience}
                      onChange={(e) => setYearsOfExperience(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                  </div>

                  <div>
                  <b style={{ fontSize: '15px', width: '100%', marginTop:"1.5rem",position:"relative",top:"-1rem" }}>Years of Experience with HR functions*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='# of Years'
                      value={YearsOfHr}
                      onChange={(e) => setYearsOfHr(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                  </div>

                  <div>
                  <b style={{ fontSize: '15px', width: '100%', marginTop:"1.5rem" ,position:"relative",top:"-1rem"}}>Years of Experience as an indedpendent recruiter*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder='# of Years'
                      value={YearsOfIndependence}
                      onChange={(e) => setYearsOfIndependence(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                  </div>

                </Grid>
              </Grid>


         
              <Grid container spacing={6} direction="row" sx={{mt:2}}>
                <Grid item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Linkedin Profile*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Enter URL'
                      value={LinkedInProfile}
                      onChange={(e) => setLinkedInProfile(e.target.value)}
                      style={inputStyle}
                    />
                  </div>
                </Grid>

              
              </Grid>



              <Typography variant="h3" gutterBottom style={{marginTop:"5rem", color:"#40255F",display:"flex",justifyContent:"flex-start",fontFamily:"Libre Baskerville,sans-serif"}}>
              Application Information
            </Typography>
              <Grid container direction="row" sx={{mt:5}}>
                <Grid item xs={12}>
                  <b style={{ fontSize: '17px', width: '60%',marginBottom:"2rem",position:"relative",top:"-1rem" }}>How many reqs. on average are you filling on a monthly basis? (Add as much detail, if it varies depending on different variables.)*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={15} 
                    name="jobResponsibilities"
                    value={MonthlyReqs}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setMonthlyReqs(e.target.value)}
                    placeholder='  Were you working as an agency recruiter,internal recruiter or independent recruiter '
                    >
                    </textarea>

                  </div>
                </Grid>

                
                
              </Grid>



              <Grid container direction="row" xs={12}  sx={{mt:5,display:"flex",justifyContent:"space-between"}}>
                <Grid item xs={5.5}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>What tech stack or set of tools do you use to source and track candidates?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={techStack}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setTechStack(e.target.value)}
                    placeholder='  Add answer here'
                    >
                    </textarea>

                  </div>
                </Grid>




                <Grid item xs={5.5}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>What was your all-time best month, in total commissions? If you were paid a salary, please include that as well.*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={BestMonth}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setBestMonth(e.target.value)}
                    placeholder="  $1,000,000 / April 2020 ($50K salary)"
                    >
                    </textarea>

                  </div>
                </Grid>  
                
              </Grid>




              <Grid container direction="row" xs={12}  sx={{mt:5,display:"flex",justifyContent:"space-between"}}>
              <Grid item xs={5.5}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-1rem" }}>Do you have any experience working with a recruitment agency?*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel value="unemployed" control={<Radio />} label="Yes" />
                     <FormControlLabel value="Self-employed" control={<Radio />} label="No" />
                    
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>




                <Grid item xs={5.5}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>What is your availability for team meetings?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={meetingAvailability}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setmeetingAvailability(e.target.value)}
                    placeholder=" Monday, Wednesday & Fridays - 2:00pm - 4:00pm"
                    >
                    </textarea>

                  </div>
                </Grid>  
                
              </Grid>



              <Grid container direction="row" xs={12}  sx={{mt:5,display:"flex",justifyContent:"space-between"}}>
              

                <Grid item xs={7}>
                  <b style={{ fontSize: '17px', width: '60%',position:"relative",top:"-1rem"}}>Can you tell us the most challenging position you had to fill. Why was it challenging? How did you fill it?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={10} 
                    name="jobResponsibilities"
                    value={mostChallenging}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"1rem",fontSize:"1rem",fontFamily:"arial",color:"gray"}}
                    onChange={(e) => setmostChallenging(e.target.value)}
                    placeholder=" Add answer here"
                    >
                    </textarea>

                  </div>
                </Grid>  


                <Grid item xs={4} style={{display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
                 
                  <div style={{display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center"}}>
                  <b style={{ fontSize: '15px' }}>Please Upload Resume*</b>
                 {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                      <LoadingButton sx={{bgcolor:"#40255F"}} fullWidth size="large"  variant="contained" disabled={loading}>
                     {"Upload File"}
                    </LoadingButton>
                  </Stack>*/}


                 <center style={{marginTop:"0rem"}}>
                  <Button component="label"  sx={{bgcolor:"#40255F"}}  size="large" type="button" variant="contained" disabled={loading}>
                    
                    <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleselectedFile}
                   />
                   { "Upload File"}
                  </Button>

                  <div style={{marginTop:"1rem"}}>{selectedFile.selectedFileName}</div>
               </center>


                  </div>

                </Grid>

                
              </Grid>



      <center style={{marginTop:"6rem"}}>
      <LoadingButton  sx={{bgcolor:"#40255F"}}  size="large" type="submit" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "Submit Application"}
      </LoadingButton>
      </center>

      </form>


      

   
     

     {/* <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1,color:"gray" }}>
        <Typography>
        Thanks for Submitting
        </Typography>
      </Stack>*/}




    </div>
  );
}
