import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Iconify from '../components/iconify';

import Piggy from '../assets/images/piggy2.jpg';
import CoolerEntryIMG from '../assets/images/Cooler_Entry.jpg';
//import LoginForm from 'src/components/login/LoginForm';
import { useState } from 'react';
import ForgotPasswordForm from 'src/components/forgot-password/ForgotPassowrdForm';
import { FaCircle, FaRegArrowAltCircleLeft } from "react-icons/fa";
import icon  from 'src/assets/images/Cooler.png'
import { useNavigate } from 'react-router-dom';
import LoginFormBusiness from 'src/components/login/LoginFormBusiness';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    width:"100%",
    flexDirection:"column",
    fontFamily:"Public Sans, sans serif",
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  marginTop:'-80px',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems:'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
  fontFamily:"Public Sans, sans-serif !important",
}));

// ----------------------------------------------------------------------

export default function LoginPageBusiness() {
  const mdUp = useResponsive('up', 'md');
  const [forgotPassword, setForgotPassword] = useState(false);
  const navigate = useNavigate()

  return (
    <>
      <Helmet>
        <title> Login |One Recruiter </title>
      </Helmet>

      <StyledRoot>
      {/* <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
      <img src={Piggy} width="100" height="100"/>
    </Typography> */}
      

        {/*mdUp && (
          <StyledSection>
            <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src={CoolerEntryIMG} alt="login" />

          </StyledSection>
        )*/}



       <div  style={{zIndex:"1000", position:"relative",top:"0",backgroundColor:"#40255F",display:"flex",justifyContent:"center",alignItems:"center",width:"100%",paddingTop:"1rem",paddingBottom:"1rem"}}>
       <FaRegArrowAltCircleLeft onClick={()=>{navigate('/home')}}  style={{color:"white",fontSize:"2.5rem",position:"absolute",left:"1rem",zIndex:"100"}}/>
        <img style={{height:"100px",width:"160px"}} src={icon} alt={"one recruiter logo"}/>
        </div>

        <Container style={{width:"100%"}}>
          {forgotPassword ? <StyledContent>
            <Typography variant="h4" sx={{display:"flex",alignItems:"center",justifyContent:"center"}} gutterBottom>
              Forgot your Password
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Enter email address to reset password {''}
            </Typography>

            <ForgotPasswordForm setForgotPassword={setForgotPassword}/>
            <Typography variant="body2" sx={{ mt: 2 }} onClick={() => setForgotPassword(false)}>
              <Link href='#' variant="subtitle2">Sign In</Link>
            </Typography>
          </StyledContent> : 
          
          <StyledContent>
            <Typography variant="h3" gutterBottom sx={{color:"#40255F",fontFamily:"Public Sans, sans-serif !important",}}>
              Business Account
            </Typography>
            <Typography variant="body2" sx={{ mt: 2, display:"flex",alignItems:"center",justifyContent:"center",gap:"1rem", color:"#40255F",fontFamily:"Public Sans, sans-serif !important", }}>
           <b style={{fontSize:"1.1rem"}}>Click</b>
           <FaCircle style={{fontSize:"0.4rem"}} />
 
           <b style={{fontSize:"1.1rem"}}>Interview</b>
           <FaCircle style={{fontSize:"0.4rem"}}/>

            <b style={{fontSize:"1.1rem"}}>Hire</b>
            </Typography>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' ,fontFamily:"Public Sans, sans-serif !important",}}>
                Enter your email and password to sign in
              </Typography>
            </Divider>

            <LoginFormBusiness />
            <Typography variant="body2" sx={{ mt: 2,fontFamily:"Public Sans, sans-serif !important", }} onClick={() => setForgotPassword(true)}>
              <Link style={{fontFamily:"Public Sans, sans-serif !important",}} href='#' variant="subtitle2">Forgot Password</Link>
            </Typography>
          </StyledContent>}
        </Container>
      </StyledRoot>
    </>
  );
}
