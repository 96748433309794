import React from 'react'
import './about2.css'
import about2graphic from 'src/assets/images/about2graphic.png'
import about2decor from 'src/assets/images/about2decor.png'
import {AiOutlineArrowRight} from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

const About2 = () => {


const navigate= useNavigate()


  return (

    <>


    <section id='about2'>
    <div className="container-hp about2__container">
       


  
       

      <div className="about2Explainer">
      <h1 style={{fontWeight:"700",color:"#40255F",fontSize:"2.3rem"}}> Community & Stability{/*<span className="text-accent">  get the Job! </span>*/}</h1>

         <p>
         Talent Acquisition is seasonal with a huge competitive culture. There are points of extreme highs and lows in demand based on industry-specific economic factors. During times of low demand companies are forced to lay off members of their TA and HR Team to meet their bottom line. Leaving many talented recruiters struggling to find new opportunities while others take their chances in starting a recruitment agency. OneRecruiter is a platform that creates a community for independent recruiters while balancing the workload and competition for job postings.
          Recruiters can now rely on themselves for stability and job security.
        </p>

       {/*<div className = "about-btn-placement" >
        <button   onClick={()=>{navigate('/login')}} className="btn ">
           Get Started 
         </button>

         <p  onClick={()=>{navigate('/dashboard/home')}} className="btn-invisible" >See How It Works</p>
     </div>*/}


      </div>
     
     {/*<div className='aboutCTA'>
    
       <img  className='CTAbox' src={countPennies} alt="counting coins"/>
     

     <div className='clip-path-bottom-right'></div>
     <div className='clip-path-bottom-left'></div>
     <div className='clip-path-top-left'></div>
</div>*/}  


{<div className='about2CTA'>
    
 
    <img  className='CTAboximg2' src={about2graphic} alt="search image"/>
    
       
        
        </div>}
      

        <img style={{position:"absolute",bottom:"-3rem",left:"5rem",height:"300px"}}  className='about2__decor'  src={about2decor} />
   
   
</div>



    </section>


</>
  )
}

export default About2