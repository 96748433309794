import * as React from 'react';
import Typography from '@mui/material/Typography';
// import Title from './title';
import { Button, Divider, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { fCurrency } from 'src/utils/formatNumber';
import microsoft from 'src/assets/images/microsoft.jpeg'

function preventDefault(event) {
  event.preventDefault();
}

export default function StatsBox({firstRow,secondRow,thirdRow}) {
  const { user } = useSelector((state) => state.auth);

  return (
    <div style={{border:"2px solid lightgray",padding:"1rem",borderRadius:"0.5rem"}}>
      <Typography
            color="textPrimary"
            variant="h6"
            component="p"
          >
       
      </Typography>
     
     
      
       
      <Typography
            color="textPrimary"
            
            component="p"
            style={{marginBottom:"1rem",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"flex-start",gap:"1rem",backgroundColor: 'white', borderRadius:"1rem" }}
          >
        
        <span style={{fontSize:"19px",marginTop:"0px"}}>{firstRow}</span>

       <div style={{display:"flex",justifyContent:"flex-start",alignItems:"flex-start", padding: '10px',paddingLeft:"0px", backgroundColor: 'white', fontSize:"33px"}}>
       <b style={{color:"#130C66"}}>{secondRow}</b>
      
       </div>


       <i style={{fontSize:"16px",marginTop:"0px",color:"grey"}}>{thirdRow}</i>

      </Typography>

   
      
     
      
      {/* <div> */}
        
        {/* </div> */}
        </div>
    );
    }