import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Link, Stack, IconButton, 
      InputAdornment, TextField, Checkbox,
       Typography, Grid, FormControlLabel, RadioGroup, 
       FormControl, FormLabel, Radio, 
       Button,
       MenuItem,Select,
       FormGroup} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../iconify';
import { useDispatch, useSelector } from 'react-redux';
import { signin, signinBusiness } from 'src/redux/actions/auth.action';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { createGroup, uploadJobDescription } from 'src/redux/actions/group.action';
import { notifyErrorFxn } from 'src/utils/toast-fxn';


// ----------------------------------------------------------------------

export default function JobPostingForm() {
  const navigate = useNavigate();

  const StyledConnector = styled('div')(({ theme }) => ({
   
    left:"4.5rem",
  

   
    [theme.breakpoints.down('xl')]: {
     
      left:"4.3rem",
    },


  /*  [theme.breakpoints.down(' 1336px')]: {
     
      left:"4rem",
    },*/


   /* [theme.breakpoints.down(' 1386px')]: {
     
      left:"2.2rem",
    },*/
  
  
   /* [theme.breakpoints.down('lg')]: {
     
      left:"-2rem",
    },
  
  
    [theme.breakpoints.down('md')]: {
     
      left:"3rem",
    }*/
  
  
  
  }));




  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fname, setFName] = useState('');
  const [lname, setLName] = useState('');
  const [bio, setBio] = useState('');

  
 

  const [positionTitle, setPositionTitle] = useState('');


  const { user } = useSelector((state) => state.auth);
  const { isLoading } = useSelector((state) => state.group);
  console.log("user is--->",user)
  const userId = user && user.id?user.id:user && user.uid
  const [companyName, setCompanyName] = useState(user && user.companyName?user.companyName:"");

  const [showRound2,setShowRound2] = useState(false)
  const [showRound3,setShowRound3] = useState(false)

  const [sourceCandidates,setSourceCandidates] = useState(false)
  const [postJobExternally,setPostJobExternally] = useState(false)

  const [termsAgreed,setTermsAgreed] =  useState(false)

  


  const [jobRole, setjobRole] = useState('');
  const [salary, setSalary] = useState('');
  const [skillLevel,setSkillLevel] = useState('')
  const [typeOfRemoteWork,setTypeOfRemoteWork] = useState('')
  const [workStatus, setWorkStatus] = useState('');
  const [addressOnSite, setAddressOnSite] = useState('');
  const [roleOnSite, setRoleOnSite] = useState('');
  const [hiringManagerName, setHiringManagerName] = useState('');
  const [teamSize, setTeamSize] = useState('');
  const [roleAvailabilityReason, setRoleAvailabilityReason] = useState('');
  const [essentialSkills, setEssentialSkills] = useState('');

  const [nonEssentialSkills, setNonEssentialSkills] = useState('');

  const [successfulPersonDesc, setSuccessfulPersonDesc] = useState('');

  const [numberOfOpenings, setNumberOfOpenings] = useState('');

  const [sharedElseWhere, setSharedElseWhere] = useState('');


  const [typeOfInterview1,setTypeOfInterview1]= useState('');
  const [typeOfInterview2,setTypeOfInterview2]= useState('');
  const [typeOfInterview3,setTypeOfInterview3]= useState('');

  const [typeOfInterview4,setTypeOfInterview4]= useState('');
  const [typeOfInterview5,setTypeOfInterview5]= useState('');
  const [typeOfInterview6,setTypeOfInterview6]= useState('');

  const [interviewRound1,setInterviewRound1]= useState('');
  const [interviewRound2,setInterviewRound2]= useState('');
  const [interviewRound3,setInterviewRound3]= useState('');

  const [interviewRound4,setInterviewRound4]= useState('');
  const [interviewRound5,setInterviewRound5]= useState('');
  const [interviewRound6,setInterviewRound6]= useState('');
  

  const [interviewLead1,setInterviewLead1]= useState('');
  const [interviewLead2,setInterviewLead2]= useState('');
  const [interviewLead3,setInterviewLead3]= useState('');
  

  const [interviewLead4,setInterviewLead4]= useState('');
  const [interviewLead5,setInterviewLead5]= useState('');
  const [interviewLead6,setInterviewLead6]= useState('');

  const [interviewLead7,setInterviewLead7]= useState('');
  const [interviewLead8,setInterviewLead8]= useState('');
  const [interviewLead9,setInterviewLead9]= useState('');
  
  

  const [duration1,setDuration1]= useState('');
  const [duration2,setDuration2]= useState('');
  const [duration3,setDuration3]= useState('');


  const [feedback1,setFeedback1]= useState('');
  const [feedback2,setFeedback2]= useState('');
  const [feedback3,setFeedback3]= useState('');


  const [interviewLocation1,setInterviewLocation1]= useState('');
  const [interviewLocation2,setInterviewLocation2]= useState('');
  const [interviewLocation3,setInterviewLocation3]= useState('');

  const [interviewPanelMembers1,setInterviewPanelMembers1]= useState('');
  const [interviewPanelMembers2,setInterviewPanelMembers2]= useState('');
  const [interviewPanelMembers3,setInterviewPanelMembers3]= useState('');
  

  const [interviewPanelMembers4,setInterviewPanelMembers4]= useState('');
  const [interviewPanelMembers5,setInterviewPanelMembers5]= useState('');
  const [interviewPanelMembers6,setInterviewPanelMembers6]= useState('');

  //RIGHT NOW  - JUN 12 2024, I AM ONLY ACTIVELY USING INTERVIEW PANEL MEMBERS 1 -6, TWO IN EACH TABLE, IN NUMERICAL ORDER - DAGOGO
  //SO 7,8 AND 9 BELOW, I DONT SUBMIT THEM
  

  const [interviewPanelMembers7,setInterviewPanelMembers7]= useState('');
  const [interviewPanelMembers8,setInterviewPanelMembers8]= useState('');
  const [interviewPanelMembers9,setInterviewPanelMembers9]= useState('');





/*ROUND 1   */
/*monday */
const [availabilityStartMon1,setAvailabilityStartMon1]= useState('');
const [availabilityStartMon2,setAvailabilityStartMon2]= useState('');
const [availabilityStartMon3,setAvailabilityStartMon3]= useState('');


const [availabilityEndMon1,setAvailabilityEndMon1]= useState('');
const [availabilityEndMon2,setAvailabilityEndMon2]= useState('');
const [availabilityEndMon3,setAvailabilityEndMon3]= useState('');

/*tuesday*/

const [availabilityStartTue1,setAvailabilityStartTue1]= useState('');
const [availabilityStartTue2,setAvailabilityStartTue2]= useState('');
const [availabilityStartTue3,setAvailabilityStartTue3]= useState('');


const [availabilityEndTue1,setAvailabilityEndTue1]= useState('');
const [availabilityEndTue2,setAvailabilityEndTue2]= useState('');
const [availabilityEndTue3,setAvailabilityEndTue3]= useState('');


/**wednesday */

const [availabilityStartWed1,setAvailabilityStartWed1]= useState('');
const [availabilityStartWed2,setAvailabilityStartWed2]= useState('');
const [availabilityStartWed3,setAvailabilityStartWed3]= useState('');


const [availabilityEndWed1,setAvailabilityEndWed1]= useState('');
const [availabilityEndWed2,setAvailabilityEndWed2]= useState('');
const [availabilityEndWed3,setAvailabilityEndWed3]= useState('');

/**thursday */


const [availabilityStartThu1,setAvailabilityStartThu1]= useState('');
const [availabilityStartThu2,setAvailabilityStartThu2]= useState('');
const [availabilityStartThu3,setAvailabilityStartThu3]= useState('');


const [availabilityEndThu1,setAvailabilityEndThu1]= useState('');
const [availabilityEndThu2,setAvailabilityEndThu2]= useState('');
const [availabilityEndThu3,setAvailabilityEndThu3]= useState('');

/*friday */

const [availabilityStartFri1,setAvailabilityStartFri1]= useState('');
const [availabilityStartFri2,setAvailabilityStartFri2]= useState('');
const [availabilityStartFri3,setAvailabilityStartFri3]= useState('');


const [availabilityEndFri1,setAvailabilityEndFri1]= useState('');
const [availabilityEndFri2,setAvailabilityEndFri2]= useState('');
const [availabilityEndFri3,setAvailabilityEndFri3]= useState('');





/*ROUND 1  - END */

/*ROUND 2 */



/*monday */
const [availabilityStartMon4,setAvailabilityStartMon4]= useState('');
const [availabilityStartMon5,setAvailabilityStartMon5]= useState('');
const [availabilityStartMon6,setAvailabilityStartMon6]= useState('');


const [availabilityEndMon4,setAvailabilityEndMon4]= useState('');
const [availabilityEndMon5,setAvailabilityEndMon5]= useState('');
const [availabilityEndMon6,setAvailabilityEndMon6]= useState('');

/*tuesday*/

const [availabilityStartTue4,setAvailabilityStartTue4]= useState('');
const [availabilityStartTue5,setAvailabilityStartTue5]= useState('');
const [availabilityStartTue6,setAvailabilityStartTue6]= useState('');


const [availabilityEndTue4,setAvailabilityEndTue4]= useState('');
const [availabilityEndTue5,setAvailabilityEndTue5]= useState('');
const [availabilityEndTue6,setAvailabilityEndTue6]= useState('');


/**wednesday */

const [availabilityStartWed4,setAvailabilityStartWed4]= useState('');
const [availabilityStartWed5,setAvailabilityStartWed5]= useState('');
const [availabilityStartWed6,setAvailabilityStartWed6]= useState('');


const [availabilityEndWed4,setAvailabilityEndWed4]= useState('');
const [availabilityEndWed5,setAvailabilityEndWed5]= useState('');
const [availabilityEndWed6,setAvailabilityEndWed6]= useState('');

/**thursday */


const [availabilityStartThu4,setAvailabilityStartThu4]= useState('');
const [availabilityStartThu5,setAvailabilityStartThu5]= useState('');
const [availabilityStartThu6,setAvailabilityStartThu6]= useState('');


const [availabilityEndThu4,setAvailabilityEndThu4]= useState('');
const [availabilityEndThu5,setAvailabilityEndThu5]= useState('');
const [availabilityEndThu6,setAvailabilityEndThu6]= useState('');

/*friday */

const [availabilityStartFri4,setAvailabilityStartFri4]= useState('');
const [availabilityStartFri5,setAvailabilityStartFri5]= useState('');
const [availabilityStartFri6,setAvailabilityStartFri6]= useState('');


const [availabilityEndFri4,setAvailabilityEndFri4]= useState('');
const [availabilityEndFri5,setAvailabilityEndFri5]= useState('');
const [availabilityEndFri6,setAvailabilityEndFri6]= useState('');



/*ROUND 2 - END */


/*ROUND 3   */


/*monday */
const [availabilityStartMon7,setAvailabilityStartMon7]= useState('');
const [availabilityStartMon8,setAvailabilityStartMon8]= useState('');
const [availabilityStartMon9,setAvailabilityStartMon9]= useState('');


const [availabilityEndMon7,setAvailabilityEndMon7]= useState('');
const [availabilityEndMon8,setAvailabilityEndMon8]= useState('');
const [availabilityEndMon9,setAvailabilityEndMon9]= useState('');

/*tuesday*/

const [availabilityStartTue7,setAvailabilityStartTue7]= useState('');
const [availabilityStartTue8,setAvailabilityStartTue8]= useState('');
const [availabilityStartTue9,setAvailabilityStartTue9]= useState('');


const [availabilityEndTue7,setAvailabilityEndTue7]= useState('');
const [availabilityEndTue8,setAvailabilityEndTue8]= useState('');
const [availabilityEndTue9,setAvailabilityEndTue9]= useState('');


/**wednesday */

const [availabilityStartWed7,setAvailabilityStartWed7]= useState('');
const [availabilityStartWed8,setAvailabilityStartWed8]= useState('');
const [availabilityStartWed9,setAvailabilityStartWed9]= useState('');


const [availabilityEndWed7,setAvailabilityEndWed7]= useState('');
const [availabilityEndWed8,setAvailabilityEndWed8]= useState('');
const [availabilityEndWed9,setAvailabilityEndWed9]= useState('');

/**thursday */


const [availabilityStartThu7,setAvailabilityStartThu7]= useState('');
const [availabilityStartThu8,setAvailabilityStartThu8]= useState('');
const [availabilityStartThu9,setAvailabilityStartThu9]= useState('');


const [availabilityEndThu7,setAvailabilityEndThu7]= useState('');
const [availabilityEndThu8,setAvailabilityEndThu8]= useState('');
const [availabilityEndThu9,setAvailabilityEndThu9]= useState('');

/*friday */

const [availabilityStartFri7,setAvailabilityStartFri7]= useState('');
const [availabilityStartFri8,setAvailabilityStartFri8]= useState('');
const [availabilityStartFri9,setAvailabilityStartFri9]= useState('');


const [availabilityEndFri7,setAvailabilityEndFri7]= useState('');
const [availabilityEndFri8,setAvailabilityEndFri8]= useState('');
const [availabilityEndFri9,setAvailabilityEndFri9]= useState('');




/*ROUND 3 - END */

/*================================= OLD AVAILABILITY STATE (DELETE WHEN THE NEW ONE ENDS)   ============================*/
const [availabilityStart1,setAvailabilityStart1]= useState('');
const [availabilityStart2,setAvailabilityStart2]= useState('');
const [availabilityStart3,setAvailabilityStart3]= useState('');

const [availabilityStart4,setAvailabilityStart4]= useState('');
const [availabilityStart5,setAvailabilityStart5]= useState('');
const [availabilityStart6,setAvailabilityStart6]= useState('');

const [availabilityStart7,setAvailabilityStart7]= useState('');
const [availabilityStart8,setAvailabilityStart8]= useState('');
const [availabilityStart9,setAvailabilityStart9]= useState('');
/*=================================   ============================*/

  const [availabilityEnd1,setAvailabilityEnd1]= useState('');
  const [availabilityEnd2,setAvailabilityEnd2]= useState('');
  const [availabilityEnd3,setAvailabilityEnd3]= useState('');

  const [availabilityEnd4,setAvailabilityEnd4]= useState('');
  const [availabilityEnd5,setAvailabilityEnd5]= useState('');
  const [availabilityEnd6,setAvailabilityEnd6]= useState('');

  const [availabilityEnd7,setAvailabilityEnd7]= useState('');
  const [availabilityEnd8,setAvailabilityEnd8]= useState('');
  const [availabilityEnd9,setAvailabilityEnd9]= useState('');


  // const [availableDays1,setAvailableDays1]=useState('')
  // const [availableDays2,setAvailableDays2]=useState('')
  // const [availableDays3,setAvailableDays3]=useState('')


  /*================================= OLD AVAILABILITY STATE END   ============================*/
   
   
   const [availableDays1,setAvailableDays1]=useState({
   
    monday:{slot1:`${availabilityStartMon1} - ${availabilityEndMon1 }`,slot2:`${availabilityStartMon2} - ${availabilityEndMon2 }`,slot3:`${availabilityStartMon3} - ${availabilityEndMon3}`},
    tuesday:{slot1:`${availabilityStartTue1} - ${availabilityEndTue1 }`,slot2:`${availabilityStartTue2} - ${availabilityEndTue2 }`,slot3:`${availabilityStartTue3} - ${availabilityEndTue3}` },
    wednesday:{slot1:`${availabilityStartWed1} - ${availabilityEndWed1 }`,slot2:`${availabilityStartWed2} - ${availabilityEndWed2 }`,slot3:`${availabilityStartWed3} - ${availabilityEndWed3}` },
    thursday:{slot1:`${availabilityStartThu1} - ${availabilityEndThu1 }`,slot2:`${availabilityStartThu2} - ${availabilityEndThu2 }`,slot3:`${availabilityStartThu3} - ${availabilityEndThu3}` },
    friday:{slot1:`${availabilityStartFri1} - ${availabilityEndFri1 }`,slot2:`${availabilityStartFri2} - ${availabilityEndFri2 }`,slot3:`${availabilityStartFri3} - ${availabilityEndFri3}` },


   })


   const [availableDays2,setAvailableDays2]=useState({
   
    monday:{slot1:`${availabilityStartMon4} - ${availabilityEndMon4 }`,slot2:`${availabilityStartMon5} - ${availabilityEndMon5 }`,slot3:`${availabilityStartMon6} - ${availabilityEndMon6}` },
    tuesday:{slot1:`${availabilityStartTue4} - ${availabilityEndTue4 }`,slot2:`${availabilityStartTue5} - ${availabilityEndTue5 }`,slot3:`${availabilityStartTue6} - ${availabilityEndTue6}` },
    wednesday:{slot1:`${availabilityStartWed4} - ${availabilityEndWed4 }`,slot2:`${availabilityStartWed5} - ${availabilityEndWed5 }`,slot3:`${availabilityStartWed6} - ${availabilityEndWed6}` },
    thursday:{slot1:`${availabilityStartThu4} - ${availabilityEndThu4 }`,slot2:`${availabilityStartThu5} - ${availabilityEndThu5 }`,slot3:`${availabilityStartThu6} - ${availabilityEndThu6}` },
    friday:{slot1:`${availabilityStartFri4} - ${availabilityEndFri4 }`,slot2:`${availabilityStartFri5} - ${availabilityEndFri5 }`,slot3:`${availabilityStartFri6} - ${availabilityEndFri6}` },

   })


   const [availableDays3,setAvailableDays3]=useState({
    
    monday:{slot1:`${availabilityStartMon7} - ${availabilityEndMon7 }`,slot2:`${availabilityStartMon8} - ${availabilityEndMon8 }`,slot3:`${availabilityStartMon9} - ${availabilityEndMon9}`},
    tuesday:{slot1:`${availabilityStartTue7} - ${availabilityEndTue7 }`,slot2:`${availabilityStartTue8} - ${availabilityEndTue8 }`,slot3:`${availabilityStartTue9} - ${availabilityEndTue9}` },
    wednesday:{slot1:`${availabilityStartWed7} - ${availabilityEndWed7 }`,slot2:`${availabilityStartWed8} - ${availabilityEndWed8 }`,slot3:`${availabilityStartWed9} - ${availabilityEndWed9}`} ,
    thursday:{slot1:`${availabilityStartThu7} - ${availabilityEndThu7 }`,slot2:`${availabilityStartThu8} - ${availabilityEndThu8 }`,slot3:`${availabilityStartThu9} - ${availabilityEndThu9}` },
    friday:{slot1:`${availabilityStartFri7} - ${availabilityEndFri7 }`,slot2:`${availabilityStartFri8} - ${availabilityEndFri8 }`,slot3:`${availabilityStartFri9} - ${availabilityEndFri9}`} ,

   })


   const setAvailableDays1Fxn=  ()=>{

    setAvailableDays1(
    {
      monday:{slot1:`${availabilityStartMon1} - ${availabilityEndMon1 }`,slot2:`${availabilityStartMon2} - ${availabilityEndMon2 }`,slot3:`${availabilityStartMon3} - ${availabilityEndMon3}`},
      tuesday:{slot1:`${availabilityStartTue1}} - ${availabilityEndTue1 }`,slot2:`${availabilityStartTue2} - ${availabilityEndTue2 }`,slot3:`${availabilityStartTue3} - ${availabilityEndTue3}` },
      wednesday:{slot1:`${availabilityStartWed1} - ${availabilityEndWed1 }`,slot2:`${availabilityStartWed2} - ${availabilityEndWed2 }`,slot3:`${availabilityStartWed3} - ${availabilityEndWed3}` },
      thursday:{slot1:`${availabilityStartThu1} - ${availabilityEndThu1 }`,slot2:`${availabilityStartThu2} - ${availabilityEndThu2 }`,slot3:`${availabilityStartThu3} - ${availabilityEndThu3}` },
      friday:{slot1:`${availabilityStartFri1} - ${availabilityEndFri1 }`,slot2:`${availabilityStartFri2} - ${availabilityEndFri2 }`,slot3:`${availabilityStartFri3} - ${availabilityEndFri3}` },
    }

    )
   }


   const setAvailableDays2Fxn=  ()=>{

    setAvailableDays2(
    {
     
    monday:{slot1:`${availabilityStartMon4} - ${availabilityEndMon4 }`,slot2:`${availabilityStartMon5} - ${availabilityEndMon5 }`,slot3:`${availabilityStartMon6} - ${availabilityEndMon6}` },
    tuesday:{slot1:`${availabilityStartTue4} - ${availabilityEndTue4 }`,slot2:`${availabilityStartTue5} - ${availabilityEndTue5 }`,slot3:`${availabilityStartTue6} - ${availabilityEndTue6}` },
    wednesday:{slot1:`${availabilityStartWed4} - ${availabilityEndWed4 }`,slot2:`${availabilityStartWed5} - ${availabilityEndWed5 }`,slot3:`${availabilityStartWed6} - ${availabilityEndWed6}` },
    thursday:{slot1:`${availabilityStartThu4} - ${availabilityEndThu4 }`,slot2:`${availabilityStartThu5} - ${availabilityEndThu5 }`,slot3:`${availabilityStartThu6} - ${availabilityEndThu6}` },
    friday:{slot1:`${availabilityStartFri4} - ${availabilityEndFri4 }`,slot2:`${availabilityStartFri5} - ${availabilityEndFri5 }`,slot3:`${availabilityStartFri6} - ${availabilityEndFri6}` },

    }

    )
   }




   const setAvailableDays3Fxn=  ()=>{

    setAvailableDays3(
    {
     
      monday:{slot1:`${availabilityStartMon7} - ${availabilityEndMon7 }`,slot2:`${availabilityStartMon8} - ${availabilityEndMon8 }`,slot3:`${availabilityStartMon9} - ${availabilityEndMon9}`},
      tuesday:{slot1:`${availabilityStartTue7} - ${availabilityEndTue7 }`,slot2:`${availabilityStartTue8} - ${availabilityEndTue8 }`,slot3:`${availabilityStartTue9} - ${availabilityEndTue9}` },
      wednesday:{slot1:`${availabilityStartWed7} - ${availabilityEndWed7 }`,slot2:`${availabilityStartWed8} - ${availabilityEndWed8 }`,slot3:`${availabilityStartWed9} - ${availabilityEndWed9}`} ,
      thursday:{slot1:`${availabilityStartThu7} - ${availabilityEndThu7 }`,slot2:`${availabilityStartThu8} - ${availabilityEndThu8 }`,slot3:`${availabilityStartThu9} - ${availabilityEndThu9}` },
      friday:{slot1:`${availabilityStartFri7} - ${availabilityEndFri7 }`,slot2:`${availabilityStartFri8} - ${availabilityEndFri8 }`,slot3:`${availabilityStartFri9} - ${availabilityEndFri9}`} ,
    }

    )
   }








  /* for showing team availability on monday to friday - ROUND 1 */
   const [showMondayAvailable1,setShowMondayAvailable1] = useState(true)
   const [showTuesdayAvailable1,setShowTuesdayAvailable1] = useState(false)
   const [showWednesdayAvailable1,setShowWednesdayAvailable1] = useState(false)
   const [showThursdayAvailable1,setShowThursdayAvailable1] = useState(false)
   const [showFridayAvailable1,setShowFridayAvailable1] = useState(false)
   

 /* for showing team availability on monday to friday - ROUND 2 */
 const [showMondayAvailable2,setShowMondayAvailable2] = useState(true)
 const [showTuesdayAvailable2,setShowTuesdayAvailable2] = useState(false)
 const [showWednesdayAvailable2,setShowWednesdayAvailable2] = useState(false)
 const [showThursdayAvailable2,setShowThursdayAvailable2] = useState(false)
 const [showFridayAvailable2,setShowFridayAvailable2] = useState(false)




  /* for showing team availability on monday to friday - ROUND 3 */
  const [showMondayAvailable3,setShowMondayAvailable3] = useState(true)
  const [showTuesdayAvailable3,setShowTuesdayAvailable3] = useState(false)
  const [showWednesdayAvailable3,setShowWednesdayAvailable3] = useState(false)
  const [showThursdayAvailable3,setShowThursdayAvailable3] = useState(false)
  const [showFridayAvailable3,setShowFridayAvailable3] = useState(false)








  const [jobPostings,setJobPostings] = useState('')
  const [serviceFeeEnd, setServiceFeeEnd] =useState('')
  const [serviceFeeStart,setServiceFeeStart] =useState('')
  const [salaryRangeStart,setSalaryRangeStart] =useState('')
  const [salaryRangeEnd,setSalaryRangeEnd] =useState('')
  const [servicePercentage,setServicePercentage] = useState('')

  const [classes,setClasses] =useState('')

  const [readyToCreate,setReadyToCreate] =useState(false)

 // const [typeofInterview1,setTypeOfInterview1] = useState('')
 // const [typeofInterview2,setTypeOfInterview2] = useState('')
 // const [typeofInterview3,setTypeOfInterview3] = useState('')


  const [selectedFile, setSelectedFile] = useState({selectedFile: [], selectedFileName: []});
  const [file, setFile] = useState('');
  const handleselectedFile = event => {
    setSelectedFile({
        selectedFile: event.target.files[0],
        selectedFileName: event.target.files[0].name
    });
    setFile(URL.createObjectURL(event.target.files[0]));
};



  const dispatch = useDispatch(); 

  const inputStyle = {
    background: 'white',
    marginRight: '30px',
    width: '100%',
    //height:"1rem"
  };

  const inputContainer = {
    display: 'flex',
    alignItems: 'center',
  };


  const inputContainer2 = {
    display: 'flex',
    flexDirection:'column',
    alignItems: 'center',
    marginTop:"0.2rem"
  };


  const userSignin = (e) => {
    e.preventDefault();
    setLoading(true);
    const user = { email, password };
    //dispatch(signinBusiness(user, navigate, setLoading));
  }

  useEffect(()=>{
    if(!user ){
  navigate('/home')
    }
  },[])

  console.log("Interview Panel members 1",interviewPanelMembers1)
  console.log("Interview Panel members 2",interviewPanelMembers2)
  console.log("Interview Panel members 3",interviewPanelMembers3)
  
  console.log("Interview Panel members 4",interviewPanelMembers4)
  console.log("Interview Panel members 5",interviewPanelMembers5)
  console.log("Interview Panel members 6",interviewPanelMembers6)

  console.log("Interview Panel members 7",interviewPanelMembers7)
  console.log("Interview Panel members 8",interviewPanelMembers8)
  console.log("Interview Panel members 9",interviewPanelMembers9)



  useEffect(()=>{
    if(readyToCreate === true){
    createThisJob()
    }
  },[readyToCreate])

  
  const createThisJob = (/*e*/) => {
    //e.preventDefault();
   console.log("GROUP OR JOB, IS CREATING!---->")
    setLoading(true);
   /* const id = user.id;
    const name = user.firstName + " " + user.lastName;
    const email = user.email;
    const profileImg = user.imageUrl;*/
    //const userData = {id, name, email, profileImg};
    let userData = {
    businessName:companyName,
    jobRole,
    salary,
    skillLevel,
    typeOfRemoteWork,
    workStatus,
    addressOnSite,
    hiringManagerName,
    sourceCandidates,
    postJobExternally,
    teamSize,
    interviewRound1:{
    typeOfInterview:typeOfInterview1,
    duration:duration1,
    interviewLocation:interviewLocation1,
    interviewLead:interviewLead1,
    interviewPanelMember1:interviewPanelMembers1,
    interviewPanelMember2:interviewPanelMembers2,
    /*availabilityStart1:availabilityStart1,
    availabilityEnd1: availabilityEnd1,
    availabilityStart2: availabilityStart2,
    availabilityEnd2:availabilityEnd2,
    availabilityStart3:availabilityStart3,
    availabilityEnd3:availabilityEnd3,*/
    availableDays:availableDays1

    },
    interviewRound2:{
  
      typeOfInterview:typeOfInterview2,
      duration:duration2,
      interviewLocation:interviewLocation2,
      interviewLead:interviewLead4,
      interviewPanelMember1:interviewPanelMembers3,
      interviewPanelMember2:interviewPanelMembers4,
     /* availabilityStart1:availabilityStart4,
      availabilityEnd1: availabilityEnd4,
      availabilityStart2: availabilityStart5,
      availabilityEnd2:availabilityEnd5,
      availabilityStart3:availabilityStart6,
      availabilityEnd3:availabilityEnd6,*/
      availableDays:availableDays2

    },
    interviewRound3:{

      typeOfInterview:typeOfInterview3,
      duration:duration3,
      interviewLocation:interviewLocation3,
      interviewLead:interviewLead7,
      interviewPanelMember1:interviewPanelMembers5,
      interviewPanelMember2:interviewPanelMembers6,
      /*availabilityStart1:availabilityStart7,
      availabilityEnd1: availabilityEnd7,
      availabilityStart2: availabilityStart8,
      availabilityEnd2:availabilityEnd8,
      availabilityStart3:availabilityStart9,
      availabilityEnd3:availabilityEnd9,*/
      availableDays:availableDays3

    },
    roleAvailabilityReason,
    essentialSkills,
    nonEssentialSkills,
    successfulPersonDesc,
    numberOfOpenings,
    sharedElseWhere,
    businessId:userId ? userId: user && user.id?user.id :user && user.uid
    }
   if(user && !user.id){/**DONT DELETE THIS IF STATEMENT TILL U FIND OUT WHY U RANDONLY LOG OUT AT TIMES */
 
      notifyErrorFxn("Error while submitting form, please refresh and try again")
      setLoading(false)
   }
   else if(sourceCandidates && !termsAgreed){
    notifyErrorFxn("Please Agree to the terms before submitting!")
    setLoading(false)
   }
   else if(
   sourceCandidates && !selectedFile.selectedFile||  sourceCandidates && selectedFile.selectedFile.length === 0 
   )
   {
    notifyErrorFxn("Be sure to upload a job description document!")
    setLoading(false)
   }
   else if(
   
    typeOfInterview1 & !interviewLead1 || typeOfInterview2 & !interviewLead4 || typeOfInterview3 & !interviewLead7  

   ){

    notifyErrorFxn("For Interviews you have selected,please fill in at least an interview lead!")
    setLoading(false)
   }
   else if(

   /*==============CHECKING EVERY SINGLE AVAILABILITY SLOT FOR IF AN END DATE WAS NOT PROVIDED BUT A START DATE WAS */

  
   
      availabilityStartMon1 & !availabilityEndMon1 || availabilityStartMon2 & !availabilityEndMon2 || availabilityStartMon3 & !availabilityEndMon3||
      availabilityStartTue1 & !availabilityEndTue1 || availabilityStartTue2 & !availabilityEndTue2 || availabilityStartTue3 & !availabilityEndTue3||
      availabilityStartWed1 & !availabilityEndWed1 || availabilityStartWed2 & !availabilityEndWed2 || availabilityStartWed3 & !availabilityEndWed3||
      availabilityStartThu1 & !availabilityEndThu1 || availabilityStartThu2 & !availabilityEndThu2 || availabilityStartThu3 & !availabilityEndThu3||
      availabilityStartFri1 & !availabilityEndFri1 || availabilityStartFri2 & !availabilityEndFri2 || availabilityStartFri3 & !availabilityEndFri3||
  
  
    
  
  
     
     
      availabilityStartMon4 & !availabilityEndMon4 || availabilityStartMon5 & !availabilityEndMon5 || availabilityStartMon6 & !availabilityEndMon6||
      availabilityStartTue4 & !availabilityEndTue4 || availabilityStartTue5 & !availabilityEndTue5 || availabilityStartTue6 & !availabilityEndTue6||
      availabilityStartWed4 & !availabilityEndWed4 || availabilityStartWed5 & !availabilityEndWed5 || availabilityStartWed6 & !availabilityEndWed6||
      availabilityStartThu4 & !availabilityEndThu4 || availabilityStartThu5 & !availabilityEndThu5 || availabilityStartThu6 & !availabilityEndThu6||
      availabilityStartFri4 & !availabilityEndFri4 || availabilityStartFri5 & !availabilityEndFri5 || availabilityStartFri6 & !availabilityEndFri6||
  
   
  
  
    
      
      availabilityStartMon7 & !availabilityEndMon7 || availabilityStartMon8 & !availabilityEndMon8 || availabilityStartMon9 & !availabilityEndMon9||
      availabilityStartTue7 & !availabilityEndTue7 || availabilityStartTue8 & !availabilityEndTue8 || availabilityStartTue9 & !availabilityEndTue9||
      availabilityStartWed7 & !availabilityEndWed7 || availabilityStartWed8 & !availabilityEndWed8 || availabilityStartWed9 & !availabilityEndWed9||
      availabilityStartThu7 & !availabilityEndThu7 || availabilityStartThu8 & !availabilityEndThu8 || availabilityStartThu9 & !availabilityEndThu9||
      availabilityStartFri7 & !availabilityEndFri7 || availabilityStartFri8 & !availabilityEndFri8 || availabilityStartFri9 & !availabilityEndFri9||
  

/*==============CHECKING EVERY SINGLE AVAILABILITY SLOT FOR IF AN END DATE WAS NOT PROVIDED BUT A START DATE WAS  -END*/



/*==============CHECKING EVERY SINGLE AVAILABILITY SLOT FOR IF A START DATE WAS NOT PROVIDED BUT AN END DATE WAS */
!availabilityStartMon1 & availabilityEndMon1 || !availabilityStartMon2 & availabilityEndMon2 || !availabilityStartMon3 & availabilityEndMon3||
!availabilityStartTue1 & availabilityEndTue1 || !availabilityStartTue2 & availabilityEndTue2 || !availabilityStartTue3 & availabilityEndTue3||
!availabilityStartWed1 & availabilityEndWed1 || !availabilityStartWed2 & availabilityEndWed2 || !availabilityStartWed3 & availabilityEndWed3||
!availabilityStartThu1 & availabilityEndThu1 || !availabilityStartThu2 & availabilityEndThu2 || !availabilityStartThu3 & availabilityEndThu3||
!availabilityStartFri1 & availabilityEndFri1 || !availabilityStartFri2 & availabilityEndFri2 || !availabilityStartFri3 & availabilityEndFri3||







!availabilityStartMon4 & availabilityEndMon4 || !availabilityStartMon5 & availabilityEndMon5 || !availabilityStartMon6 & availabilityEndMon6||
!availabilityStartTue4 & availabilityEndTue4 || !availabilityStartTue5 & availabilityEndTue5 || !availabilityStartTue6 & availabilityEndTue6||
!availabilityStartWed4 & availabilityEndWed4 || !availabilityStartWed5 & availabilityEndWed5 || !availabilityStartWed6 & availabilityEndWed6||
!availabilityStartThu4 & availabilityEndThu4 || !availabilityStartThu5 & availabilityEndThu5 || !availabilityStartThu6 & availabilityEndThu6||
!availabilityStartFri4 & availabilityEndFri4 || !availabilityStartFri5 & availabilityEndFri5 || !availabilityStartFri6 & availabilityEndFri6||






!availabilityStartMon7 & availabilityEndMon7 || !availabilityStartMon8 & availabilityEndMon8 || !availabilityStartMon9 & availabilityEndMon9||
!availabilityStartTue7 & availabilityEndTue7 || !availabilityStartTue8 & availabilityEndTue8 || !availabilityStartTue9 & availabilityEndTue9||
!availabilityStartWed7 & availabilityEndWed7 || !availabilityStartWed8 & availabilityEndWed8 || !availabilityStartWed9 & availabilityEndWed9||
!availabilityStartThu7 & availabilityEndThu7 || !availabilityStartThu8 & availabilityEndThu8 || !availabilityStartThu9 & availabilityEndThu9||
!availabilityStartFri7 & availabilityEndFri7 || !availabilityStartFri8 & availabilityEndFri8 || !availabilityStartFri9 & availabilityEndFri9


/*==============CHECKING EVERY SINGLE AVAILABILITY SLOT FOR IF A START DATE WAS NOT PROVIDED BUT AN END DATE WAS  -- END*/


   ){
    notifyErrorFxn("Please check your  team availability,each entry you fill must have both a start date an and end date!")
    setLoading(false)
  }
   
   else{
    dispatch(uploadJobDescription(userData,selectedFile.selectedFile,navigate, setLoading));

  
    setTimeout(()=>{


if(isLoading === false){
    
  setSelectedFile({selectedFile: [], selectedFileName: []}) ;
  setFile('')
   
      setjobRole('') ;
      setSalary('') ;
     setSkillLevel('') 
   setTypeOfRemoteWork('') 
    setWorkStatus('') ;
      setAddressOnSite('') ;
      setHiringManagerName('') ;
     setTeamSize('') ;
     setRoleAvailabilityReason('') ;
      setEssentialSkills('') ;
    
     setNonEssentialSkills('') ;
     setSuccessfulPersonDesc('') ;
    
      setNumberOfOpenings ('');
    
      setSharedElseWhere('') 
    
     setTypeOfInterview1('');
     setTypeOfInterview2('');
     setTypeOfInterview3('');
    
     setTypeOfInterview4('');
     setTypeOfInterview5('');
     setTypeOfInterview6('');
    
     setInterviewRound1('');
     setInterviewRound2('');
     setInterviewRound3('');
    
     setInterviewRound4('');
     setInterviewRound5('');
     setInterviewRound6('');



     setInterviewLocation1('');
     setInterviewLocation2('');
     setInterviewLocation3('');
      
    
     setInterviewLead1('');
     setInterviewLead2('');
     setInterviewLead3('');

     setInterviewLead4('');
     setInterviewLead5('');
     setInterviewLead6('');

     setInterviewLead7('');
     setInterviewLead8('');
     setInterviewLead9('');
      
    
     setDuration1('');
     setDuration2('');
     setDuration3('');
    
    
     setFeedback1('');
     setFeedback2('');
     setFeedback3('');
    
     setInterviewPanelMembers1('');
     setInterviewPanelMembers2('');
     setInterviewPanelMembers3('');
      
    
     setInterviewPanelMembers4('');
     setInterviewPanelMembers5('');
     setInterviewPanelMembers6('');
      
    
 setInterviewPanelMembers7('');
 setInterviewPanelMembers8('');
 setInterviewPanelMembers9('');
    
     setAvailabilityStart1('');
     setAvailabilityStart2('');
     setAvailabilityStart3('');
    
     setAvailabilityStart4('');
     setAvailabilityStart5('');
     setAvailabilityStart6('');
    
     setAvailabilityStart7('');
     setAvailabilityStart8('');
     setAvailabilityStart9('');
   
       setAvailabilityEnd1('');
       setAvailabilityEnd2('');
       setAvailabilityEnd3('');
      
       setAvailabilityEnd4('');
       setAvailabilityEnd5('');
       setAvailabilityEnd6('');
      
       setAvailabilityEnd7('');
       setAvailabilityEnd8('');
       setAvailabilityEnd9('');
    
    
      /*==============RESETTING EVERY SINGLE AVAILABILITY SLOT AFTER SUBMISSION*/
setAvailabilityStartMon1(''); setAvailabilityEndMon1(''); setAvailabilityStartMon2(''); setAvailabilityEndMon2(''); setAvailabilityStartMon3(''); setAvailabilityEndMon3('');
setAvailabilityStartTue1(''); setAvailabilityEndTue1(''); setAvailabilityStartTue2(''); setAvailabilityEndTue2(''); setAvailabilityStartTue3(''); setAvailabilityEndTue3('');
setAvailabilityStartWed1(''); setAvailabilityEndWed1(''); setAvailabilityStartWed2(''); setAvailabilityEndWed2(''); setAvailabilityStartWed3(''); setAvailabilityEndWed3('');
setAvailabilityStartThu1(''); setAvailabilityEndThu1(''); setAvailabilityStartThu2(''); setAvailabilityEndThu2(''); setAvailabilityStartThu3(''); setAvailabilityEndThu3('');
setAvailabilityStartFri1(''); setAvailabilityEndFri1(''); setAvailabilityStartFri2(''); setAvailabilityEndFri2(''); setAvailabilityStartFri3(''); setAvailabilityEndFri3('');







setAvailabilityStartMon4(''); setAvailabilityEndMon4(''); setAvailabilityStartMon5(''); setAvailabilityEndMon5(''); setAvailabilityStartMon6(''); setAvailabilityEndMon6('');
setAvailabilityStartTue4(''); setAvailabilityEndTue4(''); setAvailabilityStartTue5(''); setAvailabilityEndTue5(''); setAvailabilityStartTue6(''); setAvailabilityEndTue6('');
setAvailabilityStartWed4(''); setAvailabilityEndWed4(''); setAvailabilityStartWed5(''); setAvailabilityEndWed5(''); setAvailabilityStartWed6(''); setAvailabilityEndWed6('');
setAvailabilityStartThu4(''); setAvailabilityEndThu4(''); setAvailabilityStartThu5(''); setAvailabilityEndThu5(''); setAvailabilityStartThu6(''); setAvailabilityEndThu6('');
setAvailabilityStartFri4(''); setAvailabilityEndFri4(''); setAvailabilityStartFri5(''); setAvailabilityEndFri5(''); setAvailabilityStartFri6(''); setAvailabilityEndFri6('');






setAvailabilityStartMon7(''); setAvailabilityEndMon7(''); setAvailabilityStartMon8(''); setAvailabilityEndMon8(''); setAvailabilityStartMon9(''); setAvailabilityEndMon9('');
setAvailabilityStartTue7(''); setAvailabilityEndTue7(''); setAvailabilityStartTue8(''); setAvailabilityEndTue8(''); setAvailabilityStartTue9(''); setAvailabilityEndTue9('');
setAvailabilityStartWed7(''); setAvailabilityEndWed7(''); setAvailabilityStartWed8(''); setAvailabilityEndWed8(''); setAvailabilityStartWed9(''); setAvailabilityEndWed9('');
setAvailabilityStartThu7(''); setAvailabilityEndThu7(''); setAvailabilityStartThu8(''); setAvailabilityEndThu8(''); setAvailabilityStartThu9(''); setAvailabilityEndThu9('');
setAvailabilityStartFri7(''); setAvailabilityEndFri7(''); setAvailabilityStartFri8(''); setAvailabilityEndFri8(''); setAvailabilityStartFri9(''); setAvailabilityEndFri9('');


    
    setJobPostings(''); 
      setServiceFeeEnd('');
     setServiceFeeStart('');
    setSalaryRangeStart('');
     setSalaryRangeEnd('');
     setServicePercentage('') ;

}

    },1800)


   }
  }



  const rows= [
    {interviewRound:"Round 1", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/4/2024"},
    {interviewRound:"Round 2", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/11/2024"},
    {interviewRound:"Round 3", typeOfInterview:"Team Interview -Virtual",feedback:"Basic Interview", duration:"3/11/2024"},
    
    
  ];

  const rows2 = [
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"},
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"},
   {interviewRound:"Round 1", interviewPanelMembers:["Mark Thomas - Lead Full Stack Developer","Mariah Johnson -Full Stack Developer","Ann Moore - Full Stack Developer"],interviewLead:"Mark Thomas"}
  ]


  return (
    <div style={{width:"80%",zIndex:"0",fontFamily:"Public Sans, sans-serif ",}}>
     <form onSubmit={(e)=>{e.preventDefault();setReadyToCreate(true)}}>

    
           <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",justifyContent:"flex-start",marginTop:"3rem",marginBottom:"2rem"}}>
              Add Job
            </Typography>

          <Grid container  xs={12} spacing={6} direction="row" style={{fontFamily:"Public Sans, sans-serif ",}}>
            <Grid item container  xs={6} spacing={6} style={{display:"flex",flexDirection:"row"}} >
                <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%' ,position:"relative",top:"-0.4rem",fontFamily:"Public Sans, sans-serif",}}>Job Title*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="fname"
                      required
                      placeholder='Job Title'
                      value={jobRole}
                      onChange={(e) => setjobRole(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans, sans-serif",
                          
                        },
                    }}
                    />
                  </div>
                </Grid>

              </Grid>



     
      
              <Grid item container xs={6} spacing={0} style={{display:"flex",flexDirection:"row",gap:"0rem",position:"relative",left:"1rem"}}>
                

              <Grid item xs={12} style={{/*marginTop:"-10.3rem"*/}}>
                  <b style={{ fontSize:'14px', width: '100%',position:"relative",top:"-0.4rem" }}>Name of Hiring Manager or Reporting Manager</b>
                  <div style={inputContainer}>
                    <TextField
                      name="hiring manager name"
                      required
                      placeholder='add answer here'
                      value={hiringManagerName}
                      onChange={(e) => setHiringManagerName(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                    />
                  </div>
                </Grid>

           

              </Grid>
          </Grid>




              <Typography variant="h3" gutterBottom style={{marginTop:"2rem",fontFamily:"Libre Baskerville, sans-serif", color:"#40255F",display:"flex",justifyContent:"flex-start"}}>
             The Interview Process
              </Typography>

              <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start"}}>
              Once you receive your batch of qualified candidates, what is the interview process for this role?
              </Typography>





              {/*<div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",position:"relative",marginTop:"1rem",marginBottom:"1rem",fontFamily:"Public Sans, sans-serif ",}}>
              <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                      <Table sx={{}} aria-label="simple table">
                        <TableHead>

                          <TableRow sx={{backgroundColor:"#FFFFFFF"}}>

                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",}}>Interview Round</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",}} align="center">Type of Interview</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",}} align="center">Duration</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",}} align="center">Feedback form</TableCell>
                           
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows.map((row,index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                            >
                              <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                              
                            <TextField  
                              name="interview round"
                              inputProps={{
                                style: {
                                  height:'0.6rem',
                                  cursor:"pointer",
                                  fontFamily:"Public Sans, sans-serif",
                                  '&::placeholder': {
                                    color: 'gray',
                                  },
                                },
                            }}
                              placeholder='Round -1'
                              value={index=== 0 ?interviewRound1:(index === 1 ? interviewRound2:interviewRound3)}
                          onChange={(e) => {index=== 0 ?setInterviewRound1(e.target.value):(index === 1 ? setInterviewRound2(e.target.value):setInterviewRound3(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer"}}
                            />
                              
                        
                              </TableCell>


                              <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>

                              <TextField 
                              name="type of interview"
                              inputProps={{
                                style: {
                                  height:'0.6rem',
                                  cursor:"pointer",
                                  fontFamily:"Public Sans, sans-serif"
                                },
                            }}
                            placeholder={index=== 0 ?'Team Interview':index===1?"Onsite Interview":"HR Interview"}
                              value={index=== 0 ?typeOfInterview1:(index === 1 ? typeOfInterview2:typeOfInterview3)}
                              onChange={(e) => {index=== 0 ?setTypeOfInterview1(e.target.value):(index === 1 ? setTypeOfInterview2(e.target.value):setTypeOfInterview3(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer"}}
                            />  

                              </TableCell>


                              <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                             <TextField 
                             name="duration"
                             inputProps={{
                              style: {
                                height:'0.6rem',
                                cursor:"pointer",
                                fontFamily:"Public Sans, sans-serif ",
                              },
                          }}
                             placeholder='60 mins'
                             value={index=== 0 ?duration1:(index === 1 ? duration2:duration3)}
                             onChange={(e) => {index=== 0 ?setDuration1(e.target.value):(index === 1 ? setDuration2(e.target.value):setDuration3(e.target.value))}}
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer"}}
                             />  
                             
                             </TableCell>


                             <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                            <TextField 
                            name="feedback"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='feedback'
                            value={index=== 0 ?feedback1:(index === 1 ? feedback2:feedback3)}
                            onChange={(e) => {index=== 0 ?setFeedback1(e.target.value):(index === 1 ? setFeedback2(e.target.value):setFeedback3(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer"}}
                            />  
                            
                            </TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                  </TableContainer>
                </div> */}


           
             

               {/* <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",marginTop:"3rem",marginBottom:"3rem"}}> */}
               <Typography variant="h4" style={{ color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",textDecoration:"underline",position:"relative",top:"3.5rem"}}>
               Round 1
              </Typography>
              <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                          
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows2.slice(0,1).map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                            
                          {/*<TextField  
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='team interview'
                            value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                            onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                          /> */}

                     <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={typeOfInterview1}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>type of interview</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setTypeOfInterview1(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>type of interview</MenuItem>   
                   <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                   <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                   <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                 
                        
                         </Select>
                            


                            <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                            Location
                            </Typography>
                    
                           { <TextField  
                            name="location of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='location'
                            value={interviewLocation1}
                            onChange={(e)=>{setInterviewLocation1(e.target.value)}}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                          /> }



                       {/* <Select
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="location-label"
                           id="location-select"
                           value={classes}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>location</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setClasses(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>select</MenuItem>   
                   <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                   <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                  
                 
                        
                         </Select> */}
                              
                      
                            </TableCell>  



                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          {/*  <TextField  
                              name="60 mins"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='duration'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                          /> */}



                 <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={duration1}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>duration</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setDuration1(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>duration</MenuItem>   
                   <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                   <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                   <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                 
                        
                         </Select>
                              

                              </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                    
                        
                            <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Hayward'
                           value={index=== 0 ?interviewLead1:(index === 1 ? interviewLead2:interviewLead3)}
                           onChange={(e) => {
                            console.log("interviewLead1 is-->",interviewLead1 );console.log("interviewLead2 is-->",interviewLead2 );console.log("interviewLead3 is-->",interviewLead3 )
                            
                            index=== 0 ?setInterviewLead1(e.target.value):(index === 1 ? setInterviewLead2(e.target.value):setInterviewLead3(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                           />  

            <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
            Interview Lead
            </Typography>
                    
                    
                     {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                            placeholder='Tom Ham'
                            value={index === 0?  (index2=== 0 ?interviewPanelMembers1:(index2 === 1 ? interviewPanelMembers2:interviewPanelMembers3)):
                              index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                               (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                            }
                            onChange={ (e)=>{
                                console.log("interviewPanelMembers1 is",interviewPanelMembers1 );console.log("interviewPanelMembers2 is",interviewPanelMembers2 );console.log("interviewPanelMembers3 is",interviewPanelMembers3 )
                              {index === 0?  (index2=== 0 ?setInterviewPanelMembers1(e.target.value):(index2 === 1 ? setInterviewPanelMembers2(e.target.value):setInterviewPanelMembers3(e.target.value))):
                                index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                 (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))

                              }
                            }

                            }
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  
                        )) }
                            </TableCell>

                         {/*
                            <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> 
                         */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                          
                           <FormControl style={{position:"relative",top:"-0.6rem"}}>
                             <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                             <span style={{textDecoration:"underline"}}>Mon </span> 
                             <span style={{textDecoration:"underline"}}>Tue</span> 
                             <span style={{textDecoration:"underline"}}>Wed</span> 
                             <span style={{textDecoration:"underline"}}>Thu</span>   
                             <span style={{textDecoration:"underline"}}> Fri</span>  
                                 </div>
                                 </FormLabel>

                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                >
                                  <FormControlLabel  style={{width:"1.5rem"}} value="monday"  onClick={()=>{setShowMondayAvailable1(true);setShowTuesdayAvailable1(false);setShowWednesdayAvailable1(false);setShowThursdayAvailable1(false);setShowFridayAvailable1(false)}} control={<Radio size="small"     />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="tuesday"  onClick={()=>{setShowMondayAvailable1(false);setShowTuesdayAvailable1(true);setShowWednesdayAvailable1(false);setShowThursdayAvailable1(false);setShowFridayAvailable1(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="wednesday"  onClick={()=>{setShowMondayAvailable1(false);setShowTuesdayAvailable1(false);setShowWednesdayAvailable1(true);setShowThursdayAvailable1(false);setShowFridayAvailable1(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="thursday"  onClick={()=>{setShowMondayAvailable1(false);setShowTuesdayAvailable1(false);setShowWednesdayAvailable1(false);setShowThursdayAvailable1(true);setShowFridayAvailable1(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="friday"   onClick={()=>{setShowMondayAvailable1(false);setShowTuesdayAvailable1(false);setShowWednesdayAvailable1(false);setShowThursdayAvailable1(false);setShowFridayAvailable1(true)}}control={<Radio size="small" />}  />
                                  
                                </RadioGroup>
                               </FormControl>
       
                        <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                         {/*1 */}  

                         <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityStartTue1:(showWednesdayAvailable1?availabilityStartWed1:(showThursdayAvailable1? availabilityStartThu1:(showFridayAvailable1? availabilityStartFri1   :availabilityStartMon1)))}
                            onChange={ (e)=>{ showTuesdayAvailable1?setAvailabilityStartTue1(e.target.value):(showWednesdayAvailable1?setAvailabilityStartWed1(e.target.value):(showThursdayAvailable1? setAvailabilityStartThu1(e.target.value):(showFridayAvailable1? setAvailabilityStartFri1(e.target.value):(setAvailabilityStartMon1(e.target.value)) ))) ;setAvailableDays1Fxn() }}
                           

                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityEndTue1:(showWednesdayAvailable1?availabilityEndWed1:(showThursdayAvailable1? availabilityEndThu1:(showFridayAvailable1? availabilityEndFri1   :availabilityEndMon1)))}
                            onChange={ (e)=>{showTuesdayAvailable1?setAvailabilityEndTue1(e.target.value):(showWednesdayAvailable1?setAvailabilityEndWed1(e.target.value):(showThursdayAvailable1? setAvailabilityEndThu1(e.target.value):(showFridayAvailable1? setAvailabilityEndFri1(e.target.value):(setAvailabilityEndMon1(e.target.value)) ))) ;setAvailableDays1Fxn()  }}
                           

                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>

                     {/*2 */}  
                     <div style={{display:"flex", gap:"0.6rem"}}> 
                     <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityStartTue2:(showWednesdayAvailable1?availabilityStartWed2:(showThursdayAvailable1? availabilityStartThu2:(showFridayAvailable1? availabilityStartFri2   :availabilityStartMon2)))}
                            onChange={ (e)=>{showTuesdayAvailable1?setAvailabilityStartTue2(e.target.value):(showWednesdayAvailable1?setAvailabilityStartWed2(e.target.value):(showThursdayAvailable1? setAvailabilityStartThu2(e.target.value):(showFridayAvailable1? setAvailabilityStartFri2(e.target.value):(setAvailabilityStartMon2(e.target.value)) )));setAvailableDays1Fxn()  }}
                           
                            
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityEndTue2:(showWednesdayAvailable1?availabilityEndWed2:(showThursdayAvailable1? availabilityEndThu2:(showFridayAvailable1? availabilityEndFri2   :availabilityEndMon2)))}
                            onChange={ (e)=>{setAvailableDays1Fxn();showTuesdayAvailable1?setAvailabilityEndTue2(e.target.value):(showWednesdayAvailable1?setAvailabilityEndWed2(e.target.value):(showThursdayAvailable1? setAvailabilityEndThu2(e.target.value):(showFridayAvailable1? setAvailabilityEndFri2(e.target.value):(setAvailabilityEndMon2(e.target.value)) )));setAvailableDays1Fxn() }}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  

                          </div>


                        {/*3 */}  
                        <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityStartTue3:(showWednesdayAvailable1?availabilityStartWed3:(showThursdayAvailable1? availabilityStartThu3:(showFridayAvailable1? availabilityStartFri3   :availabilityStartMon3)))}
                            onChange={ (e)=>{showTuesdayAvailable1?setAvailabilityStartTue3(e.target.value):(showWednesdayAvailable1?setAvailabilityStartWed3(e.target.value):(showThursdayAvailable1? setAvailabilityStartThu3(e.target.value):(showFridayAvailable1? setAvailabilityStartFri3(e.target.value):(setAvailabilityStartMon3(e.target.value)) )));setAvailableDays1Fxn() }}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable1?availabilityEndTue3:(showWednesdayAvailable1?availabilityEndWed3:(showThursdayAvailable1? availabilityEndThu3:(showFridayAvailable1? availabilityEndFri3   :availabilityEndMon3)))}
                            onChange={ (e)=>{showTuesdayAvailable1?setAvailabilityEndTue3(e.target.value):(showWednesdayAvailable1?setAvailabilityEndWed3(e.target.value):(showThursdayAvailable1? setAvailabilityEndThu3(e.target.value):(showFridayAvailable1? setAvailabilityEndFri3(e.target.value):(setAvailabilityEndMon3(e.target.value)) )));setAvailableDays1Fxn() }}
                            
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>


                        </div>


                           </TableCell>

                          

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                </TableContainer>
                 {/*</div> */}
                 







       {showRound2 &&

          <>

                 {/* <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",marginTop:"3rem",marginBottom:"3rem"}}> */}
               <Typography variant="h4" style={{ color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",textDecoration:"underline",position:"relative",top:"3.5rem"}}>
               Round 2
              </Typography>
              <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                    <Table sx={{}} aria-label="simple table">
                      <TableHead>

                        <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                          
                          <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                         
                         
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows2.slice(0,1).map((row,index) => (
                          <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                          >
                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                            
                          {/*<TextField  
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='team interview'
                            value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                            onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                          /> */}

                     <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={typeOfInterview2}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>type of interview</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setTypeOfInterview2(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>type of interview</MenuItem>   
                   <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                   <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                   <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                        
                         </Select>
                            


                            <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                            Location
                            </Typography>
                    
                           {<TextField  
                            name="location of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif",
                                
                              },
                          }}
                            placeholder='location'
                            value={interviewLocation2}
                            onChange={(e)=>{setInterviewLocation2(e.target.value)}}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                          /> }



                       {/* <Select
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="location-label"
                           id="location-select"
                           value={classes}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>location</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setClasses(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>select</MenuItem>   
                   <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                   <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                  
                 
                        
                         </Select> */}
                              
                      
                            </TableCell>  



                            <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                            
                          {/*  <TextField  
                              name="60 mins"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='duration'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                          /> */}



                 <Select
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                          inputProps={{
                           classes: {
                               icon: classes.icon,
                           },
                       }}
                         
                           labelId="duration-label"
                           id="hi"
                           value={duration2}
                           //label="Classe et option"
                           displayEmpty
                           renderValue={(selected) => {
                             if (selected.length === 0) {
                               return <em style={{color:"lightgray"}}>duration</em>;
                             }
                 
                             return selected;
                           }}
                           onChange={(event) => {
                             setDuration2(event.target.value);
                           }}
                         >
                        
                   <MenuItem disabled value={""}>duration</MenuItem>   
                   <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                   <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                   <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                 
                        
                         </Select>
                              

                              </TableCell>


                            <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                    
                        
                            <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harriet'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {
                            
                            console.log("interviewLead4 is-->",interviewLead4 );console.log("interviewLead5 is-->",interviewLead5 );console.log("interviewLead6 is-->",interviewLead6 )
                            
                            index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                           />  

            <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
            Interview Lead
            </Typography>
                    
                    
                     {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                            <TextField 
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                            placeholder='Tristan Harris'
                            value={index === 0?  (index2=== 0 ?interviewPanelMembers3:(index2 === 1 ? interviewPanelMembers4:interviewPanelMembers5)):
                              index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                               (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                            }
                            onChange={ (e)=>{
                              console.log("interviewPanelMembers3 is",interviewPanelMembers3 );console.log("interviewPanelMembers4 is",interviewPanelMembers4 );console.log("interviewPanelMembers5 is",interviewPanelMembers5 )
                              {index === 0?  (index2=== 0 ?setInterviewPanelMembers3(e.target.value):(index2 === 1 ? setInterviewPanelMembers4(e.target.value):setInterviewPanelMembers5(e.target.value))):
                                index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                 (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))

                              }
                            }

                            }
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  
                        )) }
                            </TableCell>

                         {/*
                            <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                           <TextField 
                           name="interview lead"
                           inputProps={{
                            style: {
                              cursor:"pointer",
                              height:'0.6rem'
                              ,fontFamily:"Public Sans, sans-serif ",
                              
                            },
                        }}
                           placeholder='Tom Harris'
                           value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                           onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                           style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                           />  
                           
                           </TableCell> 
                         */}


                           <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                          
                           <FormControl style={{position:"relative",top:"-0.6rem"}}>
                             <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                             <span style={{textDecoration:"underline"}}>Mon </span> 
                             <span style={{textDecoration:"underline"}}>Tue</span> 
                             <span style={{textDecoration:"underline"}}>Wed</span> 
                             <span style={{textDecoration:"underline"}}>Thu</span>   
                             <span style={{textDecoration:"underline"}}> Fri</span>  
                                 </div>
                                 </FormLabel>

                                <RadioGroup
                                  row
                                  aria-labelledby="demo-row-radio-buttons-group-label"
                                  name="row-radio-buttons-group"
                                  style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                >
                                  <FormControlLabel  style={{width:"1.5rem"}} value="monday"  onClick={()=>{setShowMondayAvailable2(true);setShowTuesdayAvailable2(false);setShowWednesdayAvailable2(false);setShowThursdayAvailable2(false);setShowFridayAvailable2(false)}} control={<Radio size="small"     />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="tuesday"  onClick={()=>{setShowMondayAvailable2(false);setShowTuesdayAvailable2(true);setShowWednesdayAvailable2(false);setShowThursdayAvailable2(false);setShowFridayAvailable2(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="wednesday"  onClick={()=>{setShowMondayAvailable2(false);setShowTuesdayAvailable2(false);setShowWednesdayAvailable2(true);setShowThursdayAvailable2(false);setShowFridayAvailable2(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="thursday"  onClick={()=>{setShowMondayAvailable2(false);setShowTuesdayAvailable2(false);setShowWednesdayAvailable2(false);setShowThursdayAvailable2(true);setShowFridayAvailable2(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="friday"   onClick={()=>{setShowMondayAvailable2(false);setShowTuesdayAvailable2(false);setShowWednesdayAvailable2(false);setShowThursdayAvailable2(false);setShowFridayAvailable2(true)}}control={<Radio size="small" />}  />
                                  
                                </RadioGroup>
                               </FormControl>
       
                        <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                         {/*1 */}  

                         <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                          
                            value={showTuesdayAvailable2?availabilityStartTue4:(showWednesdayAvailable2?availabilityStartWed4:(showThursdayAvailable2? availabilityStartThu4: (showFridayAvailable2? availabilityStartFri4:  availabilityStartMon4)))}
                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityStartTue4(e.target.value):(showWednesdayAvailable2?setAvailabilityStartWed4(e.target.value):(showThursdayAvailable2? setAvailabilityStartThu4(e.target.value):(showFridayAvailable2? setAvailabilityStartFri4(e.target.value):setAvailabilityStartMon4(e.target.value) )));setAvailableDays2Fxn() } }
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            
                            value={showTuesdayAvailable2?availabilityEndTue4:(showWednesdayAvailable2?availabilityEndWed4:(showThursdayAvailable2? availabilityEndThu4:(showFridayAvailable2? availabilityEndFri4:availabilityEndMon4)))}
                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityEndTue4(e.target.value):(showWednesdayAvailable2?setAvailabilityEndWed4(e.target.value):(showThursdayAvailable2? setAvailabilityEndThu4(e.target.value):(showFridayAvailable2? setAvailabilityEndFri4(e.target.value):setAvailabilityEndMon4(e.target.value) )));setAvailableDays2Fxn() }}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>

                     {/*2 */}  
                     <div style={{display:"flex", gap:"0.6rem"}}> 
                     <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                           
                            value={showTuesdayAvailable2?availabilityStartTue5:(showWednesdayAvailable2?availabilityStartWed5:(showThursdayAvailable2? availabilityStartThu5 :(showFridayAvailable2? availabilityStartFri5:availabilityStartMon5)))}

                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityStartTue5(e.target.value):(showWednesdayAvailable2?setAvailabilityStartWed5(e.target.value):(showThursdayAvailable2? setAvailabilityStartThu5(e.target.value):(showFridayAvailable2? setAvailabilityStartFri5(e.target.value):setAvailabilityStartMon5(e.target.value))));setAvailableDays2Fxn() }}
                           
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            
                            value={showTuesdayAvailable2?availabilityEndTue5:(showWednesdayAvailable2?availabilityEndWed5:(showThursdayAvailable2? availabilityEndThu5:(showFridayAvailable2? availabilityEndFri5:availabilityEndMon5)))}
                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityEndTue5(e.target.value):(showWednesdayAvailable2?setAvailabilityEndWed5(e.target.value):(showThursdayAvailable2? setAvailabilityEndThu5(e.target.value):(showFridayAvailable2? setAvailabilityEndFri5(e.target.value):setAvailabilityEndMon5(e.target.value))));setAvailableDays2Fxn() }}
                            
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  

                          </div>


                        {/*3 */}  
                        <div style={{display:"flex", gap:"0.6rem"}}> 
                        <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                           
                            value={showTuesdayAvailable2?availabilityStartTue6:(showWednesdayAvailable2?availabilityStartWed6:(showThursdayAvailable2? availabilityStartThu6:(showFridayAvailable2? availabilityStartFri6:availabilityStartMon6)))}
                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityStartTue6(e.target.value):(showWednesdayAvailable2?setAvailabilityStartWed6(e.target.value):(showThursdayAvailable2? setAvailabilityStartThu6(e.target.value):(showFridayAvailable2? setAvailabilityStartFri6(e.target.value):setAvailabilityStartMon6(e.target.value))));setAvailableDays2Fxn()  }}
                            
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  



                          <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                          <TextField
                            name="type of interview"
                            inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                 fontSize:"0.8rem",
                                
                              },
                          }}
                            placeholder="10:00AM"
                            value={showTuesdayAvailable2?availabilityEndTue6:(showWednesdayAvailable2?availabilityEndWed6:(showThursdayAvailable2? availabilityEndThu6:(showFridayAvailable2? availabilityEndFri6:availabilityEndMon6)))}
                            onChange={ (e)=>{showTuesdayAvailable2?setAvailabilityEndTue6(e.target.value):(showWednesdayAvailable2?setAvailabilityEndWed6(e.target.value):(showThursdayAvailable2? setAvailabilityEndThu6(e.target.value):(showFridayAvailable2? setAvailabilityEndFri6(e.target.value):setAvailabilityEndMon6(e.target.value))));setAvailableDays2Fxn()  }}
                            
                            style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                          />  


                          </div>


                        </div>


                           </TableCell>

                          

                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                </TableContainer>
                 {/*</div> */}
           </>

            }













     {showRound3 &&

       <>

                 {/* <div style={{padding:"1rem",border:"2px solid lightgray",borderRadius:"0.5rem",marginTop:"3rem",marginBottom:"3rem"}}> */}
               <Typography variant="h4" style={{ color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",textDecoration:"underline",position:"relative",top:"3.5rem"}}>
               Round 3
              </Typography>
                  <TableContainer sx={{py:"2rem", width: "100%"}} component={Paper}>
                    
                      <Table sx={{}} aria-label="simple table">
                        <TableHead>

                          <TableRow sx={{backgroundColor:"#FFFFFFF",color:"black",fontSize:"1rem",position:"relative",bottom:"-1.3rem"}}>

                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}} >Type of Interview</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Duration</TableCell>
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Interview Panel Members</TableCell>
                            
                            <TableCell style={{color:"black",fontSize:"1.2rem",fontFamily:"Public Sans, sans-serif",backgroundColor:"#e0dcdc"}}  align="center">Team Availability</TableCell>
                           
                           
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows2.slice(0,1).map((row,index) => (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 },backgroundColor:"white",padding:"1rem"}}
                            >
                              <TableCell sx={{my:2, backgroundColor:"#e0dcdc",position:"relative"}}  scope="row">  
                              
                            {/*<TextField  
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='team interview'
                              value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                              onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.3rem"}}
                            /> */}

                       <Select
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.5rem",width:"90%",  height:'2.7rem'}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="duration-label"
                             id="hi"
                             value={typeOfInterview3}
                             //label="Classe et option"
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>type of interview</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setTypeOfInterview3(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>type of interview</MenuItem>   
                     <MenuItem  value={"Team Interview"}>Team Interview</MenuItem>
                     <MenuItem   value={"HR Interview"}>HR Interview</MenuItem>
                     <MenuItem   value={"1:1 interview"}>1:1 Interview</MenuItem>
                   
                          
                           </Select>
                              


                              <Typography variant="body" style={{marginBottom:"-0.8rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem"}}>
                              Location
                              </Typography>
                      
                             { <TextField  
                              name="location of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif",
                                  
                                },
                            }}
                              placeholder='location'
                              value={interviewLocation3}
                              onChange={(e)=>{setInterviewLocation3(e.target.value)}}
                             
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%"}}
                            /> }



                         {/* <Select
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"absolute",top:"11rem",width:"148%",height:"2.6rem"}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="location-label"
                             id="location-select"
                             value={classes}
                             
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>location</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setClasses(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>select</MenuItem>   
                     <MenuItem  value={"Virtual"}>Virtual</MenuItem>
                     <MenuItem   value={"Onsite"}>Onsite</MenuItem>
                    
                   
                          
                            </Select>*/ }
                                
                        
                              </TableCell>  



                              <TableCell sx={{my:2, backgroundColor:"#e0dcdc"}}  scope="row">  
                              
                            {/*  <TextField  
                                name="60 mins"
                                inputProps={{
                                  style: {
                                    cursor:"pointer",
                                    height:'0.6rem'
                                    ,fontFamily:"Public Sans, sans-serif",
                                    
                                  },
                              }}
                                placeholder='duration'
                                value={index=== 0 ?interviewRound4:(index === 1 ? interviewRound5:interviewRound6)}
                                onChange={(e) => {index=== 0 ?setInterviewRound4(e.target.value):(index === 1 ? setInterviewRound5(e.target.value):setInterviewRound6(e.target.value))}}
                                style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.7rem"}}
                            /> */}



                   <Select
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",cursor:"pointer",position:"relative",top:"-4.9rem",width:"90%",  height:'2.7rem'}}
                            inputProps={{
                             classes: {
                                 icon: classes.icon,
                             },
                         }}
                           
                             labelId="duration-label"
                             id="hi"
                             value={duration3}
                             //label="Classe et option"
                             displayEmpty
                             renderValue={(selected) => {
                               if (selected.length === 0) {
                                 return <em style={{color:"lightgray"}}>duration</em>;
                               }
                   
                               return selected;
                             }}
                             onChange={(event) => {
                               setDuration3(event.target.value);
                             }}
                           >
                          
                     <MenuItem disabled value={""}>duration</MenuItem>   
                     <MenuItem  value={"30 mins"}>30 mins</MenuItem>
                     <MenuItem   value={"60 mins"}>60 mins</MenuItem>
                     <MenuItem   value={"90 mins"}>90 mins</MenuItem>
                   
                          
                           </Select>
                                
  
                                </TableCell>


                              <TableCell align="center"  sx={{my:2, backgroundColor:"#e0dcdc"}}>
                      
                          
                              <TextField 
                             name="interview lead"
                             inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                             placeholder='Tom Holland'
                             value={index=== 0 ?interviewLead7:(index === 1 ? interviewLead8:interviewLead9)}
                             onChange={(e) => 
                                
                                {console.log("interviewLead7 is",interviewLead7 );console.log("interviewLead8 is",interviewLead8 );console.log("interviewLead9 is",interviewLead9 )
                              index=== 0 ?setInterviewLead7(e.target.value):(index === 1 ? setInterviewLead8(e.target.value):setInterviewLead9(e.target.value))}}
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",position:"relative",top:"0.1rem"}}
                             />  

              <Typography variant="body" gutterBottom style={{marginBottom:"2rem", color:"black",display:"flex",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",fontWeight:"800",fontSize:"1rem",position:"relative",top:"0.2rem"}}>
              Interview Lead
              </Typography>
                      
                      
                       {  row.interviewPanelMembers.slice(0,2).map((item,index2)=>(  
                              <TextField 
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                  
                                },
                            }}
                              placeholder='Tom Harris'
                              value={index === 0?  (index2=== 0 ?interviewPanelMembers5:(index2 === 1 ? interviewPanelMembers6:interviewPanelMembers7)):
                                index === 1 ? (index2=== 0 ?interviewPanelMembers4:(index2 === 1 ? interviewPanelMembers5:interviewPanelMembers6)):
                                 (index2=== 0 ?interviewPanelMembers7:(index2 === 1 ? interviewPanelMembers8:interviewPanelMembers9))

                              }
                              onChange={ (e)=>{

                                {index === 0?  (index2=== 0 ?setInterviewPanelMembers5(e.target.value):(index2 === 1 ? setInterviewPanelMembers6(e.target.value):setInterviewPanelMembers7(e.target.value))):
                                  index === 1 ? (index2=== 0 ?setInterviewPanelMembers4(e.target.value):(index2 === 1 ? setInterviewPanelMembers5(e.target.value):setInterviewPanelMembers6(e.target.value))):
                                   (index2=== 0 ?setInterviewPanelMembers7(e.target.value):(index2 === 1 ? setInterviewPanelMembers8(e.target.value):setInterviewPanelMembers9(e.target.value)))
  
                                }
                              }

                              }
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",display:"flex",flexDirection:"column",marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  
                          )) }
                              </TableCell>

                           {/*
                              <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>

                             <TextField 
                             name="interview lead"
                             inputProps={{
                              style: {
                                cursor:"pointer",
                                height:'0.6rem'
                                ,fontFamily:"Public Sans, sans-serif ",
                                
                              },
                          }}
                             placeholder='Tom Harris'
                             value={index=== 0 ?interviewLead4:(index === 1 ? interviewLead5:interviewLead6)}
                             onChange={(e) => {index=== 0 ?setInterviewLead4(e.target.value):(index === 1 ? setInterviewLead5(e.target.value):setInterviewLead6(e.target.value))}}
                             style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black"}}
                             />  
                             
                             </TableCell> 
                           */}
 

                             <TableCell align="center"  sx={{marginTop:"0.5rem", backgroundColor:"#e0dcdc"}}>
                            
                             <FormControl style={{position:"relative",top:"-0.6rem"}}>
                               <FormLabel id="demo-row-radio-buttons-group-label"><div style={{position:"relative",left:"-9px",display:"flex",gap:"6px",justifyContent:"center",width:"10rem",color:"black"}}>
                               <span style={{textDecoration:"underline"}}>Mon </span> 
                               <span style={{textDecoration:"underline"}}>Tue</span> 
                               <span style={{textDecoration:"underline"}}>Wed</span> 
                               <span style={{textDecoration:"underline"}}>Thu</span>   
                               <span style={{textDecoration:"underline"}}> Fri</span>  
                                   </div>
                                   </FormLabel>

                                  <RadioGroup
                                    row
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    style={{display:"flex",justifyContent:"space-between",gap:"3px",width:"10rem"}}
                                  >
                                    <FormControlLabel  style={{width:"1.5rem"}} value="monday"  onClick={()=>{setShowMondayAvailable3(true);setShowTuesdayAvailable3(false);setShowWednesdayAvailable3(false);setShowThursdayAvailable3(false);setShowFridayAvailable3(false)}} control={<Radio size="small"     />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="tuesday"  onClick={()=>{setShowMondayAvailable3(false);setShowTuesdayAvailable3(true);setShowWednesdayAvailable3(false);setShowThursdayAvailable3(false);setShowFridayAvailable3(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="wednesday"  onClick={()=>{setShowMondayAvailable3(false);setShowTuesdayAvailable3(false);setShowWednesdayAvailable3(true);setShowThursdayAvailable3(false);setShowFridayAvailable3(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="thursday"  onClick={()=>{setShowMondayAvailable3(false);setShowTuesdayAvailable3(false);setShowWednesdayAvailable3(false);setShowThursdayAvailable3(true);setShowFridayAvailable3(false)}} control={<Radio size="small" />}  />
                                  <FormControlLabel  style={{width:"1.5rem"}} value="friday"   onClick={()=>{setShowMondayAvailable3(false);setShowTuesdayAvailable3(false);setShowWednesdayAvailable3(false);setShowThursdayAvailable3(false);setShowFridayAvailable3(true)}}control={<Radio size="small" />}  />
                                    
                                  </RadioGroup>
                                 </FormControl>
         
                          <div style={{width:"15rem",position:"relative",top:"-1rem",display:"flex",flexDirection:"column"}}>
                           {/*1 */}  

                           <div style={{display:"flex", gap:"0.6rem"}}> 
                          <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityStartTue7:(showWednesdayAvailable3?availabilityStartWed7:(showThursdayAvailable3? availabilityStartThu7:(showFridayAvailable3? availabilityStartFri7:availabilityStartMon7)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityStartTue7(e.target.value):(showWednesdayAvailable3?setAvailabilityStartWed7(e.target.value):(showThursdayAvailable3? setAvailabilityStartThu7(e.target.value):(showFridayAvailable3? setAvailabilityStartFri7(e.target.value):setAvailabilityStartMon7(e.target.value)))) ;setAvailableDays3Fxn() }}
                            
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityEndTue7:(showWednesdayAvailable3?availabilityEndWed7:(showThursdayAvailable3? availabilityEndThu7:(showFridayAvailable3? availabilityEndFri7:availabilityEndMon7)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityEndTue7(e.target.value):(showWednesdayAvailable3?setAvailabilityEndWed7(e.target.value):(showThursdayAvailable3? setAvailabilityEndThu7(e.target.value):(showFridayAvailable3? setAvailabilityEndFri7(e.target.value):setAvailabilityEndMon7(e.target.value))));setAvailableDays3Fxn()  }}
                              
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            </div>

                       {/*2 */}  
                       <div style={{display:"flex", gap:"0.6rem"}}> 
                       <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityStartTue8:(showWednesdayAvailable3?availabilityStartWed8:(showThursdayAvailable3? availabilityStartThu8:(showFridayAvailable3? availabilityStartFri8:availabilityStartMon8)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityStartTue8(e.target.value):(showWednesdayAvailable3?setAvailabilityStartWed8(e.target.value):(showThursdayAvailable3? setAvailabilityStartThu8(e.target.value):(showFridayAvailable3? setAvailabilityStartFri8(e.target.value):setAvailabilityStartMon8(e.target.value))));setAvailableDays3Fxn()  }}
                              
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  



                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>

                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityEndTue8:(showWednesdayAvailable3?availabilityEndWed8:(showThursdayAvailable3? availabilityEndThu8:(showFridayAvailable3? availabilityEndFri8:availabilityEndMon8)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityEndTue8(e.target.value):(showWednesdayAvailable3?setAvailabilityEndWed8(e.target.value):(showThursdayAvailable3? setAvailabilityEndThu8(e.target.value):(showFridayAvailable3? setAvailabilityEndFri8(e.target.value):setAvailabilityEndMon8(e.target.value))));setAvailableDays3Fxn()   }}
                              
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  

                            </div>


                          {/*3 */}  
                          <div style={{display:"flex", gap:"0.6rem"}}> 
                          <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityStartTue9:(showWednesdayAvailable3?availabilityStartWed9:(showThursdayAvailable3? availabilityStartThu9:(showFridayAvailable3? availabilityStartFri9:availabilityStartMon9)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityStartTue9(e.target.value):(showWednesdayAvailable3?setAvailabilityStartWed9(e.target.value):(showThursdayAvailable3? setAvailabilityStartThu9(e.target.value):(showFridayAvailable3? setAvailabilityStartFri9(e.target.value):setAvailabilityStartMon9(e.target.value))));setAvailableDays3Fxn()   }}
                              
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  



                            <div style={{borderBottom:"5px solid black",width:"2rem",position:"relative",top:"-1.5rem"}}></div>


                            <TextField
                              name="type of interview"
                              inputProps={{
                                style: {
                                  cursor:"pointer",
                                  height:'0.6rem'
                                  ,fontFamily:"Public Sans, sans-serif ",
                                   fontSize:"0.8rem",
                                  
                                },
                            }}
                              placeholder="10:00AM"
                             
                              value={showTuesdayAvailable3?availabilityEndTue9:(showWednesdayAvailable3?availabilityEndWed9:(showThursdayAvailable3? availabilityEndThu9:(showFridayAvailable3? availabilityEndFri9:availabilityEndMon9)))}
                              onChange={ (e)=>{showTuesdayAvailable3?setAvailabilityEndTue9(e.target.value):(showWednesdayAvailable3?setAvailabilityEndWed9(e.target.value):(showThursdayAvailable3? setAvailabilityEndThu9(e.target.value):(showFridayAvailable3? setAvailabilityEndFri9(e.target.value):setAvailabilityEndMon9(e.target.value))));setAvailableDays3Fxn()  }}
                             
                              style={{color:"grey",backgroundColor:"white",borderRadius:"0.5rem",border:"1px solid black",borderRadius:"1rem" ,width:"35%", marginTop:"0.3rem",marginBottom:"0.3rem",cursor:"pointer"}}
                            />  


                            </div>


                          </div>


                             </TableCell>

                            

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                  </TableContainer>
                 {/*</div> */}


        
       </> 
        
        }

        { !showRound3 && 
          <center style={{marginTop:"3rem",marginBottom:"3rem"}}>
               <LoadingButton onClick={()=>{
                if(!showRound2 && !showRound3){
                setShowRound2(true)
                }
                else if(showRound2 && !showRound3)
                  {
                    setShowRound3(true)
                  }
                 
               }} 
         
              
               sx={{bgcolor:"#40255F" ,fontFamily:"Public Sans, sans-serif",}}  size="large" type="button" variant="contained" >
                 { "Add Round"}
               </LoadingButton>
           </center>

          }

   

            <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",fontFamily:"Libre Baskerville, sans-serif",gap:"0rem",justifyContent:"flex-start",alignItems:"center",marginTop:"2rem",marginBottom:"4rem"}}>
             

              <FormControl>
                 <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                 <RadioGroup
                   row
                   aria-labelledby="demo-row-radio-buttons-group-label"
                   name="row-radio-buttons-group"
                 >
                   <FormControlLabel onClick={()=>{setPostJobExternally(!postJobExternally)}} checked={postJobExternally} value="yes" control={<Radio size="large" />} style={{fontSize:"3rem"}} label="" />
                  
                  
                 
                 </RadioGroup>
           </FormControl>

             
           Post Job (Externally)

            </Typography>

{postJobExternally &&
 <>
<Grid container  xs={12} spacing={6} direction="row" style={{position:"relative",left:"3rem",fontFamily:"Public Sans, sans-serif ",border:"1px solid black",paddingLeft:"2rem",paddingRight:"2rem",paddingTop:"1rem",paddingBottom:"1rem"}}>
            <Grid item container  xs={6} spacing={6} style={{display:"flex",flexDirection:"row"}} >
              


              

                <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-1.4rem" }}>Skill level for the role?*</b>
                  <div style={inputContainer2}>
                  <FormControl style={{position:"relative",left:"-5.8rem",top:"-0.7rem",scale:"0.9"}}>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                      <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.01);console.log(servicePercentage)}} value="Entry Level(3yrs or less)" control={<Radio  style={{color:"grey"}} />}  label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative"}}>Entry Level(3yrs or less) <b style={{color:"#636363",width:"12rem",position:"absolute"}}>&nbsp; -&nbsp;  10% </b></Typography>} />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.15);console.log(servicePercentage)}} value="Mid-level (3yrs - 5yrs)" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Mid-level (3yrs - 5yrs)<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  15%   </b></Typography>}  />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.18);console.log(servicePercentage)}} value="Senior-level (6yrs - 10yrs)" control={<Radio  style={{color:"grey"}} />}  label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Senior-level (6yrs - 10yrs)<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  18% </b></Typography>} />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.20);console.log(servicePercentage)}} value="Lead/Manager/SME (11yrs - 20yrs.)" control={<Radio  style={{color:"grey"}} />}   label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>Lead/Manager/SME (11yrs - 20yrs.)<b style={{color:"#636363",width:"12rem",position:"absolute"}}>&nbsp; -&nbsp;  20% </b></Typography>}   />
                     <FormControlLabel onClick={(e)=>{setSkillLevel(e.target.value);setServicePercentage(0.22);console.log(servicePercentage)}} value="C Suite" control={<Radio  style={{color:"grey"}} />}   label={<Typography style={{fontFamily:"Public Sans, sans-serif",position:"relative",}}>C Suite<b style={{color:"#636363",width:"15rem",position:"absolute"}}>&nbsp; -&nbsp;  22%</b></Typography>} />
                     
                   </RadioGroup>
                 </FormControl>

                 {/** PASTE HERE!! */}
                  </div>

                </Grid>




                <Grid item xs={12} style={{position:"relative",top:"-1.4rem" }}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Where is the role located?*</b>
                  <div style={inputContainer}>
                  <FormControl style={{position:"relative",left:"-3rem",scale:"0.95"}}>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel  onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US  </Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, East Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, East Coast working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, West Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, West Coast working hours </Typography>}/>
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, Midwest Coast working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, Midwest Coast working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, South working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, South working hours </Typography>}/>
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote within the US, South West working hours" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote within the US, South West working hours</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% Remote world-wide" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% Remote world-wide</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="50% Remote, 50% on-site (Hybrid)" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >50% Remote, 50% on-site (Hybrid)</Typography>} />
                     <FormControlLabel style={{fontFamily:"Public Sans, sans-serif",}} onClick={(e)=>{setTypeOfRemoteWork(e.target.value)}} value="100% On-site, please provide work address." control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}} >100% On-site, please provide work address.</Typography> }/>
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>


              

              </Grid>



     
      
              <Grid item container xs={6} spacing={0} style={{display:"flex",flexDirection:"row",gap:"0rem",position:"relative",left:"1rem"}}>
                

           
              <Grid item xs={12}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-1.4rem" }}>Type of Employment*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup  style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                    
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="full-time W-2 Employee" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Full-time, W-2 Employee</Typography>} />
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="full-time c2c" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Full-time, C2C</Typography>} />
                     <FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="part-time" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans,sans-serif"}}>Part-time</Typography>} />
                     {/*<FormControlLabel  onClick={(e)=>{setWorkStatus(e.target.value)}} value="other" control={<Radio   style={{color:"grey"}}/>} label="Other" />*/}
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>


              





                <Grid item xs={12} style={{position:"relative",top:"-3.5rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}> Please provide the address of the location. If not, please add your Headquarters*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="address on site"
                      required
                      placeholder='123 Street name, City, ST, 12345'
                      value={addressOnSite}
                      onChange={(e) => setAddressOnSite(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                      
                    />
                  </div>
                </Grid>



             


                <Grid item xs={12} style={{marginTop:"-5.9rem"}}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>What's the team size for this role*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="team size"
                      required
                      placeholder='add answer here'
                      value={teamSize}
                      onChange={(e) => setTeamSize(e.target.value)}
                      style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          fontFamily:"Public Sans,sans-serif"
                          
                        },
                    }}
                    />
                  </div>
                </Grid>




                <Grid item xs={12} style={{marginTop:"-6rem",position:"relative",top:"2.4rem" }}>
                  <b style={{ fontSize:'17px', width: '100%',position:"relative",top:"-0.4rem" }}>Why is this role available*</b>
                  <div style={inputContainer}>
                  <FormControl>
                   {/*<FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>*/}
                   <RadioGroup style={{color:"grey"}}
                     aria-labelledby="demo-radio-buttons-group-label"
                     defaultValue="female"
                     name="radio-buttons-group"
                   >
                     <FormControlLabel  onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Resignation/Termination" control={<Radio  style={{color:"grey"}} />} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Resignation/Termination</Typography>} />
                     <FormControlLabel onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Early Stages of Building a Team" control={<Radio  style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Early Stages of Building a Team</Typography>}/>
                     <FormControlLabel onClick={(e)=>{setRoleAvailabilityReason(e.target.value)}}  value="Company Growth / New Work" control={<Radio   style={{color:"grey"}}/>} label={<Typography style={{fontFamily:"Public Sans, sans-serif",}}>Company Growth / New Work</Typography>} />
                     
                    
                   </RadioGroup>
                 </FormControl>
                  </div>

                </Grid>
              </Grid>
          </Grid>

  </>
    }





              <Typography variant="h3" gutterBottom style={{color:"#40255F",display:"flex",gap:"0rem",fontFamily:"Libre Baskerville, sans-serif",justifyContent:"flex-start",marginTop:"3rem",marginBottom:"1rem"}}>
             

              <FormControl>
                 <FormLabel id="demo-row-radio-buttons-group-label"></FormLabel>
                 <RadioGroup
                   row
                   aria-labelledby="demo-row-radio-buttons-group-label"
                   name="row-radio-buttons-group"
                 >
                   <FormControlLabel onClick={()=>{setSourceCandidates(!sourceCandidates)}} checked={sourceCandidates} value="Yes" control={<Radio size="large" />} label="" />
                  
                  
                 
                 </RadioGroup>
           </FormControl>

           Source Candidates
            </Typography>

 


  {sourceCandidates &&
   <>

         <div style={{border:"1px solid black",paddingTop:"1rem",paddingBottom:"1rem", paddingLeft:"2rem",paddingRight:"1rem"}}> 
            <Typography variant="body2" gutterBottom style={{display:"flex",justifyContent:"flex-start"}}>
             What is your ideal candidate for this role? What skills are you willing to train and what are skills they must have day one
            </Typography>


            <Grid container spacing={6} direction="row">
                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem" ,fontFamily:"Public sans, sans-serif",}} item xs={6}>
                <b style={{ position:"relative",top:"0.7rem",  width: '60%' }}>Must have skills*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={essentialSkills}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",fontSize:"1.1rem",paddingLeft:"1rem",paddingTop:"1rem"}}
                    onChange={(e) => setEssentialSkills(e.target.value)}
                    placeholder=' Add answer here '
                    >
                    </textarea>

                  </div>
                </Grid>

                

                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem",fontFamily:"Public sans, sans-serif",  }} item xs={6}>
                <b style={{ position:"relative",top:"0.7rem" , width: '60%' }}>Flexible skills, willing to train*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={nonEssentialSkills}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"0.8rem",fontSize:"1.1rem",paddingLeft:"1rem"}}
                    onChange={(e) => setNonEssentialSkills(e.target.value)}
                    placeholder=' Add answer here'
                    >
                    </textarea>

                  </div>
                </Grid>
              </Grid>



     
      
              <Grid container spacing={6} direction="row">
                <Grid style={{marginTop:"2rem" ,display:"flex",flexDirection:"column",gap:"1.5rem",fontFamily:"Public sans, sans-serif", }} item xs={6}>
                <b style={{  width: '60%',position:"relative",top:"0.7rem" ,position:"relative",top:"0.7rem"  }}>What type of person would be successful in this role?*</b>
                  <div style={inputContainer}>
                    {/*<TextField
                      name="email"
                      required
                      type="email"
                      placeholder=''
                      onChange={(e) => setEmail(e.target.value)}
                      style={inputStyle}
                    />*/}

                    <textarea rows={7} 
                    name="jobResponsibilities"
                    value={successfulPersonDesc}
                    style={{borderRadius:"0.3rem",width:"100%",border:"1px solid #E0E0E0",padding:"0.8rem",fontSize:"1.1rem"}}
                    onChange={(e) => setSuccessfulPersonDesc(e.target.value)}
                    placeholder=' Add answer here '
                    >
                    </textarea>

                  </div>
                </Grid>

                <Grid style={{display:"flex",flexDirection:"column",gap:"1.5rem",marginTop:"2rem",fontFamily:"Public sans, sans-serif",}} item xs={6}>
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"0.9rem"  }}>How many openings of this role do you need filled?*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder=''
                      value={numberOfOpenings}
                      onChange={(e) => setNumberOfOpenings(e.target.value)}
                      //style={inputStyle}
                      style={{width:"100%",backgroundColor:"white",position:"relative",top:"1.5rem" }}
                      InputProps={{
                        style:{
                          fontFamily:"Public sans, sans-serif",
                          backgroundColor:"white"
                        }
                      }}
                    />
                  </div>

                <div style={{position:"relative",top:"2.9rem"}}> 
                  <b style={{ fontSize: '15px', width: '100%',position:"relative",top:"-0.4rem" }}>Is this role posted on any job board or shared with any staffing entity?*</b>
                  <div style={inputContainer}>
                    <TextField
                      name="lname"
                      required
                      placeholder=''
                      value={sharedElseWhere}
                      onChange={(e) => setSharedElseWhere(e.target.value)}
                      //style={inputStyle}
                      style={{width:"100%",backgroundColor:"white",position:"relative",top:"0.4rem"}}
                      InputProps={{
                        style:{
                          fontFamily:"Public sans, sans-serif",
                          backgroundColor:"white"
                        }
                      }}
                    />
                  </div>
               </div>

             <Stack direction="column" alignItems="center" justifyContent="center" sx={{ mt: 5,mb:1,color:"black" }}>
                <Typography style={{fontFamily:"Public Sans, sans-serif",}}>
                Please Upload Job Description*
               
                </Typography>

                <Typography style={{fontFamily:"Public Sans, sans-serif",}}>
              
                (PDF Format ONLY)
                </Typography>
             </Stack>
              {/* <center style={{marginTop:"0rem"}}>
                  <LoadingButton  sx={{bgcolor:"black",fontFamily:"Public Sans, sans-serif",}}  size="large" type="submit" variant="contained" disabled={loading}>
                    {loading ? "Loading..." : "Upload File"}
                  </LoadingButton>
                    </center>*/}


               <center style={{marginTop:"0rem"}}>
                  <Button component="label"  sx={{bgcolor:/*"#40255F"*/"#000000"}}  size="large" type="button" variant="contained" disabled={loading}>
                    
                    <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={handleselectedFile}
                   />
                   { "Upload File"}
                  </Button>

                  <div style={{marginTop:"1rem"}}>{selectedFile.selectedFileName}</div>
               </center>




                </Grid>



               <center style={{width:"100%",display:"flex",justifyContent:"center"}}>
                <Grid style={{display:"flex",flexDirection:"column"}} item xs={6}>
                <div style={{border:"1px solid lightgrey",padding:"1rem",paddingBottom:"3rem",paddingTop:"1rem",width:"95%",display:"flex",justifyContent:"center",alignItems:"center",gap:"2.5rem",marginTop:"2rem"}}>
                    
                    {    <div style={{flex:"1",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"0.5rem"}}>
                      <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      style={{backgroundColor:'transparent',padding:"0.2rem",border:"1px solid lightgrey",borderRadius:"0.5rem",width:"100%",margin:"0 auto",flex:"1"}}
                      spacing={{
                        xs: 1,
                        sm: 2,
                      }}
                    >
                     
                      <div onClick={()=>{setJobPostings(true)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:jobPostings?"#40255F":"transparent",color:jobPostings?"white":"#40255F",padding:"0.5rem",paddingLeft:"0.1rem",paddingRight:"0.1rem",width:"45%",textAlign:"center"}}>Salary</div>
                      <div onClick={()=>{setJobPostings(false)}} style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:!jobPostings?"#40255F":"transparent",color:!jobPostings?"white":"#40255F",padding:"0.5rem",paddingLeft:"0.1rem",paddingRight:"0.1rem",width:"45%",textAlign:"center"}}>Hourly</div>
                     
                
                    
                    </Stack>
                    
                    
                    <div style={{display:"flex",gap:"0.2rem",position:"relative",alignItems:"center",justifyContent:"center"}}>
                    <TextField
                      name=""
                      required
                      placeholder='$145,000'
                      value={salaryRangeStart}
                      onChange={(e) => {setSalaryRangeStart(e.target.value);
                        servicePercentage && servicePercentage > 0 ? setServiceFeeStart((e.target.value)*servicePercentage):setServiceFeeStart('')
                      }}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.3rem"
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                  <div  style={{fontSize:"3rem",top:"0rem",height:"2rem",position:"relative",top:"-0.5rem"}}>-</div>
 
                 <TextField
                      name=""
                      required
                      placeholder='$155,000'
                      value={salaryRangeEnd}
                      onChange={(e) => {setSalaryRangeEnd(e.target.value);
                        servicePercentage ? setServiceFeeEnd((e.target.value)*servicePercentage):setServiceFeeEnd('')
                      }}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.3rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                    </div>
                  </div>
                    }
                    

                    <div style={{flex:"1",display:"flex",flexDirection:"column",alignItems:"center",justifyContent:"flex-start",gap:"0.9rem"}}>
                    <div  style={{cursor:"pointer",borderRadius:"0.5rem",backgroundColor:"#40255F",color:"white",padding:"0.5rem",textAlign:"center",position:"relative",top:"0.1rem"}}>Service Fee</div>
                    
                    <div style={{display:"flex",gap:"0.2rem",justifyContent:"center",alignItems:"center",position:"relative"}}>
                    <TextField
                      name=""
                      required
                      placeholder='$26,900'
                      value={serviceFeeStart}
                      onChange={(e) => setServiceFeeStart(e.target.value)}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.5rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                   <div  style={{fontSize:"3rem",top:"0rem",height:"2rem",position:"relative",top:"-0.5rem"}}>-</div> 
 
                 <TextField
                      name=""
                      required
                      placeholder='$27,100'
                      value={serviceFeeEnd}
                      onChange={(e) => setServiceFeeEnd(e.target.value)}
                      //style={inputStyle}
                      inputProps={{
                        style: {
                          cursor:"pointer",
                          height:"0.2rem",
                          fontSize:"10px",
                          paddingLeft:"0.5rem"
                          
                        },
                    }}
                      style={{width:"100%",backgroundColor:"white",height:"0.2rem"}}
                    />


                    </div>
                    
                    </div>

                  </div>
                </Grid>
              
              </center>


             
              <Grid style={{display:"flex",flexDirection:"row",justifyContent:"center",width:"100%",position:"relative",top:"-2rem",left:"-20px"}} item xs={12}>
                  <FormGroup style={{marginBottom:"1rem",display:"flex",flexDirection:"row",alignItems:"center"}} >
        
                   <FormControlLabel onClick={()=>{setTermsAgreed(!termsAgreed)}} control={<Checkbox />} label={"I agree to the terms and conditions in the  "} /> <div style={{textDecoration:"underline",color:"blue"}}>service agreement </div>
                 </FormGroup>
               </Grid>



              </Grid>



       </div>

  </>}







  
              



      <center style={{marginTop:"6rem"}}>
      <LoadingButton  sx={{bgcolor:"#40255F" ,fontFamily:"Public Sans, sans-serif",}}  size="large" type="submit" variant="contained" disabled={loading}>
        {loading ? "Loading..." : "Submit Job"}
      </LoadingButton>
      </center>

      </form>


      

   
     

    {/*  <Stack direction="row" alignItems="center" justifyContent="center" sx={{ my: 1,color:"gray" }}>
        <Typography>
        Thanks for Submitting
        </Typography>
      </Stack> */}




    </div>
  );
}
